import styled from 'styled-components'
import { Colors } from 'advenio-components'

export const Container = styled.div`
  width: ${({ width }) => width };
  margin: 0px 8px;
  background: ${({ status }) => status ?  Colors.gray300 : Colors.red300};
  max-height: calc(100vh - 185px);
  border-radius: 7px;
  overflow-y: hidden;
  padding: 12px 8px 24px 8px;
  flex: 1 0 210px;
  ${({ oneSpace }) => oneSpace && `
    padding: 12px 8px 12px 8px;
    height: 200px;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  `}
`

export const ContainerSubtitle = styled.div`
  display:inline-block;
  width: 100%;
  margin: 6px 0px;
`

export const ColumnStatus = styled.div`
  font-size: 12px;
  color: #000000;
  align-items: center;
  padding: 3px 5px;
  border-radius: 3px;
  display: inline-block;
  float: right
  img {
    margin-bottom: -2px;
  }
`

export const Root = styled.div`
  box-sizing: border-box;
  padding: 16px;
  display: block ruby;
  align-items: flex-start;
  overflow-y: hidden;
  max-height: calc(100vh - 116px);
`

export const ColumnTitle = styled.div`
  font-size: 21px;
  color: ${Colors.advenioGray700};
  padding-left: 8px;
  cursor: pointer;
  margin-left: 5px;
  background-color: ${Colors.white500};
  border-radius: 6px;
  width: 96%;
`

export const ColumnSubtitle = styled.div`
  font-size: 11px;
  color: ${Colors.advenioGray400};
  text-transform: uppercase;
  padding-left: 8px;
  display:inline-block;
`

export const Placeholder = styled.div`
  width: 100%;
  height: 89px;
  border: 2px dashed ${Colors.advenioGray350};
  border-radius: 4px;
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
`

export const draggableItemStyle = (draggableStyle, isDragging) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: isDragging ? '0' : '10px',
  // change background colour if dragging
  paddingBottom: isDragging ? '30px' : '0',

  // styles we need to apply on draggables
  ...draggableStyle,
})

export const droppableContainerStyle = (isDraggingOver, dropoff, oneSpace) => oneSpace ? ({
  background: isDraggingOver ? Colors.advenioGray400 : 'transparent',
  padding: '12px',
  paddingTop: '22px',
  marginBottom: '0px',
  borderRadius: '5px',
  overflowY: 'scroll',
  overflowX: 'initial',
  flex: '1 1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}) : ({
  background: isDraggingOver ? Colors.advenioGray400 : 'transparent',
  padding: '5px',
  marginBottom: '36px',
  borderRadius: '5px',
  height: 'calc(100vh - 246px)',
  overflowY: 'scroll',
  overflowX: 'initial',
  flex: '1 1',
})
