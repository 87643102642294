import { createActions, createState, createReducer } from 'reduxsauce-crud'
import {clone, findIndex, propEq} from 'ramda'
import moment from 'moment'

const d = { deep: true }
const DEEP = { deep: true }

/* ------------- Types and Action Creators ------------- */

export const AuthRedux = createActions({
  loginRequest: ['data'],
  loginSuccess: ['user'],
  loginFailure: ['error'],
  // impersonateUserRequest: ['data'],
  // impersonateUserSuccess: ['user'],
  // impersonateUserFailure: ['error'],
  // stopImpersonateUserRequest: [''],
  // stopImpersonateUserSuccess: ['user'],
  // stopImpersonateUserFailure: ['error'],
  checkAuthStatus: null,
  setAuthStatus: null,
  setAuthorized: ['authorized'],
  logout: ['data'],

}, {
  prefix: 'AUTH_',
  defaultActions: {
    // get: true,
  },
})

// const { Types, Creators: Actions, Creators } = AuthRedux
// export const AuthTypes = Types
// export default Creators
export const { Types, Creators: Actions } = AuthRedux

/* ------------- Initial State ------------- */

export const INITIAL_STATE = createState({
  fetching: false,
  // Initially set to null on first load so that it is clear
  // that an auth check hasn't even been made yet.
  authenticated: null,
  authorized: null,
  error: '',
  user: {},
},{
  // get:true
})

/* ------------- Reducers ------------- */

const loginRequest = state => (
  state.merge({ fetching: true, error: '', authenticated: false }, DEEP)
)
const loginSuccess = (state, { user }) => (
  state.merge({
    fetching: false,
    authenticated: true,
    authorized: null,
    user,
  }, DEEP)
)
const loginFailure = (state, { error }) => (
  state.merge({
    fetching: false,
    authenticated: false,
    error,
  }, DEEP)
)
const impersonateUserRequest = state => (
  state.merge({ fetching: true, error: '' }, DEEP)
)
const impersonateUserSuccess = (state, { user }) => (
  state.merge({
    fetching: false,
    user,
  }, DEEP)
)
const impersonateUserFailure = (state, { error }) => (
  state.merge({
    fetching: false,
    error,
  }, DEEP)
)

const stopImpersonateUserRequest = state => (
  state.merge({ fetching: true, error: '' }, DEEP)
)
const stopImpersonateUserSuccess = (state, { user }) => (
  state.merge({
    fetching: false,
    user,
  }, DEEP)
)
const stopImpersonateUserFailure = (state, { error }) => (
  state.merge({
    fetching: false,
    error,
  }, DEEP)
)

const setAuthStatus = (state, { authenticated, error, user }) => (
  state.merge({ authenticated, error, user }, DEEP)
)

const setAuthorized = (state, { authorized }) => (
  state.merge({ authorized }, DEEP)
)
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.loginRequest]: loginRequest,
  [Types.loginSuccess]: loginSuccess,
  [Types.loginFailure]: loginFailure,
  // [Types.impersonateUserRequest]: impersonateUserRequest,
  // [Types.impersonateUserSuccess]: impersonateUserSuccess,
  // [Types.impersonateUserFailure]: impersonateUserFailure,
  // [Types.stopImpersonateUserRequest]: stopImpersonateUserRequest,
  // [Types.stopImpersonateUserSuccess]: stopImpersonateUserSuccess,
  // [Types.stopImpersonateUserFailure]: stopImpersonateUserFailure,
  [Types.setAuthStatus]: setAuthStatus,
  [Types.setAuthorized]: setAuthorized,

}, {
  defaultActions: {
    // get: true,
  },
  Types,
})
