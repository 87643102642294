import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import R, { isEmpty } from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import JobActions, { JobRedux, JobTypes } from '../Redux/JobRedux'
import { JOB } from '../GraphQL'
import moment from 'moment'
import { parseDataGraphQL } from '../../utils/Utils'

const jobCrudObservable = createCRUDObservable({
    mainRedux: JobRedux,
    reduxPath: 'job',
    // dataHandlers: {
    //   get: response => response.data,
    // },
})

const getJobEpic = (action$, store, { Api }) => (
    action$.ofType(JobTypes.JobRequest)
    .mergeMap(() => (
        Observable
        .fromPromise(
            Api.query({ query: JOB.getAllJobs, })
            .then(response => response)
            .catch(err => err)
        )
        .flatMap((response) => {

            if (response instanceof ApolloError) {
                
                return [
                    notificationFail(response['message']),
                    JobActions.JobError(response['message'])
                ]
            }

            const { allCatPuestos: { edges } } = response.data

            // if (allJobes.status !== '200') {
            //   

            //   return [
            //     notificationFail(allJobes.message),
            //     JobActions.JobError(allJobes)
            //   ]
            // }
            if (isEmpty(edges)) {
                return [
                    notificationFail('No se encontraron resultados!.'),
                    JobActions.JobSuccess([]),
                ]
            }
            const data = parseDataGraphQL(edges)

            return [
                // AuthActions.setAuthorized(true),
                JobActions.JobSuccess(data),
            ]
        })
    ))
)

export const jobObservers = Object.assign({}, jobCrudObservable.observers, {})

export const jobEpic = combineEpics(
    jobCrudObservable.epic,
    getJobEpic,
)