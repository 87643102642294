import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEmpty } from 'ramda'
import {Card,Button as OriginalButton, Modal } from 'advenio-components'
import { ROLES,COLORSOU } from '../../Constants'
import {  getSolAvance, userHasRole, slugify } from '../../utils/Utils'
import ErrorIsland from '../../Components/ErrorIsland'
import ProfileHeader from '../../Components/ProfileHeader'
import SolicitudForm from '../../Components/Solicitud/SolicitudForm'

import SolicitudActions from '../../Data/Redux/SolicitudRedux'

import LoadingSpinner from '../../shared/LoadingSpinner'

const PageContainer = styled(Card)`
  min-height: 75vh;
  width: 65%;
  margin-left: 18%;
  margin-top: 3%;
`
const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 8px;
  border-bottom: solid 1px rgb(244, 246, 248);
`
const ParentName = styled.span`
  margin-left: 16px;
  font-size: 16px;
  color: #637381;
`
const TitleName = styled.span`
  margin-left: 16px;
  font-size: 16px;
  color: #637381;
`
const BackButton = styled.img`
  cursor: pointer;
  margin-left: 9px;
  margin-right: -8px;
`
const ErrorRow = styled.div`
  display: flex;
  justify-content: center;
`
const solicitudModel={
  nombrePuesto: {} ,//"Prueba 2 RO",
  area: {} ,//"QXJlYUNhdGFsb2dPYmplY3Q6MTU=",
  departamento: null ,//"IESalud",
  puestoReporta: null ,//"Prueba 2 Persona Reportar ",
  correoJefeDirecto: null ,//"jose.valero@talisis.com",
  ou: {} ,//"T3VDYXRhbG9nT2JqZWN0OjY=",
  region: {} ,//"UmVnaW9uQ2F0YWxvZ09iamVjdDoyMw==",
  centro: {} ,//"Q2VudHJvQ2F0YWxvZ09iamVjdDoxNTU=",
  justificacionOperativa: null ,//"Prueba2 justificacion",
  usuarioId: null ,//"VXNlck9iamVjdDo0NQ==",
  statusId: null ,//"U3RhdHVzQ2F0YWxvZ09iamVjdDox",
  tipoSolicitud: {} ,//"U29saWNpdHVkVGlwb09iamVjdDo0",
  prioridad:{} ,//"UHJpb3JpZGFkQ2F0YWxvZ09iamVjdDoz"
  descripcionPuesto:null,
  organigrama: null,
}
export const validateFields = (value, key) => {
  // const exceptions = {
  //   company_id: 1,
  //   initials: 1,
  //   created_at: 1,
  //   deleted_at: 1,
  //   comments: 1,
  //   company: 1,
  //   full_name: 1,
  //   enrollment_probability_id: 1,
  //   enrollment_estimated_at: 1,
  //   promotional_code: 1,
  // }
  if (
    (value[key] === undefined ||
      value[key] === null ||
      value[key] === 0 ||
      value[key] === '0' ||
      value[key] === ' ' ||
      value[key] === '')
  ) {
    return true
  }
  return false
}

class Solicitud extends Component {

  state={
   modalType:{
     title:"Nueva",
     description:'Autorización y reclutamiento de vacantes.'
   },
   solicitud:solicitudModel,
   messageError:null,
   disabledFiles:true,
   loadingFiles: false,
   descriptionJob:{},
   organigram:{},
  }
  componentWillMount() {
    const { params: { solicitudId } } = this.props.match
    if(solicitudId !== 'new'){
      this.setState({modalType:{title:'Editar',description:''}})
      // call-solicitud-details
      this.props.getSolicitudById({'id':solicitudId})
    }
  }
  componentWillReceiveProps(nextProps) {
    const {
      error,
      solicitud
    } = nextProps
    if(error !== this.props.error){
      this.setState({messageError:error})
    }
    if(solicitud !== this.props.solicitud){
      this.setState({solicitud, modalType:{title:'Editar',description:solicitud.tipoSolicitud.nombre}})
    }
  }
  componentWillUnmount(){
    this.props.resetSolicitud()
  }

  onUploadFiles =(e) => {

    let dataSend = {}
    if(! isEmpty(this.state.organigram)){
      dataSend = {
        ...dataSend,
        'organigramaFile':this.state.organigram.fileData,
        'organigramaFileName':this.state.organigram.fileName,
      }
    }
    if(! isEmpty(this.state.descriptionJob)){
      dataSend = {
        ...dataSend,
        'descripcionPuestoFile': this.state.descriptionJob.fileData,
        'descripcionPuestoFileName': this.state.descriptionJob.fileName
      }
    }
  }

  onchangeFiles =(e, type) =>{

    const file = e.target.files[0]
    const contentTtype = file.type
    const file_name = slugify(file.name)
    const exp = new RegExp(/.\w+[A-Za-z]$/)
    const [extension] = file.name.match(exp)
    const reader = new FileReader()
    reader.onload = (e) => {
      const file_data = e.target.result.replace(`data:${contentTtype};base64,`, '')
      if(type === 'organigram'){
        this.setState({
          organigram:{
            'fileName':`ORGANIGRAMA${extension}`,
            'fileData':file_data
          }
        })
      }
      if(type === 'descriptionJob'){
        this.setState({
          descriptionJob:{
            'fileName':`DESCRIPCION-PUESTO${extension}`,
            'fileData':file_data
          }
        })
      }
    }
    reader.readAsDataURL(file)

    this.setState({disabledFiles:false})
  }

  onSubmit = (data) => {
    const { user, postSolicitud, updateSolicitud } = this.props
    const { modalType } = this.state
    const keys = Object.keys(data)
    let flawError = 0
    let sendData = {}
    keys.map((key) => {
      if (validateFields(data, key)) {
        flawError += 1
      }
    })
    if(flawError > 0){
      this.setState({messageError:"Favor de llenar los campos."})
      return false
    }
    this.setState({messageError:null})
    sendData = {
      ...data,
      'statusId':"U3RhdHVzQ2F0YWxvZ09iamVjdDox",
      'usuarioId':user.id
    }
    if(! isEmpty(this.state.organigram)){
      sendData = {
        ...sendData,
        'organigramaFile':this.state.organigram.fileData,
        'organigramaFileName':this.state.organigram.fileName,
      }
    }
    if(! isEmpty(this.state.descriptionJob)){
      sendData = {
        ...sendData,
        'descripcionPuestoFile': this.state.descriptionJob.fileData,
        'descripcionPuestoFileName': this.state.descriptionJob.fileName
      }
    }
    if(modalType.title === 'Nueva'){
      postSolicitud(sendData)
    }
    else{
      let dataSolicitud = {
        'data':{
          ...sendData
          ,'id':this.props.solicitud.id
        }
      }
      updateSolicitud(dataSolicitud)
    }
  }

  onBack = () =>this.props.goTo(`/`)

  render() {
    const {loading, error, loadingCatalogs} = this.props
    const { modalType, messageError, loadingFiles, disabledFiles, solicitud } = this.state

    return (
      <PageContainer>
      <Form
        ref={(form) => { this.form = form }}
        onSubmit={this.onSubmit}
      >
        <Header>
          <BackButton src="/assets/back_arrow_icon.svg" disabled={loading} loading={loading} onClick={this.onBack} />
          <TitleName>
            {`Regresar`}
          </TitleName>
        </Header>
        <ProfileHeader
          header={`${modalType.title} Solicitud - ${modalType.description}`}
          submit
          loading={loading||loadingCatalogs}
          disabled={loading||loadingCatalogs}
        />
        {messageError && (
          <ErrorRow>
            <ErrorIsland error={messageError} />
          </ErrorRow>
        )}
        {loadingCatalogs ?
          <LoadingSpinner/>
        :
          <SolicitudForm
            form={this.form}
            // onUploadFiles={this.onUploadFiles}
            onchangeFiles={this.onchangeFiles}
            disabledFiles={disabledFiles}
            loadingFiles={loadingFiles}
            info={solicitud || {}}
          />
        }
      </Form>
    </PageContainer>
    )
  }
}

Solicitud.defaultProps = {
  profile:{},
  error: null,
  loading: false
}
const mapStateToProps = state => ({
  error: state.solicitud.create.error,
  user: state.auth.user,
  loadingCatalogs: state.region.get.fetching
  || state.center.get.fetching
  || state.catalogues.getCatAuthTypes.fetching
  || state.operativeUnit.get.fetching
  || state.solicitud.getOne.fetching
  || state.solicitud.updated.fetching,
  loading: state.solicitud.create.fetching,
  solicitud: state.solicitud.getOne.result
})

const mapDispatchToProps = dispatch => ({
  postSolicitud: (data) => dispatch(SolicitudActions.postSolicitudRequest(data)),
  updateSolicitud: (data) => dispatch(SolicitudActions.updateSolicitudRequest(data)),
  getSolicitudById: (data) => dispatch(SolicitudActions.getSolicitudByIdRequest(data)),
  resetSolicitud: () => dispatch(SolicitudActions.resetSolicitud()),
  goTo: path => dispatch(push(path)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Solicitud))
