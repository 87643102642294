import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import R,{ isEmpty, clone} from 'ramda'
import moment from 'moment'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import SolicitudActions from '../Redux/SolicitudRedux'
import AuthorizationStatusActions, { AuthorizationStatusRedux, AuthorizationStatusTypes } from '../Redux/AuthorizationStatusRedux'
import { AUTHORIZATION } from '../GraphQL'
import { parseDataGraphQL } from '../../utils/Utils'
import { STATUS, SOLICITUD_STAGES } from '../../Constants'

const authorizationStatusCrudObservable = createCRUDObservable({
  mainRedux: AuthorizationStatusRedux,
  reduxPath: 'authorizationStatus',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})

const getAuthorizationStatusEpic = (action$, store, { Api }) => (
  action$.ofType(AuthorizationStatusTypes.getAuthorizationStatusRequest)
    .mergeMap(() => (
      Observable
        .fromPromise(
          Api.query({query: AUTHORIZATION.getAuthorizationStatus,})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              AuthorizationStatusActions.getAuthorizationStatusError(response['message'])
            ]
          }

          const { allCatEstatus:{ edges } } = response.data

          // if (allAuthorizationStatuses.status !== '200') {
          //   

          //   return [
          //     notificationFail(allAuthorizationStatuses.message),
          //     AuthorizationStatusActions.AuthorizationStatusError(allAuthorizationStatuses)
          //   ]
          // }
          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              AuthorizationStatusActions.getAuthorizationStatusSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            // AuthActions.setAuthorized(true),
            AuthorizationStatusActions.getAuthorizationStatusSuccess(data),
          ]
        })
    ))
)

const getNextStatusEpic = (action$, store, { Api }) => (
  action$.ofType(AuthorizationStatusTypes.getNextStatusRequest)
    .mergeMap(({data}) => (
      Observable
        .fromPromise(
          Api.query({query: AUTHORIZATION.getNextStatus,variables:data,fetchPolicy:'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              AuthorizationStatusActions.getNextStatusError(response['message'])
            ]
          }

          const { catEstatus } = response.data

          // if (allAuthorizationStatuses.status !== '200') {
          //   

          //   return [
          //     notificationFail(allAuthorizationStatuses.message),
          //     AuthorizationStatusActions.AuthorizationStatusError(allAuthorizationStatuses)
          //   ]
          // }
          if(isEmpty(catEstatus)){
            return [
              notificationFail('No se encontraron resultados!.'),
              AuthorizationStatusActions.getNextStatusSuccess([]),
            ]
          }
          const data = catEstatus
          const dataSolicitudStage =clone(store.getState().solicitud.solicitudStageData)
          const solicitudStage =clone(store.getState().solicitud.getOne.result)
          let success = []
          if(
            data.codigoInterno == STATUS.authorized
            || data.codigoInterno == STATUS.rejected
            || data.codigoInterno === STATUS.info_completed
            || data.codigoInterno === STATUS.assigned_recruiter
          ){

            dataSolicitudStage.fechaAutorizacion = moment().format("YYYY-MM-DDTHH:mm:ss")
            if((solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.sign_cont && data.codigoInterno === STATUS.authorized) || data.codigoInterno === STATUS.rejected){
              let dataSolicitudStatus = {
                'id': solicitudStage.flujo.solicitud.id,
                'statusId': data.id
              }
              success.push(SolicitudActions.updateSolicitudStatusRequest(dataSolicitudStatus))
            }
          }

          success.push(
            SolicitudActions.updateSolicitudStageRequest(dataSolicitudStage),
            AuthorizationStatusActions.getNextStatusSuccess(data),
          )
          return success
        })
    ))
)

export const authorizationStatusObservers = Object.assign({}, authorizationStatusCrudObservable.observers, {})

export const authorizationStatusEpic = combineEpics(
  authorizationStatusCrudObservable.epic,
  getAuthorizationStatusEpic,
  getNextStatusEpic,
)
