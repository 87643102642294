import { gql } from '@apollo/client';

const login = gql`
mutation
  authenticate(
    $typeAuth:String,
    $token:String,
    $password:String
    $email:String)
  {
    authenticate(
      typeAuth:$typeAuth
      token:$token
      email:$email
      password:$password
      ){
		  accessToken
      expiresIn
      refreshAccessToken
      status
      message
      userAuth{
        id
        nombre
        correo
        status
        deleted
        area{
          id
          nombre
          descripcion
          codigoInterno
          status
          deleted
        }
        role{
          edges{
            node{
              id
              nombre
              descripcion
              codigoInterno
            }
          }
        }
      }
    }
  }
`

const  logout = gql`
mutation
  logout(
    $typeAuth:String!,
    $token:String!)
  {
    logout(
      typeAuth:$typeAuth,
      token:$token)
    {
      accessToken
      refreshAccessToken
      status
      message
    }
  }
`


export {
  login,
  logout,
}
