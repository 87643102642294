import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon } from 'advenio-components'
import { isEmpty, clone } from 'ramda'
import { STATUS } from '../../../Constants'
import { PanelHeader } from '../../ProfilePanel/ProfilePanel.styles'
import { parseDataGraphQL, slugify } from '../../../utils/Utils'
import moment from 'moment'
import { notificationFail, notificationSuccess } from '../../../Data/Redux/NotificationActions'
import AuthorizationStatusActions from '../../../Data/Redux/AuthorizationStatusRedux'
import SolicitudActions from '../../../Data/Redux/SolicitudRedux'
import { throwServerError } from '@apollo/client'

const labelContainerConfig = labelType => `
  ${labelType === 'right' ?
    `position: absolute;
     left: 23px;` : ''}
`

const Label = styled.label`
font-size: 14px;
margin-bottom: 2px;
margin-right: 4px;
display:block;
${props => labelContainerConfig(props.labelType)}
`
const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 120px !important;
  padding-right: 120px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
`
const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin-top: 4%;
`
const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const Checkbox = ({ label, name, value }) => (
  <OriginalCheckbox
    invertedColors
    large
    hideEmptyHelpBlock
    label={label}
    name={name}
    value={value}
  />
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}

class SelectionCandidates extends Component {
  state={
    flawSend:false,
    dataSend:{},
    candidates: [],
    files:[],
    width:0
    // cv:{},
    // curp:{},
    // actaNacimiento:{},
    // imss:{},
    // rfc:{},
    // ife:{},
    // cuentaBancaria:{},
    // comprobanteDomicilio:{},
    // comprobanteEstudios:{},
    // documentoAdicional:{},
  }

  componentWillMount() {
    const { info } = this.props
    if(info.candidatos.edges.length > 0 ){
      let candidates =  clone(info.candidatos.edges)
      this.setState({
        candidates: parseDataGraphQL(candidates)
      })
    }
    this.setState({width: window.innerWidth})
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const {
      validSolicitud,
      messageFail,
      updatedSolicitud,
      user,
      setSolicitudStageData,
      setSolicitudFilesData,
      getNextStatus
    } = nextProps
    const {
      flawSend
      , dataSend
    } = this.state

    if(flawSend && ! isEmpty(dataSend) && !isEmpty(validSolicitud)){
      if(validSolicitud.status.codigoInterno === STATUS.authorized || validSolicitud.status.codigoInterno === STATUS.rejected){
        messageFail('No se puede cambiar el estatus de una etapa previamente autorizada/rechazada')
      }else{
        let dataNextStatus = {
          'id':dataSend.statusId
        }
        let dataSolicitudStage= {
          'id':dataSend.id,
          'statusId':dataSend.statusId,
          'comentarios':dataSend.comentarios,
          'usuarioId': user.id
        }
        let dataSolicitudFiles = clone(this.state.files)
        dataSolicitudFiles =  dataSolicitudFiles.map(f=>({
          ...f
          ,'candidatoId':dataSend.candidato
        }))

        dataSend.usuarioId = user.id
        setSolicitudStageData(dataSolicitudStage)
        setSolicitudFilesData(dataSolicitudFiles)
        getNextStatus(dataNextStatus)
      }
    }
  }

  onchangeFiles =(e, type, id) =>{

    const file = e.target.files[0]
    const contentTtype = file.type
    const exp = new RegExp(/.\w+[A-Za-z]$/)
   const [extension] = file.name.match(exp)

    const file_name = slugify(file.name)
    const reader = new FileReader()
    reader.onload = (e) => {
      const file_data = e.target.result.replace(`data:${contentTtype};base64,`, '')
      let state = this.state
      state.files.push({
          'fileName':type+extension,
          'archivo':file_data,
          'id':id
        })
      this.setState(state)
    }
    reader.readAsDataURL(file)

    // this.setState({disabledFiles:false})
  }

  onSubmit= (data) =>{
    const {  match:{ params: { solicitudStageId }}, validateSolitictud, info } = this.props
    const dataSend = {
      ...data,
      'id':solicitudStageId,
    }

    this.setState({dataSend, flawSend:true})
    if(data.candidato !== 0){
      validateSolitictud({
        'id':solicitudStageId
      })
    }
  }

  renderOptions = () => [{id:0,label:"Seleccionar",value:0}].concat(this.props.status.map(s=>({
    id:s.id
    , label:s.nombre
    , value: s.id
  })))
  renderCandidatesOptions = () => this.state.candidates.map(s=>({
    id:s.id
    , label:s.nombre
    , value: s.id
  }))

  renderFiles = () => {
    const { width } = this.state
    const {
      info
    } = this.props
    if(width > 1799){
      return (
        <div>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index < 4 ? (
                <Col sm={12} md={3}lg={3} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo}  type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 3 && index < 8 ? (
                <Col sm={12} md={3}lg={3} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 7 ? (
                <Col sm={12} md={3}lg={3} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
        </div>
      )
    }
    if(width < 1800 && width > 1399){
      return (
        <div>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index < 3 ? (
                <Col sm={12} md={4}lg={4} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo}  type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 2 && index < 6 ? (
                <Col sm={12} md={4}lg={4} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 5 && index < 9 ? (
                <Col sm={12} md={4}lg={4} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 8 ? (
                <Col sm={12} md={4}lg={4} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
        </div>
      )
    }
    if(width < 1400 && width > 1199){
      return (
        <div>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index < 2 ? (
                <Col sm={12} md={6}lg={6} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo}  type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 1 && index < 4 ? (
                <Col sm={12} md={6}lg={6} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 3  && index < 6? (
                <Col sm={12} md={6}lg={6} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 5  && index < 8? (
                <Col sm={12} md={6}lg={6} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
          <Row>
            { info.requisitos.map((r ,index)=> (
              index  > 7 ? (
                <Col sm={12} md={6}lg={6} style={{marginLeft:0}} key={index}>
                  <Label
                  htmlFor={`${r.codigoInterno}`}
                  labelType={'left'}
                  >
                    {r.nombre}
                  </Label>
                <input id={`${r.codigoInterno}`} disabled={r.archivo} type="file" onChange={e => this.onchangeFiles(e, r.codigoInterno, r.id)} accept="*"></input>
              </Col>
              ):null

            ))}
          </Row>
        </div>
      )
    }
  }

  render() {
    const {
      info
    } = this.props
    const { width } = this.state
    return (
      <Container>
        <PanelHeader tabTitle >Captura de Datos</PanelHeader>
        <Form
          ref={(form) => { this.form = form }}
          onSubmit={this.onSubmit}
        >
          <Grid>
            <Row>
            <Col sm={12} md={4} lg={4}>
                <Select
                  name="candidato"
                  label="Candidato"
                  required
                  value={info.status.id || null}
                  options={this.renderCandidatesOptions()}
                  fullWidth
                />
              </Col>
            </Row>

            {this.renderFiles()}
              <Row>
              <Col sm={12} md={6} lg={6}>
                <TextInput
                  name="comentarios"
                  label="Comentarios"
                  value={info.comentarios || null}
                  textarea
                  required
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Select
                  name="statusId"
                  label="Autorización"
                  required
                  value={info.status.id || null}
                  options={this.renderOptions()}
                  fullWidth
                />
              </Col>
            </Row>
            <Row end="xs">
              <Col sm={12} md={12}>
                <Button primary disabled={info.status.codigoInterno === STATUS.authorized } style={{marginBottom:12}} loading={false} type="submit" >
                  {/* <TextIcon label="Subir archivos" iconType="plus" iconViewBox="-2 -2 24 24" /> */}
                  Guardar
                </Button>
              </Col>
            </Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

SelectionCandidates.propTypes = {
}

SelectionCandidates.defaultProps = {

}

const mapStateToProps = state => ({
  user: state.auth.user,
  status: state.authorizationStatus.get.results,
  validSolicitud: state.solicitud.validSolicitud.result,
})

const mapDispatchToProps = dispatch => ({
  validateSolitictud: (data) => dispatch(SolicitudActions.validateSolicitudRequest(data)),
  updatedSolicitud: (data) => dispatch(SolicitudActions.updateSolicitudRequest(data)),
  getNextStatus: (data) => dispatch(AuthorizationStatusActions.getNextStatusRequest(data)),
  messageFail: msg => dispatch(notificationFail(msg)),
  setSolicitudStageData: data => dispatch(SolicitudActions.setSolicitudStageData(data)),
  setSolicitudFilesData: data => dispatch(SolicitudActions.setSolicitudFilesData(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectionCandidates))
