import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, Colors, Text } from 'advenio-components'
import { STATUS } from '../../Constants'
import { PanelHeader } from '../ProfilePanel/ProfilePanel.styles'
import moment from 'moment'
import CommentsCard  from '../CommentsCard'
import { clone } from 'ramda'

const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
  const Grid = styled(props => (
    <OriginalGrid fluid {...props}>
      {props.children}
    </OriginalGrid>
  ))`
    border: 1px solid #b5b5b5;
    border-radius: 8px;
  `
  const Row = styled(props => (
    <OriginalRow middle="xs" around="lg" {...props}>
      {props.children}
    </OriginalRow>
  ))`
    margin: 1% 0px 15px 0;
    border-bottom: solid 2px;
    border-bottom-color: #777;
    padding-bottom: 15px;
    :last-child{
      border-bottom: solid 0px;
      border-bottom-color: #777;
    }
  `
  const Col = styled(props => (
    <OriginalCol xs={12} {...props}>
      {props.children}
    </OriginalCol>
  ))``

const Info = styled.div`
    line-height: 40px;
    ${({ title })=> !! (title) && `
      font-weight: bold;
      padding-left:12px
    `}
    ${({ data })=> data && `
      line-height :20px;
      padding-left: 24px;
      color: ${Colors.gray700}
    `}
`
class InfoComments extends Component {

  render() {
    const {
      solicitudStage:
      {
        flujo:
        {
          solicitud
        },
        comments
      },
      solicitudStage
    } = this.props
    const solicitudComments = clone(comments)
    return (
      <Container>
        <PanelHeader tabTitle >Comentarios Previos</PanelHeader>
        <Grid>
          {solicitudComments.length === 0 &&(
            <Row >
            <Col sm={12} md={12} lg={12}>
              <Text color={'#54606a'}> Ningún Comentario Previo. </Text>
            </Col>
          </Row>
          )}
          {solicitudComments.length > 0 && solicitudComments.map((comment, index) => (
            <Row key={index}>
              <Col sm={12} md={12} lg={12}>
                <CommentsCard comment={comment} />
              </Col>
            </Row>
          ))}
        </Grid>
      </Container>
    )
  }
}

InfoComments.propTypes = {
}

InfoComments.defaultProps = {

}

const mapStateToProps = ({ auth }) => ({

})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoComments)
