import createCRUDObservable from 'redux-observable-crud'
import cookie from 'react-cookies'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { push } from 'react-router-redux'
import { AuthRedux, Types as AuthTypes, Actions as AuthActions } from '../../Data/Redux/AuthRedux'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import { getCookie, responseParse } from '../../utils/Utils'
import { AUTH } from '../GraphQL'

export const authEpic = (COOKIE_DOMAIN) => {
    const authCrudObservable = createCRUDObservable({
        mainRedux: AuthRedux,
        reduxPath: 'auth',
    })

    const setAuth = ({ accessToken, expiresIn, userAuth }) => {
        const user = {...userAuth }
        const tokenExpiresAt = new Date(Date.now() + (expiresIn * 1000));
        const cookie_auth_token = `vacantes_api_key=${accessToken}; domain=${COOKIE_DOMAIN}; expires=${tokenExpiresAt}; sameSite=none; secure=true; path=/;`
            // setAuthHeaders(access_token) // aqui podemos ponder el bearer
            // document.cookie = cookie_auth_token
        cookie.save('vacantes_api_key', accessToken, { domain: COOKIE_DOMAIN, sameSite: false, secure: true, path: '/' })
            // document.cookie = `vacantes_api_key_expires_at=${tokenExpiresAt}; domain=${COOKIE_DOMAIN}; expires=${tokenExpiresAt}; sameSite=none;  secure=true; path=/;`
        cookie.save('vacantes_api_key_expires_at', tokenExpiresAt, { domain: COOKIE_DOMAIN, sameSite: false, secure: true, path: '/' })
        if (user) {
            Object.keys(user).map((key) => {
                if (typeof user[key] === 'string') {
                    user[key] = encodeURIComponent(user[key])
                }

                return user[key]
            })

            // document.cookie = `vacantes_user=${JSON.stringify(user)}; domain=${COOKIE_DOMAIN}; expires=${tokenExpiresAt}; sameSite=none; secure=true; path=/;`
            cookie.save('vacantes_user', JSON.stringify(user), { domain: COOKIE_DOMAIN, sameSite: false, secure: true, path: '/' })

        }
    }
    const loginEpic = (action$, store, { Api }) =>
        action$
        .ofType(AuthTypes.loginRequest)
        .mergeMap(({ data }) => (
            Observable
            .fromPromise(
                Api.mutate({
                    mutation: AUTH.login,
                    variables: {
                        typeAuth: data.typeAuth,
                        token: data.token,
                        email: data.email,
                        password: data.password
                    }
                })
                .then(response => response)
                .catch(err => err)
            )
            .flatMap((response) => {

                if (response instanceof ApolloError) {
                    return [
                        notificationFail(response['message']),
                        AuthActions.loginFailure(response['message'])
                    ]
                }
                // console.log(response)
                const { authenticate } = response.data

                if (authenticate.status !== '200') {
                    return [
                        notificationFail(authenticate.message),
                        AuthActions.loginFailure(authenticate)
                    ]
                }

                const { userAuth } = authenticate
                setAuth(authenticate)
                return [
                    // AuthActions.setAuthorized(true),
                    AuthActions.loginSuccess(userAuth),
                ]

            })
        ))

    // const impersonateUserEpic = (action$, store, { Api }) => (
    //   action$
    //     .ofType(AuthTypes.impersonateUserRequest)
    //     .mergeMap(({ data }) => (
    //       Observable
    //         .fromPromise(Api.auth.impersonateUser(data)
    //           .then(response => response)
    //           .catch(error => error))
    //         .flatMap((response) => {
    //           if (response.status !== 200) {
    //             return [AuthActions.impersonateUserFailure(response.error)]
    //           }

    //           const { data: responseData, data: { user } } = response
    //           const originalUser = { ...user }

    //           setAuth(responseData)

    //           return [
    //             AuthActions.impersonateUserSuccess(originalUser),
    //           ]
    //         })
    //     ))
    // )

    // const stopImpersonateUserEpic = (action$, store, { Api }) => (
    //   action$
    //     .ofType(AuthTypes.stopImpersonateUserRequest)
    //     .mergeMap(() => (
    //       Observable
    //         .fromPromise(Api.auth.stopImpersonateUser()
    //           .then(response => response)
    //           .catch(error => error))
    //         .flatMap((response) => {
    //           if (response.status !== 200) {
    //             return [AuthActions.stopImpersonateUserFailure(response.error)]
    //           }

    //           const { data: responseData, data: { user } } = response
    //           const originalUser = { ...user }

    //           setAuth(responseData)

    //           return [
    //             AuthActions.stopImpersonateUserSuccess(originalUser),
    //           ]
    //         })
    //     ))
    // )

    const logoutEpic = (action$, { dispatch }) =>
        action$
        .ofType(AuthTypes.logout)
        .map(({ data: { shouldDeleteCookies, error } }) => {
            if (shouldDeleteCookies) {
                document.cookie = `vacantes_api_key=; domain=${COOKIE_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
                document.cookie = `vacantes_user=; domain=${COOKIE_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
                document.cookie = `vacantes_api_key_expires_at=; domain=${COOKIE_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
                dispatch({ type: 'RESET' })
            }
            dispatch(push('/login'))

            return {
                type: AuthTypes.setAuthStatus,
                authenticated: false,
                user: null,
                error,
            }
        })

    const checkAuthStatusEpic = (action$, store, { Api }) => (
        action$.ofType(AuthTypes.checkAuthStatus)
        .mergeMap(() => {
            const token = getCookie('vacantes_api_key')
            const tokenExpiryTime = getCookie('vacantes_api_key_expires_at')
            const tokenHasExpired = tokenExpiryTime && Date.now() >= tokenExpiryTime
            const user = getCookie('vacantes_user') && JSON.parse(getCookie('vacantes_user'))

            if (user) {
                Object.keys(user).map((key) => {
                    if (typeof user[key] === 'string') {
                        user[key] = decodeURIComponent(user[key])
                    }

                    return user[key]
                })
            }

            const actions = () => ([{
                type: AuthTypes.setAuthStatus,
                authenticated: Boolean(token && user),
                user,
            }])

            if (tokenHasExpired) {
                // //refresh token request
                // return Observable
                //   .fromPromise(Api.auth.refreshToken()
                //     .then(response => response)
                //     .catch(error => error))
                //   .mergeMap((response) => {
                //     if (response.status === 500) {
                //       return [AuthActions.logout({ shouldDeleteCookies: true })]
                //     }

                //     setAuth(response.data.data)

                //     return actions()
                //   })
            }
            // put token en url
            // if (token) setAuthHeaders(token)

            return actions()
        }))

    const checkAuthorizedEpic = (action$, store, { Api }) => (
        action$
        .ofType(AuthTypes.checkAuthStatus, AuthTypes.loginSuccess)
        .mergeMap(() => {
            const { authorized, authenticated } = store.getState().auth

            if (authorized === null && !authenticated) {
                return [AuthActions.setAuthorized(null)]
            }
            // return (
            //   Observable
            //     .fromPromise(Api.auth.checkAuthorized()
            //       .then(response => response)
            //       .catch((error) => error ))
            //     .flatMap((response) => {
            //       let status = null
            //       if (response.status) {
            //         status = response.status !== 403
            //       } else if (response.data.status) {
            //         status = response.data.status !== 403
            //       }

            //     })
            //     )
            return [AuthActions.setAuthorized(true)]
        }))

    const authObservers = Object.assign({}, authCrudObservable.observers, {
        loginEpic,
        // impersonateUserEpic,
        logoutEpic,
        checkAuthStatusEpic,
        checkAuthorizedEpic,
        // stopImpersonateUserEpic,
    })

    return combineEpics(
        authCrudObservable.epic,
        ...Object.values(authObservers),
    )
}
