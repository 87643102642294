import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Tabs, Card, Modal } from 'advenio-components'
import PropTypes from 'prop-types'
import { isEmpty, path } from 'ramda'
import { TABS_PROFILE, SOLICITUD_STAGES } from '../../Constants'
import { parseDataGraphQL } from '../../utils/Utils'

import InfoGeneral from '../../Components/SatgeAuthorization/InfoGeneral'
import InfoComments from '../../Components/SatgeAuthorization/InfoComments'
import InfoCompensations from '../../Components/SatgeAuthorization/InfoCompensations'
import InfoAditional from '../../Components/SatgeAuthorization/InfoAditional'

import Compensations from '../../Components/SatgeAuthorization/CaptureData/Compensations'
import Generic from '../../Components/SatgeAuthorization/CaptureData/Generic'
import InfoAdicionalSol from '../../Components/SatgeAuthorization/CaptureData/InfoAdicionalSol'
import AssignRecruiter from '../../Components/SatgeAuthorization/CaptureData/AssignRecruiter'
import SelectionComputer from '../../Components/SatgeAuthorization/CaptureData/SelectionComputer'
import SelectionTerna from '../../Components/SatgeAuthorization/CaptureData/SelectionTerna'
import SelectionCandidates from '../../Components/SatgeAuthorization/CaptureData/SelectionCandidates'
import AutorizationDO from '../../Components/SatgeAuthorization/CaptureData/AutorizationDO'
import AutorizationADP from '../../Components/SatgeAuthorization/CaptureData/AutorizationADP'
import AutorizationSolicitud from '../../Components/SatgeAuthorization/CaptureData/AutorizationSolicitud'

const Container = styled.div`
  width: 78%;
  margin-left: 7px;
`

const PageContainer = styled(Card)`
  margin-left: 16px;
  margin-right: 8px;
  box-sizing: content-box !important;
`
const DesktopContainer = styled.div`
  display: none;
  @media (min-width:768px) {
    display: block;
  }
`
const Views = (props , solicitudId = null) => {
  const { solicitudStage, user }= props

  const views = {
    'Vista General': {
      tabs: [],
      views: [],
    },
  }
  views['Vista General'].tabs = [
      { title: TABS_PROFILE.infoGeneral },
      { title: TABS_PROFILE.dataCapture},
  ]
  views['Vista General'].views = [
    <InfoGeneral solicitudStage = { solicitudStage } />
  ]
  let info = {
    ...solicitudStage.flujo.solicitud,
    'status':solicitudStage.status,
    'comentarios': solicitudStage.comentarios,
    'assigned': solicitudStage.autorizador,
    'requisitos': parseDataGraphQL(solicitudStage.requisitos.edges)
  }
  if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.aut_comp){
    views['Vista General'].views.push(<Compensations info={info}/>)
  }
  else if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.aut_do){
    views['Vista General'].views.push(<AutorizationDO info={info}/>)
  }
  // else if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.info_adl_sol){
  //   views['Vista General'].views.push(<InfoAdicionalSol info={info}/>)
  // }
  else if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.sel_ter){
    views['Vista General'].views.push(<SelectionTerna info={info}/>)
  }
  else if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.sel_can){
    views['Vista General'].views.push(<SelectionCandidates  info={info}/>)
  }
  else if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.sel_compu){
    views['Vista General'].views.push(<SelectionComputer info={info}/>)
  }
  else if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.asg_rec){
    views['Vista General'].views.push(<AssignRecruiter info={info}/>)
  }
  else if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.aut_vacante){
    views['Vista General'].views.push(<AutorizationSolicitud info={info}/>)
  }
  else if(solicitudStage.etapa.etapa.codigoInterno === SOLICITUD_STAGES.info_adl_nom){
    if( solicitudStage.documentos !== undefined && !isEmpty(solicitudStage.documentos)){
      info = {
        ...info,
        'documentos': solicitudStage.documentos
      }
    }
    views['Vista General'].views.push(<AutorizationADP info={info}/>)
  }
  else{
    views['Vista General'].views.push(<Generic info={info}/>)
  }
  views['Vista General'].tabs.push(
      { title: TABS_PROFILE.commPrevious },
  )
  views['Vista General'].views.push(
    <InfoComments solicitudStage = { solicitudStage } />,
  )
  if(solicitudStage.etapa.etapa.codigoInterno !== SOLICITUD_STAGES.cred_ti){
    views['Vista General'].tabs.push(
        { title: TABS_PROFILE.infoCompensation },
    )
    views['Vista General'].views.push(
      <InfoCompensations solicitudStage = { solicitudStage } />,
    ) 
  }
  views['Vista General'].tabs.push(
    { title: TABS_PROFILE.infoAditional },
  )
  views['Vista General'].views.push(
    <InfoAditional solicitudStage = { solicitudStage } />,
  )
  return views
}

class ProfilePageContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    const {  solicitudStage, loading } = this.props
    return (
      <Container>
        <DesktopContainer>
          <PageContainer>
            {!isEmpty(solicitudStage) && !loading ?
              <Tabs
                tabs={Views(this.props)['Vista General'].tabs}
                content={Views(this.props)['Vista General'].views}
                borderBottom
                activeTab={'Información General'}
              />
              :
              <PageContainer>
                <Modal isOpened loading />
              </PageContainer>
            }
          </PageContainer>
        </DesktopContainer>
      </Container>
    )
  }
}

ProfilePageContent.propTypes = {
}

ProfilePageContent.defaultProps = {
  loading: false,
}

const mapStateToProps = state => ({
  user: state.auth
})


const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePageContent)
