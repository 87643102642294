import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { gql } from '@apollo/client';
import R,{isEmpty} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import UserActions, { UserRedux, UserTypes } from '../Redux/UserRedux'
import { USER } from '../GraphQL'
import moment from 'moment'
import { parseDataGraphQL, paramsGraphQL } from '../../utils/Utils'

const userCrudObservable = createCRUDObservable({
  mainRedux: UserRedux,
  reduxPath: 'user',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})

const getUserEpic = (action$, store, { Api }) => (
  action$.ofType(UserTypes.userByRoleRequest)
    .mergeMap(({ data }) => (
      Observable
        .fromPromise(
          Api.query({
            query: USER.getAllUsersByRole
            ,variables: data
            ,fetchPolicy: 'no-cache'
          })
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              UserActions.userByRoleError(response['message'])
            ]
          }

          const { allUsuarios:{ edges } } = response.data

          // if (allUseres.status !== '200') {
          //   

          //   return [
          //     notificationFail(allUseres.message),
          //     UserActions.UserError(allUseres)
          //   ]
          // }
          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              UserActions.userByRoleSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            // AuthActions.setAuthorized(true),
            UserActions.userByRoleSuccess(data),
          ]
        })
    ))
)
const getUsersActivesEpic = (action$, store, { Api }) => (
  action$.ofType(UserTypes.userActivesRequest)
    .mergeMap(({data}) => {
      let getUsersActives=''
      let sendData={}
      let params = '', filters = '',binding = '', paramsGroup = ''
      if(! isEmpty(data.data)){
        ({ params, binding } = paramsGraphQL(USER.usersAttr, data.data))
        sendData={...data.data}
        filters= `(filters:{ ${binding} })`
        paramsGroup = `(${params})`
      }
      const createQuery = USER.getAllUserActives.replace('$params',paramsGroup).replace('$filters',filters)
      getUsersActives = gql`${createQuery}`
      sendData={...sendData ,}

      return Observable
        .fromPromise(
          Api.query({query: getUsersActives,variables: sendData, fetchPolicy: 'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              UserActions.userActivesError(response['message'])
            ]
          }

          const { allUsuarios:{ edges } } = response.data

          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              UserActions.userActivesSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            UserActions.userActivesSuccess(data),
          ]
        })
      })
)

export const userObservers = Object.assign({}, userCrudObservable.observers, {})

export const userEpic = combineEpics(
  userCrudObservable.epic,
  getUserEpic,
  getUsersActivesEpic,
)
