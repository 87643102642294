import React from 'react'
import { MoonLoader } from 'react-spinners'
import styled from 'styled-components'
import { Colors } from 'advenio-components'

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  * {
  box-sizing: content-box;
`

const LoadingSpinner = () => <Spinner><MoonLoader color={Colors.blue500} size={25} /></Spinner>

export default LoadingSpinner
