import { gql } from '@apollo/client';

const usersAttr = {
  '$statusIdIn': `[Int!]`,
  '$status': 'Int',
  '$deleted': 'Int',
  '$after': 'String',
  '$first': 'Int',
  '$before': 'String',
  '$last': 'Int'
}

const  getAllUsersByRole = gql`
query
allUsuarios($rolUsuario:String) {
  allUsuarios(filters: {rolUsuario:$rolUsuario}) {
    edges {
      node {
        id
        nombre
        correo
      }
    }
  }
}
`
const getAllUserActives = `
query
allUsuarios $params {
  allUsuarios
    $filters
  {
    edges{
      node{
        id
        nombre
        correo
        role{
          edges{
            node{
              id
              nombre
            }
          }
        }
      }
    }
  }
}`

export {
  usersAttr,
  getAllUsersByRole,
  getAllUserActives,
}
