import * as AUTH from './Auth';
import * as SOLICITUD from './Solicitud'
import * as CENTER from './Center'
import * as OU from './OperativeUnit'
import * as REGION from './Region'
import * as AUTHORIZATION from './Authorization'
import * as USER from './User'
import * as JOB from './Job'
import * as FLOWAUTHORIZATIONSATGES from './FlowAuthorizationSatges'
import * as CATALOGUES from './Catalogues'
import { Types as TYPES } from './Types';


export {
    AUTH,
    SOLICITUD,
    CENTER,
    OU,
    REGION,
    AUTHORIZATION,
    USER,
    JOB,
    FLOWAUTHORIZATIONSATGES,
    CATALOGUES,
    TYPES,
}