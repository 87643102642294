import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { gql } from '@apollo/client';
import { Observable } from 'rxjs'
import { push } from 'react-router-redux'
import R,{isEmpty, clone, last} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import SolicitudActions, { SolicitudRedux, SolicitudTypes } from '../Redux/SolicitudRedux'
import SendNotificationActions, { SendNotificationRedux, SendNotificationTypes } from '../Redux/SendNotificationRedux'
import AuthorizationStatusActions from '../Redux/AuthorizationStatusRedux'
import { SOLICITUD } from '../GraphQL'
import moment from 'moment'
import { parseDataGraphQL, paramsGraphQL, paramsGraphQLPagination } from '../../utils/Utils'
import { SOLICITUD_STAGES, STATUS } from '../../Constants';

const solicitudCrudObservable = createCRUDObservable({
  mainRedux: SolicitudRedux,
  reduxPath: 'solicitud',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})
const formSerializer = (data, headers) => {
  const formData = new FormData();
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }

  headers.set('Content-Type', 'application/x-www-form-urlencoded');

  return {body: formData, headers};
}

const getAllSolicitudEpic = (action$, store, { Api }) => (
  action$.ofType(SolicitudTypes.getAllSolicitudRequest)
    .mergeMap(({ data }) => {

      let getAllSolicitudsFilter=''
      let sendData={}
      let params = '', binding =''
      if(! isEmpty(data.data)){
        ({ params, binding } = paramsGraphQL(SOLICITUD.allSolicitudsFilterAttr, data.data))
        sendData={...data.data}
      }
      const {paramsPage, pagination } = paramsGraphQLPagination(SOLICITUD.allSolicitudsFilterAttr, data.pagination)
      const paramsGroup = params + paramsPage
      const createQuery = SOLICITUD.getAllSolicitudsFilter.replace('$params',paramsGroup).replace('$binding',binding).replace('$pagination',pagination)
      getAllSolicitudsFilter = gql`${createQuery}`
      sendData={...sendData , ...data.pagination}
      return Observable
        .fromPromise(
          Api.query(
            // data !== null ?
              { query: getAllSolicitudsFilter, variables: sendData, fetchPolicy: 'no-cache'}
            // : { query: SOLICITUD.getAllSolicituds, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.getAllSolicitudError(response['message'])
            ]
          }

          const { allSolicitudes:{ edges }, allSolicitudes } = response.data

          if(isEmpty(edges)){
            const stateSolicitud =clone(store.getState().solicitud.get.results)
            //TODO: aqui se debe de set la url con los parametros del state y validar si es ultima pagina o primera pagina
            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.getAllSolicitudSuccess(stateSolicitud),
            ]
          }
          let data = parseDataGraphQL(edges)
          data = {
            'pageInfo':allSolicitudes['pageInfo'],
            'data':[...data]
          }

          return [
            // AuthActions.setAuthorized(true),
            SolicitudActions.getAllSolicitudSuccess(data),
          ]
        })
    })
)
const getsolicitudByIdEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.getSolicitudByIdRequest)
.mergeMap(({ data }) => {
      const createQuery = SOLICITUD.getSolicitudById
      const getSolicitudById = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
            // data !== null ?
              { query: getSolicitudById, variables: data, fetchPolicy: 'no-cache'}
            // : { query: SOLICITUD.getAllSolicituds, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.getSolicitudByIdError(response['message'])
            ]
          }

          const { solicitud } = response.data

          if(isEmpty(solicitud)){
            const stateSolicitud =clone(store.getState().solicitud.getOne.results)
            //TODO: aqui se debe de set la url con los parametros del state y validar si es ultima pagina o primera pagina
            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.getSolicitudByIdSuccess(stateSolicitud),
            ]
          }
          let data = solicitud

          return [
            // AuthActions.setAuthorized(true),
            SolicitudActions.getSolicitudByIdSuccess(data),
          ]
        })
    })
)

const getSolicitudStepByIdEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.getSolicitudStepByIdRequest)
.mergeMap(({ data }) => {
      let getSolicitudStepById=''
      const createQuery = SOLICITUD.getSolicitudStepById
      getSolicitudStepById = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: getSolicitudStepById, variables: data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.getSolicitudStepByIdError(response['message'])
            ]
          }

          const { solicitudEtapa } = response.data

          if(isEmpty(solicitudEtapa)){
            const stateSolicitud =clone(store.getState().solicitud.getOne.result)

            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.getSolicitudStepByIdSuccess(stateSolicitud),
            ]
          }
          let data = solicitudEtapa

          return [
            // AuthActions.setAuthorized(true),
            SolicitudActions.getSolicitudStepByIdSuccess(data),
          ]
        })
    })
)

const validateSolicitudEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.validateSolicitudRequest)
.mergeMap(({ data }) => {
      let getSolicitudStepById=''
      const createQuery = SOLICITUD.getSolicitudStepById
      getSolicitudStepById = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: getSolicitudStepById, variables: data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.validateSolicitudError(response['message'])
            ]
          }

          const { solicitudEtapa } = response.data

          if(isEmpty(solicitudEtapa)){
            const stateSolicitud =clone(store.getState().solicitud.getOne.result)

            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.validateSolicitudSuccess(stateSolicitud),
            ]
          }
          let data = solicitudEtapa

          return [
            // AuthActions.setAuthorized(true),
            SolicitudActions.validateSolicitudSuccess(data),
          ]
        })
    })
)

const updateSolicitudEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.updateSolicitudRequest)
.mergeMap(({ data }) => {
      let updateSolicitud=''
      let { params, binding } = paramsGraphQL(SOLICITUD.solicitudAttr, data.data)
      const createQuery = SOLICITUD.updateSolicitud.replace('$params',params).replace('$binding',binding)
      updateSolicitud = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: updateSolicitud, variables: data.data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.updateSolicitudError(response['message'])
            ]
          }

          const { updateSolicitud } = response.data

          if(isEmpty(updateSolicitud)){
            const stateSolicitud =clone(store.getState().solicitud.getOne.result)

            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.updateSolicitudSuccess(stateSolicitud),
            ]
          }
          let dataResponse = updateSolicitud

          let success= [
            SolicitudActions.updateSolicitudSuccess(dataResponse),
            notificationSuccess('Solicitud actualizada exitosamente.'),
          ]
          if(data.paramsExtra !== undefined){
            success.push(AuthorizationStatusActions.getNextStatusRequest({'id':data.paramsExtra.nextStatus}),)
          }
          return success 
        })
    })
)
const updateSolicitudStatusEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.updateSolicitudStatusRequest)
.mergeMap(({ data }) => {
      let updateSolicitud=''
      let { params, binding } = paramsGraphQL(SOLICITUD.solicitudAttr, data)
      const createQuery = SOLICITUD.updateSolicitud.replace('$params',params).replace('$binding',binding)
      updateSolicitud = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: updateSolicitud, variables: data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.updateSolicitudStatusError(response['message'])
            ]
          }

          const { updateSolicitud } = response.data

          if(isEmpty(updateSolicitud)){
            const stateSolicitud =clone(store.getState().solicitud.getOne.result)

            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.updateSolicitudStatusSuccess(stateSolicitud),
            ]
          }
          let dataResponse = updateSolicitud

          return [
            SolicitudActions.updateSolicitudStatusSuccess(dataResponse),
          ]
        })
    })
)

const updateSolicitudStageEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.updateSolicitudStageRequest)
.mergeMap(({ data }) => {
      let updateSolicitudStage =''
      let { params, binding } = paramsGraphQL(SOLICITUD.solicitudStageAttr, data)
      const createQuery = SOLICITUD.updateSolicitudStage.replace('$params',params).replace('$binding',binding)
      updateSolicitudStage = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: updateSolicitudStage, variables: data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.updateSolicitudStageError(response['message'])
            ]
          }

          const { updateStep:{ solicitudEtapa } } = response.data

          if(isEmpty(solicitudEtapa)){
            const stateSolicitud =clone(store.getState().solicitud.getOne.result)

            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.updateSolicitudStageSuccess(stateSolicitud),
            ]
          }
          let dataResponse = solicitudEtapa
          const solicitudCandidatesData =clone(store.getState().solicitud.solicitudCandidatesData)
          const solicitudFilesData =clone(store.getState().solicitud.solicitudFilesData)

           let success = [
            SolicitudActions.updateSolicitudStageSuccess(dataResponse),
            notificationSuccess('Se actualizo la etapa exitosamente.'),
            SolicitudActions.resetUpdateSolicitud(),
            AuthorizationStatusActions.resetNextStep(),
            SendNotificationActions.postSendNotificationRequest({'id':dataResponse.id})
          ]
          if(solicitudEtapa.etapa.etapa.codigoInterno === SOLICITUD_STAGES.sel_ter && solicitudCandidatesData.length > 0 ){
            solicitudCandidatesData.map(candidate => {
              let candidatoSolicitud =  solicitudEtapa.flujo.solicitud.candidatos.edges.filter(c=>
                c.node.correoElectronico === candidate.correoElectronico
                && c.node.nombre === candidate.nombre
                && c.node.apellidoPaterno === candidate.apellidoPaterno
                &&c.node.apellidoMaterno === candidate.apellidoMaterno)|| []
              if(isEmpty(candidatoSolicitud)){
                success.push(SolicitudActions.addCandidatesSolicitudRequest(candidate))
              }
            })
          }
          if(solicitudEtapa.etapa.etapa.codigoInterno === SOLICITUD_STAGES.sel_can && solicitudFilesData.length > 0 ){
            solicitudFilesData.map(file => {
              let fileSolicitud =  solicitudEtapa.requisitos.edges.filter(f=>f.node.id === file.id && f.node.archivo === null) || []
              if(! isEmpty(fileSolicitud)){
                success.push(SolicitudActions.addFileSolicitudRequest(file))
              }
            })
          }
          // solicitudEtapa.status.codigoInterno === STATUS.authorized ? success.push(push(`/authorizations`),) : null
          return success
        })
    })
)

const addCandidatesSolicitudEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.addCandidatesSolicitudRequest)
.mergeMap(({ data }) => {
      let addCandidatesSolicitud=''
      const createQuery = SOLICITUD.addCandidatesSolicitud
      addCandidatesSolicitud = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: addCandidatesSolicitud, variables: data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.addCandidatesSolicitudError(response['message'])
            ]
          }

          const { addCandidato:{ candidato } } = response.data
          const solicitudStageData =clone(store.getState().solicitud.getOne.result)
          const solicitudCandidatesData =clone(store.getState().solicitud.solicitudCandidatesData)
          const lastCandidate = last(solicitudCandidatesData)

          if(isEmpty(candidato)){
            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.addCandidatesSolicitudSuccess(),
            ]
          }

          let success =[
            // AuthActions.setAuthorized(true),
            SolicitudActions.addCandidatesSolicitudSuccess(),
          ]
          if(lastCandidate.nombre === candidato.nombre){
            success.push( SolicitudActions.getSolicitudStepByIdRequest({'id':solicitudStageData.id}),)
          }

          return success
        })
    })
)
const addFileSolicitudEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.addFileSolicitudRequest)
.mergeMap(({ data }) => {
      let addFileSolicitud=''
      const createQuery = SOLICITUD.addFileSolicitud
      addFileSolicitud = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: addFileSolicitud, variables: data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.addFileSolicitudError(response['message'])
            ]
          }

          const { addFileSolicitud:{ solicitudEtapaRequisitos } } = response.data
          const solicitudStageData =clone(store.getState().solicitud.getOne.result)
          const solicitudFilesData =clone(store.getState().solicitud.solicitudFilesData)
          const lastFile = last(solicitudFilesData)

          if(isEmpty(solicitudEtapaRequisitos)){
            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudActions.addFileSolicitudSuccess(),
            ]
          }

          let success =[
            // AuthActions.setAuthorized(true),
            SolicitudActions.addFileSolicitudSuccess(),
          ]
          if(lastFile.id === solicitudEtapaRequisitos.id){
            success.push( SolicitudActions.getSolicitudStepByIdRequest({'id':solicitudStageData.id}),)
          }

          return success
        })
    })
)

const getSolicitudCommentsEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.getSolicitudCommentsRequest)
.mergeMap(({ data }) => {
      let getSolicitudById=''
      const createQuery = SOLICITUD.getSolicitudComments
      getSolicitudById = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: getSolicitudById, variables: data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.getSolicitudCommentsError(response['message'])
            ]
          }

          const { allSolicitudEtapas:{ edges } } = response.data
          const stateSolicitud =clone(store.getState().solicitud.getOne.result)
          let data = {
            ...stateSolicitud,
            'comments':parseDataGraphQL(edges)
          }

          if(isEmpty(edges)){
            return [
              // notificationFail('No se encontraron resultados!.'),
              SolicitudActions.getSolicitudCommentsSuccess(data),
            ]
          }

          return [
            // AuthActions.setAuthorized(true),
            SolicitudActions.getSolicitudCommentsSuccess(data),
          ]
        })
    })
)
const getSolicitudDocumentsEpic = (action$, store, { Api }) => (
action$.ofType(SolicitudTypes.getSolicitudDocumentsRequest)
.mergeMap(({ data }) => {
      let getSolicitudById=''
      const createQuery = SOLICITUD.getSolicitudDocuments
      getSolicitudById = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query(
              { query: getSolicitudById, variables: data, fetchPolicy: 'no-cache'}
            )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.getSolicitudDocumentsError(response['message'])
            ]
          }

          const { allEtapaRequisitos:{ edges } } = response.data
          const stateSolicitud =clone(store.getState().solicitud.getOne.result)
          let data = {
            ...stateSolicitud,
            'documentos':parseDataGraphQL(edges)
          }

          if(isEmpty(edges)){
            return [
              // notificationFail('No se encontraron resultados!.'),
              SolicitudActions.getSolicitudDocumentsSuccess(data),
            ]
          }

          return [
            // AuthActions.setAuthorized(true),
            SolicitudActions.getSolicitudDocumentsSuccess(data),
          ]
        })
    })
)

const postSolicitudEpic = (action$, store, { Api }) => (
  action$.ofType(SolicitudTypes.postSolicitudRequest)
    .mergeMap(({ data }) => {

      const {params, binding } = paramsGraphQL(SOLICITUD.solicitudAttr, data)
      const createMutation = SOLICITUD.postSolicitud.replace('$params',params).replace('$binding',binding)

      const postSolicitud = gql`${createMutation}`
      return Observable
        .fromPromise(
          Api.mutate({
            mutation: postSolicitud
            ,variables: data
            , fetchPolicy: 'no-cache'
          })
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {
          if (response instanceof ApolloError) {
          
            return [
              notificationFail(response['message']),
              SolicitudActions.postSolicitudError(response['message'])
            ]
          }

          if(response.data.addSolicitud.edges !== undefined){

            const { addSolicitud:{ edges } } = response.data
            if(isEmpty(edges)){
              return [
                notificationFail('No se encontraron resultados!.'),
                SolicitudActions.postSolicitudSuccess([]),
              ]
            }
            return [
              // AuthActions.setAuthorized(true),
              SolicitudActions.postSolicitudSuccess([]),
              push(`/`),
            ]
          }
          const { addSolicitud:{ solicitud } } = response.data

          const data = solicitud

          return [
            // AuthActions.setAuthorized(true),
            SolicitudActions.postSolicitudSuccess([]),
            notificationSuccess('Se guardo exitosamente.'),
            push(`/`),
          ]
        })
      })
)

export const solicitudObservers = Object.assign({}, solicitudCrudObservable.observers, {})

export const solicitudEpic = combineEpics(
  solicitudCrudObservable.epic,
  getAllSolicitudEpic,
  postSolicitudEpic,
  getSolicitudStepByIdEpic,
  getSolicitudCommentsEpic,
  validateSolicitudEpic,
  updateSolicitudEpic,
  updateSolicitudStageEpic,
  updateSolicitudStatusEpic,
  addCandidatesSolicitudEpic,
  addFileSolicitudEpic,
  getsolicitudByIdEpic,
  getSolicitudDocumentsEpic,
)
