import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ApolloProvider } from '@apollo/client';
import { msalConfig } from "./auth365Config";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import apolloClient from './Data/Api';
import App from './Containers/App'
import {SENTRY_URL,SENTRY_ENV} from './config'


/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
 const msalInstance = new PublicClientApplication(msalConfig);

function render(Root) {
  if(SENTRY_ENV !=='development'){
    Sentry.init({
        dsn:`https://${SENTRY_URL}`,
        environment:`${SENTRY_ENV}`,
    });
  }
  ReactDOM.render(
    <AppContainer>
      <MsalProvider instance={msalInstance}>
      <ApolloProvider client={apolloClient}>
        <Root />
      </ApolloProvider>
      </MsalProvider>
    </AppContainer>,
    document.getElementById('root'),
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./Containers/App', () => {
    render(App)
  })
}
