import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Pills } from './ProfilePanel/ProfilePanel.styles.jsx'
import moment from 'moment'

const Container = styled.div`
  background-color: #F0F0F0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 8px;
  border-radius: 4px;
  margin:4px 0 4px 0;
  width: 170px;
  border:none;
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  width: auto;
  @media (min-width:1024px) {
    padding: 8px;
    border-radius: 4px;
    margin:4px 0 4px 0;
  }
  @media (min-width:1440px) {
    padding: 8px;
    border-radius: 4px;
    margin:4px 0 4px 0;
  }
`
const CommentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: auto;
  @media (min-width:1024px) {
    height: auto;
    padding-top: 0;
  }
`
const CommentInfoHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
const CommentInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const CommentPhoto = styled.div`
  background-image: ${props => props.profile_image ? 'url(' + props.profile_image + ')' : ''};
  background-size: cover;
  background-position: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  @media (min-width:1024px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width:1440px) {
    width: 40px;
    height: 40px;
  }
`
const CommentName = styled.span`
  color: #EA3B3B;
  font-size: 14px;
  font-weight: bold;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  @media (min-width:1024px) {
    font-size: 14px;
    max-width: 150px;
    white-space: normal;

  }
  @media (min-width:1440px) {
    font-size: 16px;
    max-width: 200px;
  }
`
const CommentBD = styled.span`
  color: rgba(72,71,71,0.68);
  font-size: 13px;
  margin-top: 4px;
`
const CommentInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 5px;
  width:auto;
`

const ExtraInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0 4px 0;
  width: 100%;
  font-weight: 600;
  padding-top: 10px;
`

const CommentStatus = styled.div`
  color: #75B753;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e1eed8;
  border-radius: 10px;
  margin: 4px 4px 4px 4px;
  padding: .2em 1em .2em 1em
`
const CommentDate = styled.span`
  color: rgba(72,71,71,0.68);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
`

const CommentsCard = props => (
  // <ParentButton {...props} disabled={!props.inCenter} onClick={props.inCenter && props.onClick}>
  <Container border>
    <CommentContainer >
      <CommentInfoHeader>
        <CommentPhoto profile_image={'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'} />
        <CommentInfo>
          <CommentName>
            {props.comment.etapa.autorizador !== null ?props.comment.etapa.autorizador.nombre : props.comment.autorizador.nombre}
          </CommentName>
        </CommentInfo>
      </CommentInfoHeader>
      <CommentInfoContainer>
        <CommentBD>
            {props.comment.comentarios}
          </CommentBD>
      </CommentInfoContainer>
      <ExtraInfoRow>
        <Pills>{props.comment.nombre} </Pills>
        <CommentStatus>
          {props.comment.status.codigoInterno} &nbsp; <CommentDate>{` - ${moment(props.comment.fechaAutorizacion).format('YYYY-MM-DD')}`}</CommentDate>
        </CommentStatus>
      </ExtraInfoRow>
    </CommentContainer>
  {/* </ParentButton> */}
  </Container>
)

CommentsCard.propTypes = {
}

CommentsCard.defaultProps = {
}

export default CommentsCard
