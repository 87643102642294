import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import ResetPasswordActions, { ResetPasswordRedux, ResetPasswordTypes } from '../Redux/ResetPasswordRedux'

const ResetPasswordObservable = createCRUDObservable({
  mainRedux: ResetPasswordRedux,
  reduxPath: 'resetPassword',
  dataHandlers: {
    get: response => response,
  },
})

const resetPassword = (action$, store, { Api }) =>
  action$.ofType(ResetPasswordTypes.resetPasswordRequest)
    .mergeMap(({ email }) => (
      Observable
        .fromPromise(Api.auth.resetPassword(email)
          .then(response => response)
          .catch(error => error))
        .flatMap((response) => {
          const { status } = response

          if (!(status >= 200 && status < 300)) {
            return [ResetPasswordActions.resetPasswordError(response)]
          }

          return [
            ResetPasswordActions.resetPasswordSuccess(response.data)]
        })
    ))

const setNewPassword = (action$, store, { Api }) =>
  action$.ofType(ResetPasswordTypes.resetRequest)
    .mergeMap(({ token, data }) => (
      Observable
        .fromPromise(Api.auth.setNewPassword(token, data)
          .then(response => response)
          .catch(error => error))
        .flatMap((response) => {
          const { status } = response

          if (!(status >= 200 && status < 300)) {
            return [ResetPasswordActions.resetError(response)]
          }

          return [
            ResetPasswordActions.resetSuccess(response)]
        })
    ))

export const ResetPasswordObservers = Object.assign({}, ResetPasswordObservable.observers, {})

export const resetPasswordEpic = combineEpics(
  ResetPasswordObservable.epic,
  resetPassword,
  setNewPassword,
)
