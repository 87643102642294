import { createActions, createState, createReducer } from 'reduxsauce-crud'
import R, { clone, findIndex, propEq } from 'ramda'

const d = { deep: true }

export const FlowAuthorizationStagesRedux = createActions(
  {
    FlowAuthorizationStagesRequest: ['data'],
    FlowAuthorizationStagesSuccess: ['data'],
    FlowAuthorizationStagesError: ['err'],
    updateFlowAuthorizationStagesRequest: ['data'],
    updateFlowAuthorizationStagesSuccess: ['data'],
    updateFlowAuthorizationStagesError: ['erro'],
    postAutorizationFlowRequest: ['data'],
    postAutorizationFlowSuccess: ['data'],
    postAutorizationFlowError: ['erro'],
    postStageFlowAuthorizationRequest: ['data'],
    postStageFlowAuthorizationSuccess: ['data'],
    postStageFlowAuthorizationError: ['erro'],
    // updateExpense: ['data']
  },
  {
    prefix: 'FLOW_AUTHORIZATION_STAGES_',
    defaultActions: {
      get: true,
      update: true,
    },
  },
)

const { Types, Creators } = FlowAuthorizationStagesRedux
export const FlowAuthorizationStagesTypes = Types
export default Creators

export const INITIAL_STATE = createState({}, {
  get: true,
  update: true,

})

const FlowAuthorizationStagesRequest = state =>
  state.merge({ get: { fetching: true, error: null } }, d)

const FlowAuthorizationStagesError = (state, { err }) =>
  state.merge({ get: { fetching: false, error: err } }, d)

const FlowAuthorizationStagesSuccess = (state, data) => {
  return state.merge({ get: { fetching: false, results: data.data } }, d)
}
const updateFlowAuthorizationStagesRequest = state =>
  state.merge({ upgrade: {fetching: true, error: null } }, d)

const updateFlowAuthorizationStagesError = (state, { err }) =>
  state.merge({ upgrade: { fetching: false, error: err } }, d)

const updateFlowAuthorizationStagesSuccess = (state, data) => 
  state.merge({ upgrade: { fetching: false} }, d)

const postAutorizationFlowRequest = state =>
  state.merge({ upgrade: {fetching: true, error: null } }, d)

const postAutorizationFlowError = (state, { err }) =>
  state.merge({ upgrade: { fetching: false, error: err } }, d)

const postAutorizationFlowSuccess = (state, { data }) =>{
  const results = clone(state.get.results)
  const index = findIndex(propEq('id',data.id))(results)

  if (index > -1 ){
    results[index] = data
    

  }
  
  return state.merge({ upgrade: { fetching: false} , get:{results} }, d)
} 

const postStageFlowAuthorizationRequest = state =>
  state.merge({ upgrade: {fetching: true, error: null } }, d)

const postStageFlowAuthorizationError = (state, { err }) =>
  state.merge({ upgrade: { fetching: false, error: err } }, d)

const postStageFlowAuthorizationSuccess = (state, { data }) =>{
  const results = clone(state.get.results)
  const index = findIndex(propEq('id',data.id))(results)

  if (index > -1 ){
    results[index] = data
    

  }
  
  return state.merge({ upgrade: { fetching: false} , get:{results} }, d)
} 


// const updateExpense = (state, { data }) => {
//   const newSolicitud = R.clone(state.get.results.data)
//   const activeExpenseIndex = R.findIndex(R.propEq('id', data.data.id))(newSolicitud)
//   newSolicitud[activeExpenseIndex] = data.data
//   return state.merge({ get: { fetching: false, results: { data: newSolicitud } } }, d)
// }


export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.FlowAuthorizationStagesRequest]: FlowAuthorizationStagesRequest,
    [Types.FlowAuthorizationStagesSuccess]: FlowAuthorizationStagesSuccess,
    [Types.FlowAuthorizationStagesError]: FlowAuthorizationStagesError,
    [Types.updateFlowAuthorizationStagesRequest]: updateFlowAuthorizationStagesRequest,
    [Types.updateFlowAuthorizationStagesSuccess]: updateFlowAuthorizationStagesSuccess,
    [Types.updateFlowAuthorizationStagesError]: updateFlowAuthorizationStagesError,
    [Types.postAutorizationFlowRequest]: postAutorizationFlowRequest,
    [Types.postAutorizationFlowSuccess]: postAutorizationFlowSuccess,
    [Types.postAutorizationFlowError]: postAutorizationFlowError,
    [Types.postStageFlowAuthorizationRequest]: postStageFlowAuthorizationRequest,
    [Types.postStageFlowAuthorizationSuccess]: postStageFlowAuthorizationSuccess,
    [Types.postStageFlowAuthorizationError]: postStageFlowAuthorizationError,
    // [Types.updateExpense]: updateExpense,
  },
  {
    defaultActions: {
      get: true,
      update: true,
    },
    Types,
  },
)
