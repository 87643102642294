import { gql } from '@apollo/client';

const getAllRegions = gql`
query
  allCatRegiones{
    allCatRegiones(filters:{not:{status:0}}){
      edges{
        node{
          id
          nombre
          codigoInterno
          status
          deleted
          ou{
            id
            nombre
            codigoInterno
          }
        }
      }
    }
  }
`
export{
  getAllRegions
}
