import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon } from 'advenio-components'
import { isEmpty } from 'ramda'
import { STATUS } from '../../../Constants'
import { PanelHeader } from '../../ProfilePanel/ProfilePanel.styles'
import { stringContainsAnyOf } from '../../../utils/Utils'
import moment from 'moment'
import { notificationFail, notificationSuccess } from '../../../Data/Redux/NotificationActions'
import AuthorizationStatusActions from '../../../Data/Redux/AuthorizationStatusRedux'
import SolicitudActions from '../../../Data/Redux/SolicitudRedux'
import UserActions from '../../../Data/Redux/UserRedux'
import LoadingSpinner from '../../../Components/LoadingSpinner'


const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 120px !important;
  padding-right: 120px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
`
const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin-top: 4%;
`
const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Checkbox = ({ label, name, value }) => (
  <OriginalCheckbox
    invertedColors
    large
    hideEmptyHelpBlock
    label={label}
    name={name}
    value={value}
  />
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}

class AssignRecruiter extends Component {
  state={
    flawSend:false,
    dataSend:{}
  }

  componentWillMount() {
    this.props.getAllUserByRole({
      'rolUsuario':'RECLUTADOR-STAFF'
    })
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const {
      validSolicitud,
      messageFail,
      updatedSolicitud,
      user,
      setSolicitudStageData,
      getNextStatus,
    } = nextProps
    const { flawSend, dataSend } = this.state

    if(flawSend && ! isEmpty(dataSend) && !isEmpty(validSolicitud)){
      if(validSolicitud.status.codigoInterno === STATUS.authorized || validSolicitud.status.codigoInterno === STATUS.rejected){
        messageFail('No se puede cambiar el estatus de una etapa previamente autorizada/rechazada')
      }else{
        dataSend.comentarios = 'Se asigna reclutador'
        dataSend.statusId = "U3RhdHVzQ2F0YWxvZ09iamVjdDo5"// FIX: this get from catalogue state Redux
        let dataNextStatus = {
          'id':dataSend.statusId
        }
        setSolicitudStageData(dataSend)
        getNextStatus(dataNextStatus)
      }
    }
  }

  onSubmit= (data) =>{
    const {  match:{ params: { solicitudStageId }}, validateSolitictud, info } = this.props
    const dataSend = {
      'id':solicitudStageId,
      'usuarioId': data.reclutador
    }
    this.setState({dataSend, flawSend:true})
    if(data.reclutador !== 0){
      validateSolitictud({
        'id':solicitudStageId
      })
    }
  }

  renderOptions = () => [{id:0,label:"Seleccionar",value:0}].concat(this.props.user_recruiter.map(s=>({
    id:s.id
    , label:s.nombre
    , value: s.id
  })))

  render() {
    const {
      info,
      solicitudStage,
      loading
    } = this.props

    if(loading || this.props.user_recruiter.length === 0) {
      return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
      )
    }

    return (
      <Container>
        <PanelHeader tabTitle >Captura de Datos</PanelHeader>
        <Form
          ref={(form) => { this.form = form }}
          onSubmit={this.onSubmit}
        >
          <Grid>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <Select
                  name="reclutador"
                  label="Reclutador"
                  required
                  value={ info.assigned ? info.assigned.id : null}
                  options={this.renderOptions()}
                //  disabled={!! info.assigned}
                  fullWidth
                />
              </Col>
            </Row>
            <Row end="xs">
              <Col sm={12} md={6}>
                <Button primary disabled={!! stringContainsAnyOf(info.status.codigoInterno,[STATUS.authorized, STATUS.assigned_recruiter ]) } style={{marginBottom:12}} loading={false} type="submit" >
                  {/* <TextIcon label="Subir archivos" iconType="plus" iconViewBox="-2 -2 24 24" /> */}
                  Guardar
                </Button>
              </Col>
            </Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

AssignRecruiter.propTypes = {
}

AssignRecruiter.defaultProps = {

}

const mapStateToProps = state => ({
  loading: state.user.get.fetching,
  user: state.auth.user,
  user_recruiter: state.user.get.results,
  status: state.authorizationStatus.get.results,
  validSolicitud: state.solicitud.validSolicitud.result,
})

const mapDispatchToProps = dispatch => ({
  validateSolitictud: (data) => dispatch(SolicitudActions.validateSolicitudRequest(data)),
  updatedSolicitud: (data) => dispatch(SolicitudActions.updateSolicitudRequest(data)),
  getAllUserByRole: (data) => dispatch(UserActions.userByRoleRequest(data)),
  getNextStatus: (data) => dispatch(AuthorizationStatusActions.getNextStatusRequest(data)),
  messageFail: msg => dispatch(notificationFail(msg)),
  setSolicitudStageData: data => dispatch(SolicitudActions.setSolicitudStageData(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignRecruiter))
