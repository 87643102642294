import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { times } from 'ramda'
import { Form } from 'formsy-react-2'
import { Colors, Icon, Button as OriginalButton, Title2, TextIcon } from 'advenio-components'

export const ModalContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

const StyledHeader = styled.div`
  background-color: rgba(226, 232, 236, 0.7);
  color: ${Colors.advenioGray700};
  position: relative;
  display: flex;
  justify-content: space-around;
  padding:  21px;
  align-items: center;
  font-size: 21px;
`

export const Header = ({ title, onDismiss }) => (
  <StyledHeader>
    <div />
    <div>{title}</div>
    <OriginalButton transparent noShadow noMargin noPadding onClick={onDismiss}>
      <Icon type="close" />
    </OriginalButton>
  </StyledHeader>
)

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

export const Body = styled.div`
  overflow: auto;
  height: 100%;
`

export const BodyContainer = styled.div`
  height: 100%;
`

export const Footer = styled.div`
  padding: 15px 0;
`

export const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ hidden }) => hidden ? 'display: none' : ''};
`

export const ToHide = styled.div`
  width: 100%;
  height: 100%;
  ${({ hidden }) => hidden ? 'display: none' : ''};
`

export const ModalStructure = ({ headerTitle, onDismiss, children,
  footer, activeView, numberDots, noDots, changeActiveView, onSubmit, onChange }) => (
    <ModalContainer
      ref={(el) => { this.form = el }}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      <Header title={headerTitle} onDismiss={onDismiss} />
      <Body>
        {children}
      </Body>
      <Footer>
        {footer}
        {!noDots && (
          <DotsContainer>
            {times(index => (
              <Dot
                key={index}
                active={activeView === index + 1}
                onClick={changeActiveView(index + 1)}
              />
            ), numberDots)}
          </DotsContainer>
        )}
      </Footer>
    </ModalContainer>
)

ModalStructure.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  activeView: PropTypes.number,
  numberDots: PropTypes.number,
  noDots: PropTypes.bool,
  changeActiveView: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
}

ModalStructure.defaultProps = {
  activeView: '-1',
  numberDots: 0,
  noDots: false,
  changeActiveView: () => null,
  onSubmit: () => null,
  onChange: () => null,
}

export const LightText = styled.p`
  opacity: 0.6;
  font-size: 16px;
  color: ${Colors.advenioGray700};
  text-align: center;
  margin: 10px 0;
`

export const Avatar = styled.div`
  ${({ height, width, marginTop, marginBottom, marginRight, color, fontSize }) =>
    `
    height: ${height || '20px'};
    width: ${width || '20px'};
    margin-top: ${marginTop};
    margin-bottom: ${marginBottom};
    margin-right: ${marginRight};
    background-color: ${color || '#000000'};
    font-size: ${fontSize};
    flex-shrink: 0;
    `
}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
`

export const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
`

export const Title = styled.div`
  font-size: 21px;
  color: ${Colors.advenioGray700};
  font-weight: 700;
  text-align: center;
`

export const Button = styled(OriginalButton)`
  display: flex;
  align-items: center;

  svg {
    ${({ left }) => left ? 'margin-right: 15px' : ''};
    ${({ right }) => right ? 'margin-left: 15px' : ''};
  }
`

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`

export const Dot = styled.div`
  background: ${({ active }) => active ? Colors.advenioBlue500 : 'white'};
  border: 1px solid ${Colors.advenioBlue500};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
`

export const NextButton = ({ onClickNext, disabled }) => (
  <Button primary onClick={onClickNext} disabled={disabled}>
    <TextIcon label="Siguiente" iconType="arrowRight" RTL />
  </Button>
)

NextButton.propTypes = {
  onClickNext: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const SuccessContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SuccessMessage = () => (
  <SuccessContainer>
    <Icon type="plus" />
    <Title2>Datos actualizados</Title2>
  </SuccessContainer>
)
