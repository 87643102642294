import styled from 'styled-components'
import { Card, Colors } from 'advenio-components'

export const CloudBar = styled.div`
  display: ${props => props.isCloud ? 'flex' : 'none'};
  background-color: #f0f9fe;
  color:#4A4A4A;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: 12px;
`

export const CloudIcon = styled.img`
  margin: 0 4px 0 4px;
`


export const PanelContainer = styled(Card)`
  width:200px;
  padding-bottom: 2vh;
  border-radius: 3px;
  min-height: 65vh;
  @media (min-width: 1024px) {
    width: auto;
  }
  @media (min-width: 1440px) {
    width: auto;
  }
`
export const PanelHeader = styled.div`
  color: #9B9B9B;
  margin: 4px;
  text-align: left;
  font-weight: bold;
  ${({ tabTitle })=> tabTitle &&`
    color: black;
    font-size: 2rem;
  `}
  display:inline-block;
`

export const PanelSection = styled.div`
  display: flex;
  min-height: 10vh;
  justify-content: flex-start;
  align-items: center;
  padding: 2vh 5% 2vh 5%;
  border-bottom: ${props => props.border ? 'solid 1px' : ''};
  border-bottom-color: #CCCCCC;
  border-top: ${props => props.top ? 'solid 1px' : ''};
  border-top-color: #CCCCCC;
  flex-direction: column;
`
export const ProfilePicture = styled.div`
  background-image: ${props => props.profile ? 'url(' + props.profile + ')' : 'url(/assets/BabyAvatar.svg)'};
  background-size: cover;
  background-position: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid gray;
  @media (max-width: 763px) {
    width: 50px;
    height: 50px;
  }
`

export const ProfilePictureContent = styled.div`
  display: flex;
  justify-content: center;
`

export const PillsRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`

export const Pills = styled.div`
  background-color: #f3f8fb;
  color:#46688F;
  font-size: 14px;
  margin: 4px 4px 4px 4px;
  padding: .2em 1em .2em 1em;
  border-radius: 10px;
  word-wrap: none;
  @media (max-width: 763px) {
    margin: 4px 4px 4px 0;
    font-size: 10px;
  }
`
export const InventorySection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
`

export const InventoryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 26px 0 0 0;
`

export const DivWithIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex:1;
`
export const StyledSpan = styled.span`
  font-size: 12px;
  font-weight: bold;
  ${({ right })=> right && `
    font-weight: normal;
    color: ${Colors.gray700}
  `}
`

export const DesktopContainer = styled.div`
  display: none;
  @media (min-width:768px) {
    display: block;
  }
`
export const MobileContainer = styled.div`
  display: none;
  @media (max-width:767px) {
    display: block;
  }
`
export const Container = styled.div`
  width: 20%;
  margin-left: 10px;

  @media (max-width:767px) {
    width: 100%;
  }
`
