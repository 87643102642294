import { gql } from '@apollo/client';

const getAllJobs = gql `
query
allCatPuestos{
  allCatPuestos(filters:{not:{status:0}}){
      edges{
        node{
          id
          nombre
          status
          deleted
          ouId
          ou {
            id
            nombre
          }
        }
      }
    }
  }
`
export {
    getAllJobs
}