import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { gql } from '@apollo/client';
import R,{isEmpty, clone} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import StageAuthorizeActions, { StageAuthorizeRedux, StageAuthorizeTypes } from '../Redux/StageAuthorizeRedux'
import { AUTHORIZATION } from '../GraphQL'
import moment from 'moment'
import { parseDataGraphQL, paramsGraphQL, paramsGraphQLPagination } from '../../utils/Utils'

const stageAuthorizeCrudObservable = createCRUDObservable({
  mainRedux: StageAuthorizeRedux,
  reduxPath: 'stageAuthorize',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})

const getStageAuthorizeEpic = (action$, store, { Api }) => (
  action$.ofType(StageAuthorizeTypes.stageAuthorizeRequest)
    .mergeMap(({data}) => {
      let getStagesByAuthorize=''
      let { params, binding } = paramsGraphQL(AUTHORIZATION.stagesByAuthorizeAttr, data)
      const createQuery = AUTHORIZATION.getStagesByAuthorize.replace('$params',params).replace('$binding',binding)
      getStagesByAuthorize = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query({
            query: getStagesByAuthorize
            ,variables: data
            ,fetchPolicy: 'no-cache'
          })
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              StageAuthorizeActions.stageAuthorizeError(response['message'])
            ]
          }

          const { allSolicitudEtapas:{ edges },allSolicitudEtapas } = response.data

          // if (allStageAuthorizees.status !== '200') {
          //   

          //   return [
          //     notificationFail(allStageAuthorizees.message),
          //     StageAuthorizeActions.StageAuthorizeError(allStageAuthorizees)
          //   ]
          // }
          if(isEmpty(edges)){
            const stateStageAuthorize =clone(store.getState().stageAuthorize.get.results)

            return [
              // notificationFail('No se encontraron resultados!.'),
              StageAuthorizeActions.stageAuthorizeSuccess(stateStageAuthorize),
            ]
          }
          let data = parseDataGraphQL(edges)
          // data = {
          //   'pageInfo':allSolicitudEtapas['pageInfo'],
          //   'data':[...data]
          // }

          return [
            // AuthActions.setAuthorized(true),
            StageAuthorizeActions.stageAuthorizeSuccess(data),
          ]
        })
    })
)
const getStepsByAutorizadorDirectoEpic = (action$, store, { Api }) => (
  action$.ofType(StageAuthorizeTypes.stepsByAutorizadorDirectoRequest)
    .mergeMap(({data}) => {
      let getStepsByAutorizadorDirecto=''
      let { params, binding } = paramsGraphQL(AUTHORIZATION.stagesByAuthorizeAttr, data)
      const createQuery = AUTHORIZATION.getStagesByAuthorize.replace('$params',params).replace('$binding',binding)
      getStepsByAutorizadorDirecto = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query({
            query: getStepsByAutorizadorDirecto
            ,variables: data
            ,fetchPolicy: 'no-cache'
          })
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              StageAuthorizeActions.stepsByAutorizadorDirectoError(response['message'])
            ]
          }

          const { allSolicitudEtapas:{ edges },allSolicitudEtapas } = response.data

          // if (allStageAuthorizees.status !== '200') {
          //   

          //   return [
          //     notificationFail(allStageAuthorizees.message),
          //     StageAuthorizeActions.StageAuthorizeError(allStageAuthorizees)
          //   ]
          // }
          if(isEmpty(edges)){
            const stateStageAuthorize =clone(store.getState().stageAuthorize.get.results)

            return [
              // notificationFail('No se encontraron resultados!.'),
              StageAuthorizeActions.stepsByAutorizadorDirectoSuccess(stateStageAuthorize),
            ]
          }
          let data = parseDataGraphQL(edges)

          return [
            // AuthActions.setAuthorized(true),
            StageAuthorizeActions.stepsByAutorizadorDirectoSuccess(data),
          ]
        })
    })
)
const getStepsByDirectoBossEpic = (action$, store, { Api }) => (
  action$.ofType(StageAuthorizeTypes.stepsByDirectBossRequest)
    .mergeMap(({data}) => {
      let getStepsByDirectBossRequest=''
      let { params, binding } = paramsGraphQL(AUTHORIZATION.stagesByAuthorizeAttr, data)
      const createQuery = AUTHORIZATION.getStagesByAuthorize.replace('$params',params).replace('$binding',binding)
      getStepsByDirectBossRequest = gql`${createQuery}`
      return Observable
        .fromPromise(
          Api.query({
            query: getStepsByDirectBossRequest
            ,variables: data
            ,fetchPolicy: 'no-cache'
          })
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              StageAuthorizeActions.stepsByDirectBossError(response['message'])
            ]
          }

          const { allSolicitudEtapas:{ edges },allSolicitudEtapas } = response.data

          // if (allStageAuthorizees.status !== '200') {
          //   

          //   return [
          //     notificationFail(allStageAuthorizees.message),
          //     StageAuthorizeActions.StageAuthorizeError(allStageAuthorizees)
          //   ]
          // }
          if(isEmpty(edges)){
            const stateStageAuthorize =clone(store.getState().stageAuthorize.get.results)

            return [
              // notificationFail('No se encontraron resultados!.'),
              StageAuthorizeActions.stepsByDirectBossSuccess(stateStageAuthorize),
            ]
          }

          let data = parseDataGraphQL(edges)

          return [
            // AuthActions.setAuthorized(true),
            StageAuthorizeActions.stepsByDirectBossSuccess(data),
          ]
        })
    })
)

export const stageAuthorizeObservers = Object.assign({}, stageAuthorizeCrudObservable.observers, {})

export const stageAuthorizeEpic = combineEpics(
  stageAuthorizeCrudObservable.epic,
  getStageAuthorizeEpic,
  getStepsByAutorizadorDirectoEpic,
  getStepsByDirectoBossEpic,
)
