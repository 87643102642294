import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import R,{isEmpty} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import SolicitudTypesActions, { SolicitudTypesRedux, SolicitudTypesTypes } from '../Redux/SolicitudTypesRedux'
import { SOLICITUD } from '../GraphQL'
import moment from 'moment'
import { parseDataGraphQL } from '../../utils/Utils'

const solicitudTypesCrudObservable = createCRUDObservable({
  mainRedux: SolicitudTypesRedux,
  reduxPath: 'solicitudTypes',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})

const getSolicitudTypesEpic = (action$, store, { Api }) => (
  action$.ofType(SolicitudTypesTypes.SolicitudTypesRequest)
    .mergeMap(() => (
      Observable
        .fromPromise(
          Api.query({query: SOLICITUD.getAllSolicitudTypes,})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              SolicitudTypesActions.SolicitudTypesError(response['message'])
            ]
          }

          const { allSolicitudTipos:{ edges } } = response.data

          // if (allSolicitudTypeses.status !== '200') {
          //   

          //   return [
          //     notificationFail(allSolicitudTypeses.message),
          //     SolicitudTypesActions.SolicitudTypesError(allSolicitudTypeses)
          //   ]
          // }
          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              SolicitudTypesActions.SolicitudTypesSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            // AuthActions.setAuthorized(true),
            SolicitudTypesActions.SolicitudTypesSuccess(data),
          ]
        })
    ))
)

export const solicitudTypesObservers = Object.assign({}, solicitudTypesCrudObservable.observers, {})

export const solicitudTypesEpic = combineEpics(
  solicitudTypesCrudObservable.epic,
  getSolicitudTypesEpic,
)
