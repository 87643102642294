import { createActions, createState, createReducer } from 'reduxsauce-crud'

const d = { deep: true }

export const ResetPasswordRedux = createActions(
  {
    resetPasswordRequest: ['email'],
    resetPasswordSuccess: ['data'],
    resetPasswordError: ['error'],
    resetRequest: ['token', 'data'],
    resetSuccess: ['data'],
    setSuccessFalse: [''],
    resetError: ['error'],
  },
  {
    prefix: 'PASSWORD_RESET_',
    defaultActions: {
      get: true,
    },
  },
)

const { Types, Creators } = ResetPasswordRedux
export const ResetPasswordTypes = Types
export default Creators

export const INITIAL_STATE = createState({
  sending: {
    fetching: false,
    error: null,
  },
  error: '',
  success: false,
}, {
  get: true,
})

/* ------------- Reducers ------------- */

const resetPasswordRequest = state => state.merge({ sending: { fetching: true, error: null } }, d)

const resetPasswordSuccess = state =>
  state.merge({ sending: { fetching: false, error: null }, success: true, error: '' }, d)

const resetPasswordError = (state, { error }) =>
  state.merge({ sending: { fetching: false, error }, error: error.error }, d)

const resetRequest = state => state.merge({ sending: { fetching: true, error: null } }, d)

const resetSuccess = state =>
  state.merge({ sending: { fetching: false, error: null }, success: true, error: '' }, d)

const resetError = (state, { error }) =>
  state.merge({ sending: { fetching: false, error }, error: error.error }, d)

const setSuccessFalse = state => state.merge({ success: false }, d)

export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.resetPasswordRequest]: resetPasswordRequest,
    [Types.resetPasswordSuccess]: resetPasswordSuccess,
    [Types.resetPasswordError]: resetPasswordError,
    [Types.resetRequest]: resetRequest,
    [Types.resetSuccess]: resetSuccess,
    [Types.resetError]: resetError,
    [Types.setSuccessFalse]: setSuccessFalse,
  },
  {
    defaultActions: {
      get: true,
    },
    Types,
  },
)
