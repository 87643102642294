import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { equals, clone, isEmpty } from 'ramda'
import { connect } from 'react-redux'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, Colors } from 'advenio-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Form } from 'formsy-react'
import { OUID, SOLICITUDTYPEID } from '../../Constants'
import LoadingSpinner from '../../shared/LoadingSpinner'
import { parseDataGraphQL } from '../../utils/Utils'
import { AWS_BUCKET_URL } from '../../config'
import { FormContainer } from '../Solicitud/SolicitudForm'

export const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 0 !important;
  padding-right: 0 !important;
`
export const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
`
export const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const SubTitle = styled.div`
  justify-content: center;
  display: flex;
  margin-top: -20px;
  color: ${Colors.gray700};
  margin-bottom: 30px;
  font-size: 24px;
`

const ContainerCheckBox = styled.div`
  display: flex;
  justify-content: center;
`
const ButtonContainer = styled.div`
  display: inline-block;
  float: right;
  margin-right: 15px;
`

const Checkbox = ({ label, name, value,...props }) => (
  <ContainerCheckBox>
    <OriginalCheckbox
      inputAlign
      invertedColors
      large
      hideEmptyHelpBlock
      label={label}
      name={name}
      value={value}
      {...props}
    />
  </ContainerCheckBox>
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}



class FlowStageDetail extends Component {
  state = {
  }


  componentWillMount() {
  }

  componentDidMount = () => {
  }

  render() {
    const { stage, editStage, departamentRhOptions,usersOptions} = this.props

    return (
      <FormContainer>
        <Form
           ref={(form) => { this.form = form }}
           onValidSubmit={editStage}
        >
        <Grid>
          <Row>
            <Col sm={12} md={12} lg={12}>
                <SubTitle>{stage ? stage.nombre : null}</SubTitle>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={2}></Col>
            <Col sm={12} md={4}>
              <Select
                name="departamentoRhId"
                label="Departamento"
                required
                value={stage ? stage.departamento.id : null}
                options={departamentRhOptions}
                // disabled={ ! isEmpty(info.tipoSolicitud)}
                // emptyElement
                //hideEmptyHelpBlock
                // onChangeCallback={(e)=> {this.changeSolicitudTypes(e)}}
                //fullWidth
              />
            </Col>  
            <Col sm={12} md={4}>
              <Select
                name="usuarioId"
                label="Autorizador"
                required
                value={stage ? stage.autorizador.id : null}
                options={usersOptions}
                // disabled={ ! isEmpty(info.tipoSolicitud)}
                //emptyElement
                //hideEmptyHelpBlock
                // onChangeCallback={(e)=> {this.changeSolicitudTypes(e)}}
                //fullWidth
              />
            </Col>  
            <Col sm={12} md={2}></Col>
          </Row>
          <Row>
            <Col sm={12} md={2}></Col>
            <Col sm={12} md={4}>
              <Checkbox
                name="status"
                label="Habilitado"
                value={stage ? Boolean(stage.status) : null}
              />
            </Col>
            <Col sm={12} md={4}>

              {/* <Checkbox
                name="deleted"
                label="Eliminado"
                value={stage ? Boolean(stage.deleted) : null}
                disabled
              /> */}
            </Col>
            <Col sm={12} md={2}></Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
            <ButtonContainer>
              <Button primary type="submit">
                {/* <Icon type={'plus'} width={14} height={14} color={Colors.white300} /> */}
                Guardar
              </Button>
            </ButtonContainer>
            </Col>
          </Row>
        </Grid>
        </Form>
     </FormContainer>
    )
  }
}

FlowStageDetail.propTypes = {
}

FlowStageDetail.defaultProps = {
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FlowStageDetail)

