import React from 'react'
import { Provider } from 'react-redux'
import createHistory from 'history/createBrowserHistory'
import { ConnectedRouter, routerMiddleware } from 'react-router-redux'
import { NotificationsManager } from 'advenio-components'
import { setStore } from '../utils/Utils'
import AuthContainer from './AuthContainer'
import './Styles/App.css'

import createStore from '../Data/Redux'

// Create a browser history
const history = createHistory()

// Build the middleware for intercepting and dispatching navigation actions
const historyMiddleware = routerMiddleware(history)

// Create our store, with middlewares
export const store = createStore([historyMiddleware])
setStore(store)

const App = () => (
  <Provider store={store}>
    { /* ConnectedRouter will use the store from Provider automatically */ }
    <div>
      <ConnectedRouter history={history}>
        <AuthContainer />
      </ConnectedRouter>
      <NotificationsManager timeout={300} />
    </div>
  </Provider>
)

if (window.Cypress) {
  window.store = store
}

export default App
