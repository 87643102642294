import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import R,{isEmpty} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import OperativeUnitActions, { OperativeUnitRedux, OperativeUnitTypes } from '../Redux/OperativeUnitRedux'
import { OU } from '../GraphQL'
import moment from 'moment'
import { parseDataGraphQL } from '../../utils/Utils'

const operativeUnitCrudObservable = createCRUDObservable({
  mainRedux: OperativeUnitRedux,
  reduxPath: 'operativeUnit',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})

const getOperativeUnitEpic = (action$, store, { Api }) => (
  action$.ofType(OperativeUnitTypes.OperativeUnitRequest)
    .mergeMap(() => (
      Observable
        .fromPromise(
          Api.query({query: OU.getAllOU,})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              OperativeUnitActions.OperativeUnitError(response['message'])
            ]
          }

          const { allCatOus:{ edges } } = response.data

          // if (allOperativeUnites.status !== '200') {
          //   

          //   return [
          //     notificationFail(allOperativeUnites.message),
          //     OperativeUnitActions.OperativeUnitError(allOperativeUnites)
          //   ]
          // }
          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              OperativeUnitActions.OperativeUnitSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            // AuthActions.setAuthorized(true),
            OperativeUnitActions.OperativeUnitSuccess(data),
          ]
        })
    ))
)

export const operativeUnitObservers = Object.assign({}, operativeUnitCrudObservable.observers, {})

export const operativeUnitEpic = combineEpics(
  operativeUnitCrudObservable.epic,
  getOperativeUnitEpic,
)
