import React from 'react'
import styled from 'styled-components'
import { Button } from 'advenio-components'
import PropTypes from 'prop-types'

const SAVE_LABEL = 'Guardar'

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Header = styled.h2`
  margin: 16px;
  @media (max-width:1200px) {
    font-size: 20px;
    margin: 16px 8px 16px 16px;
  }
`

const SaveButton = styled(Button)`
  height: 42px;
  margin-right: 16px;
  font-size: 13px;
  padding: 10px 16px 10px 16px;
  display: ${props => props.noSave ? 'none' : 'block'};
  @media (max-width:1300px) {
    height: 32px;
    margin-right: 8px;
    font-size: 12px;
    width: 60px;
    padding: 4px 8px 4px 8px;
  }
`
const ButtonsContainer = styled.div`
  display: flex;
`
const ProfileHeader = ({ header, onSave, submit, noSave, disabled, loading, buttons }) => (
  <HeaderContainer>
    <Header>
      {header}
    </Header>
    <ButtonsContainer>
      <SaveButton
        primary
        onClick={onSave}
        type={submit ? 'submit' : 'button'}
        noSave={noSave}
        disabled={disabled}
        loading={loading}
      >
        {SAVE_LABEL}
      </SaveButton>
      {buttons}
    </ButtonsContainer>
  </HeaderContainer>
)

ProfileHeader.propTypes = {
  header: PropTypes.string,
  onSave: PropTypes.func,
  buttons: PropTypes.array,
  noSave: PropTypes.bool,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

ProfileHeader.defaultProps = {
  header: '',
  onSave: () => {},
  buttons: [],
  noSave: false,
  submit: false,
  disabled: false,
  loading: false,
}

export default ProfileHeader
