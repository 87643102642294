import React from 'react'
import styled from 'styled-components'
import { path } from 'ramda'
import { Colors } from 'advenio-components'

const StatusBarColor = {
  "EN-AUTORIZACION": '#DDBE16',
  "EN-VALIDACION": '#DDBE16',
  RECHAZADO: '#D0021B',
  AUTORIZADO: Colors.green500,
  "INFO-COMPLETA": Colors.green500,
  "RECLUTADOR-ASIGNADO": Colors.green500,
}


const StatusIconPath = {
  "EN-AUTORIZACION": '/assets/clock_icon.svg',
  AUTORIZADO: '/assets/checkRoom.svg',
  "RECLUTADOR-ASIGNADO": '/assets/checkRoom.svg',
  "INFO-COMPLETA": '/assets/checkRoom.svg',
  "EN-VALIDACION":'/assets/clock_icon.svg',
  RECHAZADO:'/assets/closeAssessment.svg'
}

const StatusBarTextColor = {
  "EN-AUTORIZACION": 'white',
  AUTORIZADO: 'white',
  AUTORIZADO: 'white',
  "INFO-COMPLETA": 'white',
  "EN-VALIDACION": 'white',
  RECHAZADO: 'white',
}

const Bar = styled.div`
  display: ${props => props.status === 'checkIn' ? 'none' : 'flex'};
  background-color: ${props => StatusBarColor[props.status]};
  color:${props => 'white'/*StatusBarTextColor[props.status]*/};
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0 8px 0;
  font-size: 12px;
`

const StatusIcon = styled.img`
  margin: 0 4px 0 4px;
`
const StatusBar = (props) => {
  const { status } = props
  return (
      <Bar status={status.codigoInterno}>
        <StatusIcon src={StatusIconPath[status.codigoInterno]} />
        <i>{status.nombre}</i>
      </Bar>
    )
}

export default StatusBar
