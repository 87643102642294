import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'advenio-components'
import { Route, Redirect, Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'
import {  Actions as AuthActions } from '../Data/Redux/AuthRedux'

import Login from './Auth/Login'
import ResetPassword from './Auth/ResetPassword'
import SetNewPassword from './Auth/SetNewPassword'
import AppContainer from './AppContainer'
import LoadingSpinner from '../Components/LoadingSpinner'

class AuthContainer extends Component {
  componentWillMount() {
    const {
      checkAuthStatus,
      location,
      logOut,
    } = this.props

    if (location.pathname === '/logout') {
      logOut()
    }
    checkAuthStatus()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }

  getRoute = (authenticated, authorized) => {

    if (authenticated && authorized) {

      return <Route path="/" component={AppContainer} />

    } else if (authenticated === false) {

      return (
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/recover-password" component={ResetPassword} />
          <Route exact path="/reset/:token" component={SetNewPassword} />
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      )
    }

    return <LoadingSpinner />
  }

  render() {
    const { loading } = this.props

    return (
      <div>
        <Switch>
          {this.getRoute(this.props.authenticated, this.props.authorized)}
        </Switch>
        {loading &&
          <Modal isOpened={loading} loading={loading} />
        }
      </div>
    )
  }
}

AuthContainer.propTypes = {
  /** Function to check the user's current authentication status */
  checkAuthStatus: PropTypes.func.isRequired,
  /** Flag marking whether the user is currently authenticated */
  authenticated: PropTypes.bool,
  /** Flag marking whether the user is authorized to access this platform */
  authorized: PropTypes.bool,
  /** Loading flag */
  loading: PropTypes.bool,
  /** Log out of the platform */
  // logOut: PropTypes.func.isRequired,
}

AuthContainer.defaultProps = {
  authenticated: null,
  authorized: null,
  loading: false,
}

const mapStateToProps = ({ auth }) => ({
  authenticated: auth.authenticated,
  authorized: auth.authorized,
  loading: auth.fetching,
})

const mapDispatchToProps = dispatch => ({
  checkAuthStatus: () => dispatch(AuthActions.checkAuthStatus()),
  logOut: () => dispatch(AuthActions.logout({ shouldDeleteCookies: true })),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer))
