import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import queryString from 'query-string'
import { Table, TableCell, TableHeader, TableLoading, Link, TextIcon, RoundedTabs, Modal, Pagination,Colors, Title, Button as OriginalButton } from 'advenio-components'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer,Label as LabelChart } from 'recharts';
import { ROLES,COLORSOU } from '../../Constants'
import { getSolAvance } from '../../utils/Utils'

import StagesAuthorizeActions from '../../Data/Redux/StageAuthorizeRedux'
import SolicitudActions from '../../Data/Redux/SolicitudRedux'
import AuthorizationStatusActions from '../../Data/Redux/AuthorizationStatusRedux'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  `

const TableContainer = styled.div`
  width: 100%;
  padding: 16px 32px;
`

const Button = styled(OriginalButton).attrs({
  marginRight: '0',
})`
  margin-left: auto;
`
const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PaginationContainer = styled.div`
  margin: 8px 0;
`
const CellContainer = styled.div`
margin: 0;
display: block;
max-width: 100%;
overflow: visible;
text-overflow: ellipsis;
white-space: nowrap;
word-wrap: normal;
font-size: 14px;
line-height: 20px;
padding: 14px;
color: #637381;
height: 100%;
text-align: center;
background-color: #FFFFFF;
white-space:  ${props=> props.solicitudName ? "nowrap" : 'initial'};
`
const InfoCell = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 12px;
`
const InfoRow = styled.div`
  display: flex;
  justify-content: flex-start;
`
const SolicitudTypeName = styled.span`
  margin-left: 4px;
  color: ${Colors.gray800}
`

const CellContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#FFFFFF;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`
const Status = styled.span`
  padding: 8px 16px;
  background-color: ${props=> COLORSOU[props.colorFill]|| null};
  border-radius: 20px;
  color:  ${props=> COLORSOU[props.colorFill] ? "#FFF" : '#000'};
  font-size: 14px;
`
const SolicitudName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.blue500};
  cursor: pointer;
`

class StagesAuthorization extends Component {

  state={
    stagesAuthorize:[],
    width:"",
    cx:90,
    perPage:10,
  }
  componentWillMount() {
    const  { user:{id, correo ,role:{edges}}, location:{ search }, getStageByAuthorize, getStepsByAutorizadorDirecto,getStepsByDirectBoss} = this.props
    const  { perPage } = this.state
    const queryParams = queryString.parse(search)
    let pageParams={ 'first': perPage }

      getStageByAuthorize({
          'autorizador':id,
          'statusIdIn':[2,3],
          'deleted':0
      })
      getStepsByDirectBoss({
          'jefeDirecto':correo,
          'statusIdIn':[2,3],
          'deleted':0
      })
      getStepsByAutorizadorDirecto({
        'autorizadorDirecto':id,
        'statusIdIn':[2,3],
        'deleted':0
    })
  }
  componentWillReceiveProps(nextProps) {
    const{
      data,
      loading,
      loadingBos,
      loadingDirector
    } = nextProps

    if(!loading && !loadingBos && !loadingDirector){
     this.setState({stagesAuthorize:data})
    }
  }

  componentWillUnmount() {

    this.props.resetStageAuthorization()
  }


  onClickPage= (page) => {
    const  { user:{id,correo,role:{edges} }, getStageByAuthorize, pagination, goTo} = this.props
    const {perPage}=this.state
    let pageParams = {}

    if(page === 'next'){
      pageParams = {
        "first": perPage,
        "after":pagination['endCursor']
      }
    }else{
      pageParams= {
        "last": perPage,
        "before":pagination['startCursor']
      }
    }

    goTo(`?${queryString.stringify(pageParams)}`)

    getStageByAuthorize({
      'data':{
        'autorizador':id,
        'statusIdIn':[2,3],
        'deleted':0
      },
      'pagination':pageParams
    })
  }

  navigateToProfile = (solicitudId, solicitudStageId) =>{
    this.props.goTo(`/authorizations/solicitudStage/${solicitudStageId}`)
}

  render() {
    const {data, loading, loadingBos, loadingDirector, user } = this.props
    const { stagesAuthorize,width, cx } = this.state

    return (
      <Container>
        <TableContainer>
          <HeaderContainer>
            <Title style={{marginRight:'5px'}} >Etapas por Autorizar</Title>
          </HeaderContainer>
          {/* <PaginationContainer>
            <Pagination
              onPageClick={this.onClickPage}
              basicPagination={true}
              //basicNextDisabled={true}
              //basicPrevDisabled={true}
            />
          </PaginationContainer> */}
          {(loading || loadingBos || loadingDirector )?
            <TableLoading
              withBorder
              roundedCorners
              columns={[
                {
                  id: 1,
                  header: 'Vacantes',
                  element: 'Text',
                  center: true,
                },
                {
                  id: 2,
                  header: 'OU',
                  element: 'Text',
                  center: true,
                },
                {
                  id: 3,
                  header: 'Etapa',
                  element: 'Text',
                  center: true,
                },
                {
                  id: 4,
                  header: 'Descripcion',
                  element: 'Text',
                  ///width: '200px',
                  center: true,
                },
                {
                  id: 5,
                  header: 'Estatus',
                  element: 'Text',
                  //width: '200px',
                  center: true,
                },
                {
                  id: 6,
                  header: 'Fecha',
                  element: 'Text',
                  //width: '200px',
                  center: true,
                },
              ]}
              rows={10} />
            :
          <Table data={stagesAuthorize}
          roundedCorners
          withBorder
            columns={[
              {
              id: '1',
              maxWidth: 270,
              Header: () => <TableHeader title="Vacantes" center blue />,
              accessor: ({ flujo:{ solicitud },id, autorizador }) => ({ solicitud, id,autorizador }),
              Cell:({ value: { solicitud, id, autorizador }, index }) =>
                <CellContainer center index={index} >
                  <InfoCell>
                        <InfoRow>
                          <SolicitudName  onClick={() => { this.navigateToProfile(solicitud.id, id)}}>
                              {solicitud.nombrePuesto.nombre}
                          </SolicitudName>
                        </InfoRow>
                        {/* <InfoRow>
                          Tipo Solicitud: <SolicitudTypeName>{nombre}</SolicitudTypeName>
                        </InfoRow> */}
                      </InfoCell>
                </CellContainer>,
              },
              {
              id: '2',
              //maxWidth: 150,
              Header: () => <TableHeader title="OU" center blue />,
              accessor: ({flujo: { solicitud: { ou: { nombre, codigoInterno } } } }) => ({ nombre, codigoInterno }),
              Cell: ({ value: { nombre, codigoInterno }, index }) =>  <CellContainer center index={index} ><Status colorFill={codigoInterno}>{nombre}</Status></CellContainer>,
              },
              {
              id: '3',
              //maxWidth: 210, //150
              Header: () => <TableHeader title="Etapa" center blue />,
              accessor: ({ nombre }) => ({ nombre }),
              Cell: ({ value: { nombre }, index }) => <CellContainer center index={index} >{nombre}</CellContainer>,
              },
              {
              id: '4',
              ////maxWidth: 124,
              Header: () => <TableHeader title="Descripción" center blue />,
              accessor: ({ descripcion }) => ({ descripcion }),
              Cell:({ value: { descripcion }, index }) => <CellContainer center index={index} >{descripcion}</CellContainer>,
              },
              {
              id: '5',
              //maxWidth: 170,
              Header: () => <TableHeader title="Estatus" center blue />,
              accessor: ({ status:{ nombre } }) => ({ nombre }),
              Cell:({ value: { nombre }, index }) => <CellContainer center index={index} >{nombre}</CellContainer>,
              },
              {
              id: '6',
             // maxWidth: 180,
              Header: () => <TableHeader title="Fecha" center blue />,
              accessor: ({ flujo: { solicitud: { fecha } } }) => ({ fecha }),
              Cell:({ value: { fecha }, index }) => <CellContainer center index={index} >{moment(fecha).format('DD MMM Y H:mm:ss')}</CellContainer>,
              },
            ]}
          />}
        </TableContainer>
      </Container>
    )
  }
}

StagesAuthorization.defaultProps = {
  data: [],
  loading: false
}
const mapStateToProps = state => ({
  user: state.auth.user,
  loading: state.stageAuthorize.get.fetching ,
  loadingBos: state.stageAuthorize.getBos.fetching,
  loadingDirector: state.stageAuthorize.getDirector.fetching,
  data: state.stageAuthorize.get.results,
  // pagination: state.stageAuthorize.get.results.pageInfo,

})

const mapDispatchToProps = dispatch => ({
  getStageByAuthorize: (data = null) => dispatch(StagesAuthorizeActions.stageAuthorizeRequest(data)),
  getStepsByAutorizadorDirecto: (data = null) => dispatch(StagesAuthorizeActions.stepsByAutorizadorDirectoRequest(data)),
  getStepsByDirectBoss: (data = null) => dispatch(StagesAuthorizeActions.stepsByDirectBossRequest(data)),
  resetStageAuthorization: () => dispatch(StagesAuthorizeActions.resetStageAuthorization()),
  setSolicitudStageId: id => dispatch(SolicitudActions.setSolicitudStageId(id)),
  goTo: path => dispatch(push(path)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StagesAuthorization))
