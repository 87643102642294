import { createActions, createState, createReducer } from 'reduxsauce-crud'
import R from 'ramda'

const d = { deep: true }

export const RegionRedux = createActions(
  {
    RegionRequest: [''],
    RegionSuccess: ['data'],
    RegionError: ['err'],
    // updateExpense: ['data']
  },
  {
    prefix: 'REGION_',
    defaultActions: {
      get: true,
    },
  },
)

const { Types, Creators } = RegionRedux
export const RegionTypes = Types
export default Creators

export const INITIAL_STATE = createState({}, {
  get: true,
})

const RegionRequest = state =>
  state.merge({ get: { fetching: true, error: null } }, d)

const RegionError = (state, { err }) =>
  state.merge({ get: { fetching: false, error: err } }, d)

const RegionSuccess = (state, data) => {
  return state.merge({ get: { fetching: false, results: data.data } }, d)
}

// const updateExpense = (state, { data }) => {
//   const newSolicitud = R.clone(state.get.results.data)
//   const activeExpenseIndex = R.findIndex(R.propEq('id', data.data.id))(newSolicitud)
//   newSolicitud[activeExpenseIndex] = data.data
//   return state.merge({ get: { fetching: false, results: { data: newSolicitud } } }, d)
// }


export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.RegionRequest]: RegionRequest,
    [Types.RegionSuccess]: RegionSuccess,
    [Types.RegionError]: RegionError,
    // [Types.updateExpense]: updateExpense,
  },
  {
    defaultActions: {
      get: true,
    },
    Types,
  },
)
