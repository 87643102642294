import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import styled from 'styled-components'
import { Form } from 'formsy-react'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import { Button, Colors, GlobalStyles, Title1, Title2, TextInput } from 'advenio-components'
import ResetPasswordActions from '../../Data/Redux/ResetPasswordRedux'

const AdvenioLogo = styled.img`
  width: 350px;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    width: 100%;
  }
`
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
`
const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Card = styled.div`
  ${GlobalStyles.borderRadius};
  width: 400px;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0,0,0, .1);

  @media (max-width: 768px) {
    width: 350px;
  }
`
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px 0;

  @media (max-width: 767px) {
    padding: 16px 8px 0;
  }
`
const Heading = styled(Title2)`
  color: ${Colors.blueText};
`
const Success = styled(Form)`
  display: flex;
  justify-content: center;
`
const InputContainer = styled.div`
  width: 100%;
`
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;

  @media (max-width: 767px) {
    padding: 16px;
  }
`
const Back = styled.span`
  color: ${Colors.blue500};
  cursor: pointer;
`
const ErrorContainer = styled.div`
  ${GlobalStyles.borderRadius};
  padding: 16px;
  background-color: ${Colors.red100};
  border: 1px solid ${Colors.red300};
`
const ErrorTitle = styled(Title1)`
  color: ${Colors.redText};
`
const ErrorText = styled.p`
  display: block;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 14px;
  color: ${Colors.redText};
`

class SetNewPasword extends Component {
  state = {
    isFirstTime: false,
    token: null,
    password: '',
    passwordConfirmation: '',
  }

  componentWillMount = () => {
    const { location, match, setSuccessFalse, validateToken } = this.props
    const { token } = match.params
    const queryParams = queryString.parse(location.search)
    const isFirstTime = (queryParams && queryParams.isFirstTime) || false

    setSuccessFalse()
    validateToken(token)

    this.setState({
      isFirstTime,
      token,
    })
  }

  onChange = ({ password, password_confirmation }) => {
    this.setState({
      password,
      passwordConfirmation: password_confirmation,
    })
  }

  reset = (data) => {
    this.props.reset(this.state.token, data)
    this.setState({
      password: '',
      passwordConfirmation: '',
    })
  }

  backToLogin = () => {
    this.props.goTo('/login')
  }

  render() {
    const { loading, setPasswordError, success, invalidTokenError } = this.props
    const { isFirstTime, password, passwordConfirmation } = this.state
    const prefix = (isFirstTime && 'Establecer') || 'Restablecer'

    return (
      <Container>
        <ContentWrapper>
          <AdvenioLogo src="/assets/talisis_logo.png" />
          <Card>
            <CardHeader>
              <Heading>{prefix} Contraseña</Heading>
            </CardHeader>
            <CardBody>
              {(setPasswordError || invalidTokenError) && (
                <ErrorContainer>
                  <ErrorTitle>Error:</ErrorTitle>
                  <ErrorText>{setPasswordError || 'Token Inválido'}</ErrorText>
                </ErrorContainer>
              )}
              {success ?
                <Success>
                  <span>
                    Contraseña actualizada correctamente
                  </span>
                </Success>
                :
                <Form onChange={this.onChange} onValidSubmit={this.reset}>
                  {!invalidTokenError &&
                    <div>
                      <InputContainer>
                        <TextInput
                          type="password"
                          id="password"
                          value={password}
                          name="password"
                          label="Contraseña"
                        />
                      </InputContainer>
                      <InputContainer>
                        <TextInput
                          type="password"
                          id="password_confirmation"
                          name="password_confirmation"
                          value={passwordConfirmation}
                          label="Confirmar Contraseña"
                          validations="equalsField:password"
                          validationErrors={{ equalsField: 'Las contraseñas no coinciden' }}
                        />
                      </InputContainer>
                      <Button
                        primary
                        fullWidth
                        type="submit"
                        loading={loading}
                      >
                        {prefix}
                      </Button>
                    </div>
                  }
                </Form>
              }
            </CardBody>
          </Card>
          <Back onClick={this.backToLogin}>Regresar a la página de ingreso</Back>
        </ContentWrapper>
      </Container>
    )
  }
}

SetNewPasword.propTypes = {
  /** Loading flag */
  loading: PropTypes.bool.isRequired,
  /** React router location information, containing query params etc. */
  location: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  /** Error message returned if the password reset failed */
  setPasswordError: PropTypes.string.isRequired,
  /** Invalid token error message */
  invalidTokenError: PropTypes.string,
  /** React router path information */
  match: PropTypes.object,
  /** Flag marking whether the Password Reset request was successful */
  success: PropTypes.bool.isRequired,
  /** Navigate to the given URI */
  goTo: PropTypes.func.isRequired,
  /** Submit the password reset to the API */
  reset: PropTypes.func.isRequired,
  /** Reset the 'success' flag value */
  setSuccessFalse: PropTypes.func.isRequired,
  /** Submit a request to the API to validate the password reset token */
  validateToken: PropTypes.func.isRequired,
}

SetNewPasword.defaultProps = {
  invalidTokenError: '',
  location: {},
  match: {},
}

const mapStateToProps = ({ resetPassword }) => ({
  loading: resetPassword.sending.fetching,
  setPasswordError: resetPassword.error,
  invalidTokenError: resetPassword.get.error,
  success: resetPassword.success,
})

const mapDispatchToProps = dispatch => ({
  reset: (token, data) => dispatch(ResetPasswordActions.resetRequest(token, data)),
  validateToken: token => dispatch(ResetPasswordActions.getRequest(token)),
  setSuccessFalse: () => dispatch(ResetPasswordActions.setSuccessFalse()),
  goTo: path => dispatch(push(path)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetNewPasword))
