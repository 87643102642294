import { createActions, createState, createReducer } from 'reduxsauce-crud'
import R from 'ramda'

const d = { deep: true }

export const CataloguesRedux = createActions(
  {
    catalogDptoRhRequest: ['data'],
    catalogDptoRhSuccess: ['data'],
    catalogDptoRhError: ['err'],
    catalogStagesRequest: ['data'],
    catalogStagesSuccess: ['data'],
    catalogStagesError: ['err'],
    catAuthorizationTypesRequest: ['data'],
    catAuthorizationTypesSuccess: ['data'],
    catAuthorizationTypesError: ['err'],
  },
  {
    prefix: 'CATALOGUES_',
    defaultActions: {
      get: true,
    },
  },
)

const { Types, Creators } = CataloguesRedux
export const CataloguesTypes = Types
export default Creators

export const INITIAL_STATE = createState({
  getDptoRh:{
    fetching:false,
    error:null,
    results:[]
  },
  getCatStages:{
    fetching:false,
    error:null,
    results:[]
  },
  getCatAuthTypes:{
    fetching:false,
    error:null,
    results:[]
  }
}, {
  get: true,
})

const catalogDptoRhRequest = state =>
  state.merge({ getDptoRh: { fetching: true, error: null } }, d)

const catalogDptoRhError = (state, { err }) =>
  state.merge({ getDptoRh: { fetching: false, error: err } }, d)

const catalogDptoRhSuccess = (state, data) => {
  return state.merge({ getDptoRh: { fetching: false, results: data.data } }, d)
}
const catalogStagesRequest = state =>
  state.merge({ getCatStages: { fetching: true, error: null } }, d)

const catalogStagesError = (state, { err }) =>
  state.merge({ getCatStages: { fetching: false, error: err } }, d)

const catalogStagesSuccess = (state, data) => {
  return state.merge({ getCatStages: { fetching: false, results: data.data } }, d)
}
const catAuthorizationTypesRequest = state =>
  state.merge({ getCatAuthTypes: { fetching: true, error: null } }, d)

const catAuthorizationTypesError = (state, { err }) =>
  state.merge({ getCatAuthTypes: { fetching: false, error: err } }, d)

const catAuthorizationTypesSuccess = (state, data) => {
  return state.merge({ getCatAuthTypes: { fetching: false, results: data.data } }, d)
}

export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.catalogDptoRhRequest]: catalogDptoRhRequest,
    [Types.catalogDptoRhSuccess]: catalogDptoRhSuccess,
    [Types.catalogDptoRhError]: catalogDptoRhError,
    [Types.catalogStagesRequest]: catalogStagesRequest,
    [Types.catalogStagesSuccess]: catalogStagesSuccess,
    [Types.catalogStagesError]: catalogStagesError,
    [Types.catAuthorizationTypesRequest]: catAuthorizationTypesRequest,
    [Types.catAuthorizationTypesSuccess]: catAuthorizationTypesSuccess,
    [Types.catAuthorizationTypesError]: catAuthorizationTypesError,
  },
  {
    defaultActions: {
      get: true,
    },
    Types,
  },
)
