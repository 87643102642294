import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { gql } from '@apollo/client';
import R,{isEmpty, isNil} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import FlowAuthorizationStagesActions, { FlowAuthorizationStagesRedux, FlowAuthorizationStagesTypes } from '../Redux/FlowAuthorizationStagesRedux'
import { FLOWAUTHORIZATIONSATGES } from '../GraphQL'
import { parseDataGraphQL, paramsGraphQL, paramsGraphQLPagination } from '../../utils/Utils'

const flowAuthorizationStagesCrudObservable = createCRUDObservable({
  mainRedux: FlowAuthorizationStagesRedux,
  reduxPath: 'flowAuthorizationStages',
})

const getFlowAuthorizationStagesEpic = (action$, store, { Api }) => (
  action$.ofType(FlowAuthorizationStagesTypes.FlowAuthorizationStagesRequest)
    .mergeMap(({data}) => {
      let getAllFlujoAuthorizationStages=''
      let sendData={}
      let params = '', filters = '',binding = '', paramsGroup = ''
      if(! isEmpty(data.data)){
        ({ params, binding } = paramsGraphQL(FLOWAUTHORIZATIONSATGES.queryFlowAuthorizationAttr, data.data))
        sendData={...data.data}
        filters= `(filters:{ ${binding} })`
        paramsGroup = `(${params})`
      }
      const createQuery = FLOWAUTHORIZATIONSATGES.getAllFlujoAuthorizationStages.replace('$params',paramsGroup).replace('$filters',filters)

      getAllFlujoAuthorizationStages = gql`${createQuery}`
      sendData={...sendData ,}

      return Observable
        .fromPromise(
          Api.query({query: getAllFlujoAuthorizationStages,variables: sendData, fetchPolicy: 'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              FlowAuthorizationStagesActions.FlowAuthorizationStagesError(response['message'])
            ]
          }

          const { allFlujosAutorizacion:{ edges } } = response.data

          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              FlowAuthorizationStagesActions.FlowAuthorizationStagesSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            FlowAuthorizationStagesActions.FlowAuthorizationStagesSuccess(data),
          ]
        })
      })
)

const updateFlowAuthorizationStagesEpic = (action$, store, { Api }) => (
  action$.ofType(FlowAuthorizationStagesTypes.updateFlowAuthorizationStagesRequest)
    .mergeMap(({data}) => {
      let updateFlujoAuthorizationStages=''
      let sendData={}
      let params = '', inputs = '',binding = '', paramsGroup = ''
      if(! isEmpty(data.data)){
        ({ params, binding } = paramsGraphQL(FLOWAUTHORIZATIONSATGES.mutationFlowAuthorizationAttr, data.data))
        sendData={...data.data}
        inputs= `(input:{ ${binding} })`
        paramsGroup = `(${params})`
      }
      const createQuery = FLOWAUTHORIZATIONSATGES.updateFlujoAuthorizationStages.replace('$params',paramsGroup).replace('$input',inputs)

      updateFlujoAuthorizationStages = gql`${createQuery}`
      sendData={...sendData ,}

      return Observable
        .fromPromise(
          Api.query({query: updateFlujoAuthorizationStages,variables: sendData, fetchPolicy: 'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              FlowAuthorizationStagesActions.updateFlowAuthorizationStagesError(response['message'])
            ]
          }

          const { setStageFlujoAutorizacion:{ flujo } } = response.data

          if(isEmpty(flujo) || isNil(flujo)){
            return [
              notificationFail('No se encontraron resultados!.'),
              FlowAuthorizationStagesActions.updateFlowAuthorizationStagesSuccess([]),
            ]
          }

          return [
            FlowAuthorizationStagesActions.updateFlowAuthorizationStagesSuccess(flujo),
          ]
        })
      })
)

const postAutorizationFlowEpic = (action$, store, { Api }) => (
  action$.ofType(FlowAuthorizationStagesTypes.postAutorizationFlowRequest)
    .mergeMap(({data}) => {
      let postFlujoAutorizacion=''
      let sendData={}
      let params = '', inputs = '',binding = '', paramsGroup = ''
      if(! isEmpty(data)){
        ({ params, binding } = paramsGraphQL(FLOWAUTHORIZATIONSATGES.mutationFlowAuthorizationAttr, data))
        sendData={...data}
        inputs= `(input:{ ${binding} })`
        paramsGroup = `(${params})`
      }
      const createQuery = FLOWAUTHORIZATIONSATGES.postFlujoAutorizacion.replace('$params',paramsGroup).replace('$input',inputs)
      postFlujoAutorizacion = gql`${createQuery}`
      sendData={...sendData ,}

      return Observable
        .fromPromise(
          Api.query({query: postFlujoAutorizacion,variables: sendData, fetchPolicy: 'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              FlowAuthorizationStagesActions.postAutorizationFlowError(response['message'])
            ]
          }

          const { addFlujoAutorizacion:{ authorizationFlowSpec } } = response.data

          if(isEmpty(authorizationFlowSpec)|| isNil(authorizationFlowSpec)){
            return [
              notificationFail('No se encontraron resultados!.'),
              FlowAuthorizationStagesActions.postAutorizationFlowSuccess([]),
            ]
          }
          return [
            FlowAuthorizationStagesActions.postAutorizationFlowSuccess(authorizationFlowSpec),
          ]
        })
      })
)
const postStageFlowAuthorizationEpic = (action$, store, { Api }) => (
  action$.ofType(FlowAuthorizationStagesTypes.postStageFlowAuthorizationRequest)
    .mergeMap(({data}) => {
      let postStageFlujoAutorizacion=''
      let sendData={}
      let params = '', inputs = '',binding = '', paramsGroup = ''
      if(! isEmpty(data)){
        ({ params, binding } = paramsGraphQL(FLOWAUTHORIZATIONSATGES.mutationFlowAuthorizationAttr, data))
        sendData={...data}
        inputs= `(input:{ ${binding} })`
        paramsGroup = `(${params})`
      }
      const createQuery = FLOWAUTHORIZATIONSATGES.postStageFlujoAutorizacion.replace('$params',paramsGroup).replace('$input',inputs)
      postStageFlujoAutorizacion = gql`${createQuery}`
      sendData={...sendData ,}

      return Observable
        .fromPromise(
          Api.query({query: postStageFlujoAutorizacion,variables: sendData, fetchPolicy: 'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              FlowAuthorizationStagesActions.postStageFlowAuthorizationError(response['message'])
            ]
          }

          const { updateStageFlujoAutorizacion:{ flujo } } = response.data

          if(isEmpty(flujo) || isNil(flujo)){
            return [
              notificationFail('No se encontraron resultados!.'),
              FlowAuthorizationStagesActions.postStageFlowAuthorizationSuccess([]),
            ]
          }
          return [
            FlowAuthorizationStagesActions.postStageFlowAuthorizationSuccess(flujo),
          ]
        })
      })
)

export const flowAuthorizationStagesObservers = Object.assign({}, flowAuthorizationStagesCrudObservable.observers, {})

export const flowAuthorizationStagesEpic = combineEpics(
  flowAuthorizationStagesCrudObservable.epic,
  getFlowAuthorizationStagesEpic,
  updateFlowAuthorizationStagesEpic,
  postAutorizationFlowEpic,
  postStageFlowAuthorizationEpic,
)
