import { createActions, createState, createReducer } from 'reduxsauce-crud'
import {clone, findIndex, propEq} from 'ramda'

const d = { deep: true }

export const StageAuthorizeRedux = createActions(
  {
    resetStageAuthorization: [''],
    stageAuthorizeRequest: ['data'],
    stageAuthorizeSuccess: ['data'],
    stageAuthorizeError: ['err'],
    stepsByAutorizadorDirectoRequest: ['data'],
    stepsByAutorizadorDirectoSuccess: ['data'],
    stepsByAutorizadorDirectoError: ['err'],
    stepsByDirectBossRequest: ['data'],
    stepsByDirectBossSuccess: ['data'],
    stepsByDirectBossError: ['err'],
    // updateExpense: ['data']
  },
  {
    prefix: 'STAGE_AUTHORIZE_',
    defaultActions: {
      get: true,
    },
  },
)

const { Types, Creators } = StageAuthorizeRedux
export const StageAuthorizeTypes = Types
export default Creators

export const INITIAL_STATE = createState({
  stageAuthorize:{
    fetching: false ,
    error: null,
    results:[]
  },
  getDirector:{
    fetching:false,
    error:null,
  },
  getBos:{
    fetching:false,
    error:null
  }
}, {
  get: true,
})

const resetStageAuthorization = state => state.merge({
    get:{ fetching:false, error:null, results:[]},
  }, d)

const stageAuthorizeRequest = state =>
  state.merge({ get: { fetching: true, error: null } }, d)

const stageAuthorizeError = (state, { err }) =>
  state.merge({ get: { fetching: false, error: err } }, d)

const stageAuthorizeSuccess = (state, data) => {
  const results = clone(state.get.results)
  if(data.data.length > 0){
    data.data.map(p=> {
      const solicitudIndex = findIndex(propEq('id', p.id))(results)
      if(solicitudIndex === -1){
        results.push(p)
      }
    })  }

  return state.merge({ get: { fetching: false, results } }, d)
}
const stepsByAutorizadorDirectoRequest = state =>
  state.merge({ getDirector: { fetching: true, error: null } }, d)

const stepsByAutorizadorDirectoError = (state, { err }) =>
  state.merge({ getDirector: { fetching: false, error: err } }, d)

const stepsByAutorizadorDirectoSuccess = (state, data) => {
  const results = clone(state.get.results)
  if(data.data.length > 0){
    data.data.map(p=> {
      const solicitudIndex = findIndex(propEq('id', p.id))(results)
      if(solicitudIndex === -1){ // >
        results.push(p)
      }
    })
  }
  return state.merge({ getDirector: { fetching: false} ,get:{ results } }, d)
}
const stepsByDirectBossRequest = state =>
  state.merge({ getBos: { fetching: true, error: null } }, d)

const stepsByDirectBossError = (state, { err }) =>
  state.merge({ getBos: { fetching: false, error: err } }, d)

const stepsByDirectBossSuccess = (state, data) => {
  const results = clone(state.get.results)
  if(data.data.length > 0){
    data.data.map(p=> {
      const solicitudIndex = findIndex(propEq('id', p.id))(results)
      if(solicitudIndex === -1){
        results.push(p)
      }
    })
  }
  return state.merge({ getBos: { fetching: false} ,get:{ results } }, d)
}

// const updateExpense = (state, { data }) => {
//   const newSolicitud = R.clone(state.get.results.data)
//   const activeExpenseIndex = R.findIndex(R.propEq('id', data.data.id))(newSolicitud)
//   newSolicitud[activeExpenseIndex] = data.data
//   return state.merge({ get: { fetching: false, results: { data: newSolicitud } } }, d)
// }


export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.resetStageAuthorization]: resetStageAuthorization,
    [Types.stageAuthorizeRequest]: stageAuthorizeRequest,
    [Types.stageAuthorizeSuccess]: stageAuthorizeSuccess,
    [Types.stageAuthorizeError]: stageAuthorizeError,
    [Types.stepsByAutorizadorDirectoRequest]: stepsByAutorizadorDirectoRequest,
    [Types.stepsByAutorizadorDirectoSuccess]: stepsByAutorizadorDirectoSuccess,
    [Types.stepsByAutorizadorDirectoError]: stepsByAutorizadorDirectoError,
    [Types.stepsByDirectBossRequest]: stepsByDirectBossRequest,
    [Types.stepsByDirectBossSuccess]: stepsByDirectBossSuccess,
    [Types.stepsByDirectBossError]: stepsByDirectBossError,
    // [Types.updateExpense]: updateExpense,
  },
  {
    defaultActions: {
      get: true,
    },
    Types,
  },
)
