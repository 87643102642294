import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import styled from 'styled-components'
import { clone, isEmpty } from  'ramda'
import moment from 'moment'
import { MsalContext } from "@azure/msal-react"
import * as AWS from 'aws-sdk'
import shortid from 'shortid'
import { Table, TableCell, TableHeader, TableLoading, Link, TextIcon, RoundedTabs, Modal, Pagination,Colors, Title, Button as OriginalButton } from 'advenio-components'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer,Label as LabelChart } from 'recharts';
import { ROLES,COLORSOU, exeObjext } from '../../Constants'
import {  getSolAvance,slugify } from '../../utils/Utils'
import { uploadFile } from '../../utils/FileSystem'
import { loginRequest } from "../../auth365Config"
import { AWS_IDENTITY_POOL_ID } from '../../config'


import SolicitudActions from '../../Data/Redux/SolicitudRedux'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  `

const TableContainer = styled.div`
  width: 100%;
  padding: 16px 32px;
`

const Button = styled(OriginalButton).attrs({
  marginRight: '0',
})`
  margin-left: auto;
`
const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PaginationContainer = styled.div`
  margin: 8px 0;
`
const CellContainer = styled.div`
margin: 0;
display: block;
max-width: 100%;
overflow: visible;
text-overflow: ellipsis;
white-space: nowrap;
word-wrap: normal;
font-size: 14px;
line-height: 20px;
padding: 14px;
color: #637381;
height: 100%;
text-align: center;
background-color: #FFFFFF;
white-space:  ${props=> props.solicitudName ? "nowrap" : 'initial'};
`
const InfoCell = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 12px;
`
const InfoRow = styled.div`
  display: flex;
  justify-content: flex-start;
`
const SolicitudTypeName = styled.span`
  margin-left: 4px;
  color: ${Colors.gray800}
`

const CellContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#FFFFFF;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`
const Status = styled.span`
  padding: 8px 16px;
  background-color: ${props=> COLORSOU[props.colorFill]|| null};
  border-radius: 20px;
  color:  ${props=> COLORSOU[props.colorFill] ? "#FFF" : '#000'};
  font-size: 14px;
`
const SolicitudName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.blue500};
  cursor: pointer;
`
class Solicituds extends Component {

  static contextType = MsalContext

  state={
    solicituds:[],
    width:"",
    cx:90,
    perPage:10,
    tokenMS:null,
    tokenGoogle:null,
    urlfile: null
  }
  componentWillMount() {
    const queryParams = queryString.parse(this.props.location.search)
    const  { user:{correo,role:{edges} }, getAllSolicituds} = this.props
    const {perPage}=this.state
    const isSTAFFCH = edges.filter(r => r.node.id === ROLES.STAFF_CH)[0] || false
    const isRECLUTADOR_STAFF = edges.filter(r => r.node.id === ROLES.RECLUTADOR_STAFF)[0] || false

    let pageParams={ 'first': perPage }

    if( ! isEmpty(queryParams)){
      pageParams = queryParams
    }

    if(isSTAFFCH || isRECLUTADOR_STAFF ){
      getAllSolicituds({
        'data':{
          'usuario':correo,
          'deleted':0
      },
        'pagination':pageParams
      })
    }
    else {
      getAllSolicituds({
        'data':{
          'deleted':0
        },
        'pagination':pageParams
      })
    }
    if(window.innerWidth > 1447){
      this.setState({width:'none'})
    }
    if(window.innerWidth < 1300){
      this.setState({cx:65})
    }
    // AWS.config.region = 'us-west-2'; // Región
    // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    //     IdentityPoolId: AWS_IDENTITY_POOL_ID,
    //     //Logins with providers
    //     // Logins:{
    //     //   'accounts.google.com': this.state.tokenGoogle,,
    //     //   'login.microsoftonline.com': this.state.tokenMS,
    //     // }
    // });

    // const file = document.getElementById("photoupload").files
    // console.log(file)
    // uploadFile('solicitudes',file).then(result => this.setState({urlfile:result}))
  }
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
  }

  onAddSolicitud =() => {
    this.props.goTo('solicitud/new')
  }

  componentWillReceiveProps(nextProps) {
    const{
      data,
      loading
    } = nextProps
    if(data !== this.props.data && !loading){

      const solicituds = clone(data.map(s=>{
      const stages = s['flujo']['edges'][0]['node']['etapas']['edges']
      const {
        stgCurrent
        , proAuthorization
        , circleBarAuthorization
        , dataAuthorization
        , stages_authorized_authorization
        , stages_total_authorization
        , proRecruitment
        , circleBarRecruitment
        , dataRecruitment
        , stages_authorized_recruitment
        , stages_total_recruitment
      } = getSolAvance(stages)
      return {
        ...s
        , stage_current: stgCurrent
        , circleBarAuthorization
        , dataAuthorization
        , proAuthorization
        , circleBarRecruitment
        , dataRecruitment
        , proRecruitment
      }
      // const  {proAuthorization, stages_total_recruitment}
      }))
      // exeObjext.map(p=>solicituds.push(p))
      // console.log(solicituds)
      this.setState({solicituds})
    }
  }


  renderCustomizedLabel = () => {
    return (
      <text
        x={10}
        y={15}
        fill="black"
        fontSize="10px"
        dominantBaseline="central"
      >
        {`90%`}
      </text>
    )
  }

 // onAddSolicitud =() => this.props.goTo(`solicitud/new-solicitud`)

  onClickPage= (page) => {
    const  { user:{correo,role:{edges} }, getAllSolicituds, pagination, goTo} = this.props
    const {perPage}=this.state
    const isSTAFFCH = edges.filter(r => r.node.id === ROLES.STAFF_CH)[0] || false
    const isRECLUTADOR_STAFF = edges.filter(r => r.node.id === ROLES.RECLUTADOR_STAFF)[0] || false
    let pageParams = null

    if(page === 'next'){
      pageParams = {
        "first": perPage,
        "after":pagination['endCursor']
      }
    }else{
      pageParams= {
        "last": perPage,
        "before":pagination['startCursor']
      }
    }
    goTo(`?${queryString.stringify(pageParams)}`)
    if(isSTAFFCH || isRECLUTADOR_STAFF ){
      getAllSolicituds({
        'data':{
          'usuario':correo,
          'deleted':0

      },
        'pagination':pageParams
      })
    }
    else {
      getAllSolicituds({
        'data':{
          'deleted':0
        },
        'pagination':pageParams
      })
    }

  }

  navigateToProfile = (solicitudId) => {
    return this.props.goTo(`/solicitud/${solicitudId}`)
  }

  render() {
    const {data, loading } = this.props
    const { solicituds,width, cx } = this.state

    return (
      <Container>
        <TableContainer>
          <HeaderContainer>
            <Title style={{marginRight:'5px'}} >Solicitudes</Title>
            <Button primary disabled={loading} loading={loading} onClick={this.onAddSolicitud}>
              <TextIcon label="Solicitud" iconType="plus" iconViewBox="-2 -2 24 24" />
            </Button>
          </HeaderContainer>
          <PaginationContainer>
          <Pagination
              onPageClick={this.onClickPage}
              basicPagination={true}
              //basicNextDisabled={true}
              //basicPrevDisabled={true}
            />
          </PaginationContainer>
          {loading ?
            <TableLoading
              withBorder
              roundedCorners
              columns={[
                {
                  id: 1,
                  header: 'Vacante',
                  element: 'Text',
                  center: true,
                },
                {
                  id: 2,
                  header: 'Estatus',
                  element: 'Text',
                  center: true,
                },
                {
                  id: 3,
                  header: 'OU',
                  element: 'Text',
                  ///width: '200px',
                  center: true,
                },
                {
                  id: 4,
                  header: 'Centro',
                  element: 'Text',
                  //width: '200px',
                  center: true,
                },
                {
                  id: 5,
                  header: 'Fecha Creación',
                  element: 'Text',
                  //width: '200px',
                  center: true,
                },
                {
                  id: 6,
                  header: 'Avance Autorización',
                  element: 'Text',
                  //width: '200px',
                  center: true,
                },
                {
                  id: 7,
                  header: 'Avance Reclutamiento',
                  element: 'Text',
                  //width: '200px',
                  center: true,
                },
                // {
                //   id: 8,
                //   header: 'Tipo',
                //   element: 'Text',
                //   //width: '200px',
                //   center: true,
                // },
                {
                  id: 9,
                  header: 'Etapa',
                  element: 'Text',
                  //width: '200px',
                  center: true,
                }
              ]}
              rows={10} />
            :
          <Table data={solicituds}
          roundedCorners
          withBorder
            columns={[
              {
              id: '1',
              maxWidth:  width === 'none'? width : 210,
              Header: () => <TableHeader title="Vacante" center blue />,
              accessor: ({ nombrePuesto, id ,tipoSolicitud :{ nombre } }) => ({ nombrePuesto, id, nombre }),
              Cell:({ value: { nombrePuesto, id, nombre }, index }) =>
                <CellContainer solicitudName center index={index} >
                  <InfoCell>
                        <InfoRow>
                          <SolicitudName onClick={() => this.navigateToProfile(id)}>
                              {nombrePuesto.nombre}
                          </SolicitudName>
                        </InfoRow>
                        <InfoRow>
                          Tipo Solicitud: <SolicitudTypeName>{nombre}</SolicitudTypeName>
                        </InfoRow>
                      </InfoCell>
                </CellContainer>,
              },
              {
              id: '2',
              maxWidth:  width === 'none'? width : 124,
              Header: () => <TableHeader title="Estatus" center blue />,
              accessor: ({ status: { nombre }  }) => ({ nombre }),
              Cell: ({ value: { nombre }, index }) => <CellContainer center index={index} >{nombre}</CellContainer>,
              },
              {
              id: '3',
              maxWidth:  width === 'none'? width : 124,
              Header: () => <TableHeader title="OU" center blue />,
              accessor: ({ ou:{ nombre, codigoInterno } }) => ({ nombre,codigoInterno }),
              Cell:({ value: { nombre,codigoInterno }, index }) => <CellContainer style={{whiteSpace:'nowrap'}}center index={index} ><Status colorFill={codigoInterno}>{nombre}</Status></CellContainer>,
              },
              {
              id: '4',
              maxWidth:  width === 'none'? width : 165,
              Header: () => <TableHeader title="Centro" center blue />,
              accessor: ({ centro:{ nombre } }) => ({ nombre }),
              Cell:({ value: { nombre }, index }) => <CellContainer center index={index} >{nombre}</CellContainer>,
              },
              {
              id: '5',
              maxWidth:  width === 'none'? width : 160,
              Header: () => <TableHeader style={{whiteSpace: 'initial'}} title="Fecha Creación" center blue />,
              accessor: ({ fecha }) => ({ fecha }),
              Cell:({ value: { fecha }, index }) => <CellContainer center index={index} >{moment(fecha).format('DD MMM Y H:mm:ss')}</CellContainer>,
              },
              {
                id: '6',
              //maxWidth:  width === 'none'? width : 200,
              Header: () => <TableHeader title="Avance Autorización" center blue />,
              accessor: ({ centro:{ nombre },ou:{ codigoInterno } , circleBarAuthorization, dataAuthorization, proAuthorization }) => ({ nombre, codigoInterno, circleBarAuthorization, dataAuthorization, proAuthorization }),
              Cell:({ value: { nombre ,codigoInterno, circleBarAuthorization, dataAuthorization, proAuthorization}, index }) =>
                <CellContainer center index={index} >
                { proAuthorization === 0 ?
                  <div style={{fontSize:"12px" ,color:"#000"}}><span >0%</span></div>
                  :
                  <PieChart width={180} height={35} >
                    <Pie
                        data={dataAuthorization}
                        cx={80}
                        cy={12}
                        startAngle={0}
                        endAngle={circleBarAuthorization}
                        innerRadius={12}
                        outerRadius={17}
                        fill="#8884d8"
                        //labelLine={false}
                        //label={this.renderCustomizedLabel}
                        paddingAngle={10}
                        dataKey="value"
                      >
                      {dataAuthorization.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORSOU[codigoInterno] || '#000'/*COLORS[index % COLORS.length]*/} />
                      ))}
                      <LabelChart
                        value={`${proAuthorization}%`}
                        fontSize="11px"
                        position="center"
                      />
                    </Pie>
                  </PieChart>
                  }
                </CellContainer>,
              },
              {
                id: '7',
              //maxWidth:  width === 'none'? width : 200,
              Header: () => <TableHeader title="Avance Reclutamiento" center blue />,
              accessor: ({ centro:{ nombre },ou:{ codigoInterno }, circleBarRecruitment, dataRecruitment, proRecruitment }) => ({ nombre, codigoInterno, circleBarRecruitment, dataRecruitment, proRecruitment }),
              Cell:({ value: { nombre ,codigoInterno, circleBarRecruitment, dataRecruitment, proRecruitment }, index }) =>
                <CellContainer center index={index} >
                  { proRecruitment === 0 ?
                  <div style={{fontSize:"12px" ,color:"#000"}}><span >0%</span></div>
                  :
                  <PieChart width={180} height={35}>
                    <Pie
                        data={dataRecruitment}
                        cx={cx}
                        cy={12}
                        startAngle={0}
                        endAngle={circleBarRecruitment}
                        innerRadius={12}
                        outerRadius={17}
                        fill="#8884d8"
                        //labelLine={false}
                        //label={this.renderCustomizedLabel}
                        paddingAngle={10}
                        dataKey="value"
                      >
                      {dataRecruitment.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORSOU[codigoInterno] || '#000'/*COLORS[index % COLORS.length]*/} />
                      ))}
                      <LabelChart
                        value={`${proRecruitment}%`}
                        fontSize="11px"
                        position="center"
                      />
                    </Pie>
                  </PieChart>
                  }
                </CellContainer>,
              },
              // {
              //   id: '8',
              // //maxWidth:  width === 'none'? width : 200,
              // Header: () => <TableHeader title="Tipo" center blue />,
              // accessor: ({  node: { tipoSolicitud :{ nombre }  } }) => ({ nombre }),
              // Cell:({ value: { nombre },index }) => <CellContainer center index={index} >{nombre}</CellContainer>,
              // },
              {
              id: '9',
              maxWidth:  width === 'none'? width : 165,
              Header: () => <TableHeader title="Etapa" center blue />,
              accessor: ({ centro:{ nombre }, stage_current }) => ({ nombre, stage_current }),
              Cell:({ value: { nombre , stage_current}, index }) => <CellContainer center index={index} >{stage_current}</CellContainer>,
              },
            ]}
          />}
        </TableContainer>
      </Container>
    )
  }
}

Solicituds.defaultProps = {
  data: [],
  loading: false
}
const mapStateToProps = state => ({
  user: state.auth.user,
  loading: state.solicitud.get.fetching,
  data: state.solicitud.get.results.data,
  pagination: state.solicitud.get.results.pageInfo,
})

const mapDispatchToProps = dispatch => ({
  getAllSolicituds: (data = null) => dispatch(SolicitudActions.getAllSolicitudRequest(data)),
  goTo: path => dispatch(push(path)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Solicituds))
