import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Island = styled.div`
  width: 80%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  background-color: #ffdede;
  color:#F92135;
  border: 2px solid #F92135;
  border-radius: 4px;
`

const ErrorIsland = ({ error }) => (
  <Island>
    {error}
  </Island>
)

ErrorIsland.propTypes = {
  error: PropTypes.string,
}
ErrorIsland.defaultProps = {
  error: '',
}
export default ErrorIsland
