import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Island = styled.div`
  width: 80%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  background-color: #FFF5DE;
  color:#F5C000;
  border: 2px solid #F5C000;
  border-radius: 4px;
`

const WarningIsland = ({ warning }) => (
  <Island>
    {warning}
  </Island>
)

WarningIsland.propTypes = {
  warning: PropTypes.string,
}
WarningIsland.defaultProps = {
  warning: '',
}
export default WarningIsland
