import React, { Component } from 'react'
import { CardHeader, ContactCard, Modal,Card } from 'advenio-components'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import { path, isEmpty } from 'ramda'
import moment from 'moment'
import styled from 'styled-components'

import {
  PanelContainer,
  PanelHeader,
  PanelSection,
  ProfilePicture,
  ProfilePictureContent,
  PillsRow,
  Pills,
  InventorySection,
  InventoryRow,
  DivWithIcon,
  StyledSpan,
  DesktopContainer,
  MobileContainer,
  Container,
  CloudBar,
  CloudIcon,
} from './ProfilePanel.styles'
import StatusBar from './StatusBar'
import { userHasRole } from '../../utils/Utils'

const Icon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  cursor: pointer;
`
const PageContainer = styled(Card)`
  margin-left: 16px;
  margin-right: 8px;
`
const PageContainerMobile = styled.div`
  @media (max-width:767px) {
    background-color: white;
    width: 100%;
    margin:0;
  }
`
class ProfilePanel extends Component {

  formatHoursConsumed = (time = '') => {
    const [hours, minutes] = time.split(':')

    return `${hours}h ${minutes}m`
  }

  renderSections = (solicitud) => {
    const {
      tipoSolicitud
      ,usuario
      ,ou
      ,region
      ,centro
      ,area
      ,departamento
      ,prioridad
    }= solicitud.flujo.solicitud
      return (
        <div>
          <PanelSection top>
            <PanelHeader>INFORMACIÓN</PanelHeader>
            <InventoryRow>
              <DivWithIcon>
                {/* <Icon src="/assets/office.svg" alt="clock" /> */}
                <StyledSpan>
                Tipo de solicitud:
                </StyledSpan>
              </DivWithIcon>
              <StyledSpan right>
              {tipoSolicitud.nombre}
              </StyledSpan>
            </InventoryRow>
            <InventoryRow>
              <DivWithIcon>
                {/* <Icon src="/assets/office.svg" alt="clock" /> */}
                <StyledSpan>
                Solicitado por:
                </StyledSpan>
              </DivWithIcon>
              <StyledSpan right>
              {usuario.correo}
              </StyledSpan>
            </InventoryRow>
            <InventoryRow>
              <DivWithIcon>
                {/* <Icon src="/assets/office.svg" alt="clock" /> */}
                <StyledSpan>
                Unidad operativa:
                </StyledSpan>
              </DivWithIcon>
              <StyledSpan right>
                {ou.nombre}
              </StyledSpan>
            </InventoryRow>
            <InventoryRow>
              <DivWithIcon>
                {/* <Icon src="/assets/office.svg" alt="clock" /> */}
                <StyledSpan>
                Región:
                </StyledSpan>
              </DivWithIcon>
              <StyledSpan right>
                {region.nombre}
              </StyledSpan>
            </InventoryRow>
            <InventoryRow>
              <DivWithIcon>
                {/* <Icon src="/assets/office.svg" alt="clock" /> */}
                <StyledSpan>
                Centro educativo:
                </StyledSpan>
              </DivWithIcon>
              <StyledSpan right>
              {centro.nombre}
              </StyledSpan>
            </InventoryRow>
            <InventoryRow>
              <DivWithIcon>
                {/* <Icon src="/assets/office.svg" alt="clock" /> */}
                <StyledSpan>
                Área:
                </StyledSpan>
              </DivWithIcon>
              <StyledSpan right>
                {area.nombre}
              </StyledSpan>
            </InventoryRow>
            <InventoryRow>
              <DivWithIcon>
                {/* <Icon src="/assets/office.svg" alt="clock" /> */}
                <StyledSpan>
                Departamento:
                </StyledSpan>
              </DivWithIcon>
              <StyledSpan right>
                {departamento}
              </StyledSpan>
            </InventoryRow>
            <InventoryRow>
              <DivWithIcon>
                {/* <Icon src="/assets/office.svg" alt="clock" /> */}
                <StyledSpan>
                  Prioridad:
                </StyledSpan>
              </DivWithIcon>
              <StyledSpan right>
                {prioridad.nombre}
              </StyledSpan>
            </InventoryRow>
          </PanelSection>
        </div>
      )
  }


  render() {
    const { loadingData , solicitudStage} = this.props
    return (
      <Container>
        { isEmpty(solicitudStage) ?
          <PageContainer>
            <Modal isOpened loading />
          </PageContainer>
        :
          <DesktopContainer>
            <PanelContainer>
              <CardHeader>
                <h2 style={{ fontWeight: 400 }}>{solicitudStage.flujo.solicitud.nombrePuesto.nombre}</h2>
                {/* <PillsRow>
                  {infoPills.classroom && <Pills>{infoPills.classroom}</Pills>}
                  {infoPills.studyProgram && (
                    <Pills>
                      {infoPills.studyProgram}
                    </Pills>
                  )}
                </PillsRow> */}
              </CardHeader>
              <StatusBar
                isCloud={false}
                status={solicitudStage.status}
              />
              {this.renderSections(solicitudStage)}
            </PanelContainer>
          </DesktopContainer>
        }
      </Container>
    )
  }
}

ProfilePanel.propTypes = {
}

ProfilePanel.defaultProps = {
  loadingData:false,
  solicitudStage:{}
}

const mapStateToProps = ({ auth, ...state }) => ({
})

const mapDispatchToProps = dispatch => ({
  goTo: link => dispatch(push(link)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePanel)
