export const notificationSuccess = (title, subtitle = '') => (
  {
    type: 'NOTIFICATIONS_FIRE_NOTIFICATION',
    mode: 'success',
    title,
    subtitle,
  }
)

export const notificationFail = (title, subtitle = '') => (
  {
    type: 'NOTIFICATIONS_FIRE_NOTIFICATION',
    mode: 'error',
    title,
    subtitle,
  }
)
