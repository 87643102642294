import React, { Component } from 'react'
import { Route, Redirect, Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { equals } from 'ramda'
import PropTypes from 'prop-types'
import Layout from './Layout'
import Overview from './Overview'
import Solicitud from './Solicitud'
import StagesAuthorization from './StageAuthorize'
import StageAuthorize from './StageAuthorize/Authorize'
import FlowAuthorizationStages from './Catalogues/FlowAuthorization/FlowAuthorizationStages'
import Flow from './Catalogues/Flow/Flow'

/*-------------All Redux-----------------*/

// Build layout components for router
const SolicitudsRoute = ({match }) => <Layout match={match} title={'Solicitudes'} slot={<Overview />} />
// const SolicitudsRoute = ({match}) => <Layout match={match} title={'Solicitudes'} slot={<div>Solicitudes en proceso</div>} />
const SolicitudDetailsRoute = ({match }) => <Layout match={match} title={'Detalle de Solicitud'} slot={<Solicitud />} />
const SolicitudNewRoute = ({match }) => <Layout match={match} title={'Nueva Solicitud'} slot={<Solicitud />} />
const AuthorizationsRoute = ({match }) => <Layout match={match} title={'Autorizaciones'} slot={<StagesAuthorization/>} />
const AuthorizationSolicitudRoute = ({match }) => <Layout match={match} title={'Autrizacion de Etapa'} slot={<StageAuthorize/>} />
const FlowsAuthorizationStagesRoute = ({match }) => <Layout match={match} title={'Catalogo Flujos de Solicitudes'} slot={<FlowAuthorizationStages/>} />
const StagesRoute = ({match }) => <Layout match={match} title={'Catalogo de Etapas'} slot={<div>Catalogo de Etapas</div>} />
const StageDetailsRoute = ({match }) => <Layout match={match} title={'Detalle de Etapa'} slot={<div>Etapa detalle</div>} />
const FlowsRoute = ({match }) => <Layout match={match} title={'Catalogo de Flujos'} slot={<Flow/>} />
const FlowDetailsRoute = ({match }) => <Layout match={match} title={'Detalle de Flujo'} slot={<div>Flujo detalle</div>} />
const RequirementsRoute = ({match }) => <Layout match={match} title={'Catalogo de Requisitos'} slot={<div>Catalogo de requisitos</div>} />
const RequirementsDetailsRoute = ({match }) => <Layout match={match} title={'Detalle de Requisito'} slot={<div>Requisito Detalle</div>} />
const IndicatorsRoute = ({match }) => <Layout match={match} title={'Indicadores'} slot={<div>Indicadores</div>} />


class AppContainer extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    if (equals(this.props, nextProps) && equals(this.state, nextState)) return false

    return true
  }

  render() {
    const { user } = this.props

    return (
      <div>
      <Switch>
        <Route exact path="/" component={SolicitudsRoute} />
        <Route exact path="/solicituds" component={SolicitudsRoute} />
        <Route exact path="/solicitud/:solicitudId" component={SolicitudDetailsRoute} />
        <Route exact path="/solicitud/new" component={SolicitudNewRoute} />
        <Route exact path="/authorizations" component={AuthorizationsRoute} />
        <Route exact path="/authorizations/solicitudStage/:solicitudStageId" component={AuthorizationSolicitudRoute} />
        <Route exact path="/catalogues/flows" component={FlowsRoute} />
        <Route exact path="/catalogues/flows/:flowId" component={FlowDetailsRoute} />
        <Route exact path="/catalogues/stages" component={StagesRoute} />
        <Route exact path="/catalogues/stages/:stageId" component={StageDetailsRoute} />
        <Route exact path="/catalogues/flow-stages" component={FlowsAuthorizationStagesRoute} />
        <Route exact path="/catalogues/requirements" component={RequirementsRoute} />
        <Route exact path="/catalogues/requirements/:requirmentId" component={RequirementsDetailsRoute} />
        <Route exact path="/indicators" component={IndicatorsRoute} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      </div>
    )
  }
}

AppContainer.propTypes = {
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer))
