import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import queryString from 'query-string'
import { Table, TableCell, TableHeader, TableLoading, Link, TextIcon, RoundedTabs, Modal, Pagination,Colors, Title, Button as OriginalButton } from 'advenio-components'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer,Label as LabelChart } from 'recharts';
import { ROLES,COLORSOU, SOLICITUD_STAGES } from '../../Constants'
import {  getSolAvance } from '../../utils/Utils'

import SolicitudActions from '../../Data/Redux/SolicitudRedux'
import AuthorizationStatusActions from '../../Data/Redux/AuthorizationStatusRedux'
import ProfileContent from './ProfileContent'
import ProfilePanel from '../../Components/ProfilePanel/index'
import LoadingSpinner from '../../Components/LoadingSpinner'
import { isEmpty } from 'ramda'

const Container = styled.div`
  width: 100%;
  min-height: 80vh;
`
const Icon = styled.img`
  cursor: pointer;
  margin-right: 6px;
  margin-bottom: -2px;
  @media (max-width: 763px) {
    display:none;
  }
`
  const PageContainer = styled.div`
  min-height: 80vh;
  padding-top: 10px;
  padding-left: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width:767px) {
    width: 100%;
    padding: 0;
    flex-direction: column;
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const TableContainer = styled.div`
  width: 100%;
  padding: 16px 32px;
`

const Button = styled(OriginalButton).attrs({
  marginRight: '10px',
})`
  margin-left: auto;
`
const HeaderContainer = styled.div`
  width: auto;
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`

const PaginationContainer = styled.div`
  margin: 8px 0;
`
const CellContainer = styled.div`
margin: 0;
display: block;
max-width: 100%;
overflow: visible;
text-overflow: ellipsis;
white-space: nowrap;
word-wrap: normal;
font-size: 14px;
line-height: 20px;
padding: 14px;
color: #637381;
height: 100%;
text-align: center;
background-color: #FFFFFF;
white-space:  ${props=> props.solicitudName ? "nowrap" : 'initial'};
`
const InfoCell = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 12px;
`
const InfoRow = styled.div`
  display: flex;
  justify-content: flex-start;
`
const SolicitudTypeName = styled.span`
  margin-left: 4px;
  color: ${Colors.gray800}
`

const CellContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#FFFFFF;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`
const Status = styled.span`
  padding: 8px 16px;
  background-color: ${props=> COLORSOU[props.colorFill]|| null};
  border-radius: 20px;
  color:  ${props=> COLORSOU[props.colorFill] ? "#FFF" : '#000'};
  font-size: 14px;
`
const SolicitudName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.blue500};
  cursor: pointer;
`
class StageAuthorize extends Component {

  state={
  }
  componentWillMount() {
    const { match:{ params: { solicitudStageId }}, getAuthorizationStatus, getSolicitudStepById} = this.props

    getAuthorizationStatus()
    getSolicitudStepById({'id':solicitudStageId})
  }
  componentWillReceiveProps(nextProps) {
    const {
      solicitud
      ,getSolicitudComments
      ,getSolicitudDocuments
    }=  nextProps
    if (solicitud !== this.props.solicitud){
      if(solicitud.comments === undefined){
          getSolicitudComments({
          'flujo':solicitud.flujo.solicitud.id,
          'orden':solicitud.orden,
        })
      }
      if(solicitud.comments !== undefined && solicitud.documentos === undefined && solicitud.etapa.etapa.codigoInterno === SOLICITUD_STAGES.info_adl_nom){
        const stageSelectionCandidato = solicitud.comments.filter(stage => stage.codigoInterno === SOLICITUD_STAGES.sel_can) || []
        if(!isEmpty(stageSelectionCandidato)){
           getSolicitudDocuments({'etapaId':stageSelectionCandidato[0].id})
        }
       
      }
    }
  }
  componentWillUnmount(){
    this.props.resetSolicitud()
  }

  render() {
    const { loading, solicitud} =this.props
    if(isEmpty(solicitud)){
      <Modal isOpened loading />
    }
    return (
      <Container>
          <HeaderContainer>
            <Title style={{marginLeft:'12px'}} >Autorizar Etapa:  <span style={{fontSize:'25px', fontWeight:'initial'}}>{solicitud.nombre}</span></Title>
            <Button
              default
              onClick={() => this.props.goTo('/authorizations')}
            >
              <Icon src="/assets/back_arrow_icon.svg"/>
              Regresar
            </Button>
          </HeaderContainer>
          <PageContainer>
            <ProfilePanel
              // loadingData={loading}
              solicitudStage={solicitud}
            />
          {loading && isEmpty(solicitud) ?
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          :

              <ProfileContent
                solicitudStage={solicitud}
                loading={loading}
              />
          }
        </PageContainer>
      </Container>
    )
  }
}

StageAuthorize.defaultProps = {
  data:null,
  loading: false,
  solicitud:{}
}
const mapStateToProps = state => ({
  user: state.auth.user,
  // solicitudStageId: state.solicitud.solicitudSatgeId,
  solicitud: state.solicitud.getOne.result,
  loading: state.solicitud.getOne.fetching || state.solicitud.candidates.fetching
})

const mapDispatchToProps = dispatch => ({
  getAuthorizationStatus: () => dispatch(AuthorizationStatusActions.getAuthorizationStatusRequest()),
  getSolicitudStepById: (data) => dispatch(SolicitudActions.getSolicitudStepByIdRequest(data)),
  getSolicitudComments: (data) => dispatch(SolicitudActions.getSolicitudCommentsRequest(data)),
  getSolicitudDocuments: (data) => dispatch(SolicitudActions.getSolicitudDocumentsRequest(data)),
  resetSolicitud: () => dispatch(SolicitudActions.resetSolicitud()),
  goTo: path => dispatch(push(path)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StageAuthorize))
