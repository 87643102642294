import { createActions, createState, createReducer } from 'reduxsauce-crud'
import R from 'ramda'
import { isNull } from 'lodash'

const d = { deep: true }

export const SolicitudRedux = createActions(
  {
    setSolicitudStageId: ['id'],
    setSolicitudStageData: ['data'],
    setSolicitudCandidatesData: ['data'],
    setSolicitudFilesData: ['data'],
    resetUpdateSolicitud: [''],
    resetSolicitud: [''],
    getAllSolicitudRequest: ['data'],
    getAllSolicitudSuccess: ['data'],
    getAllSolicitudError: ['err'],
    getSolicitudByIdRequest: ['data'],
    getSolicitudByIdSuccess: ['data'],
    getSolicitudByIdError: ['err'],
    postSolicitudRequest: ['data'],
    postSolicitudSuccess: [],
    postSolicitudError: ['err'],
    getSolicitudStepByIdRequest: ['data'],
    getSolicitudStepByIdSuccess: ['data'],
    getSolicitudStepByIdError: ['err'],
    validateSolicitudRequest: ['data'],
    validateSolicitudSuccess: ['data'],
    validateSolicitudError: ['err'],
    updateSolicitudRequest: ['data'],
    updateSolicitudSuccess: ['data'],
    updateSolicitudError: ['err'],
    updateSolicitudStatusRequest: ['data'],
    updateSolicitudStatusSuccess: ['data'],
    updateSolicitudStatusError: ['err'],
    updateSolicitudStageRequest: ['data'],
    updateSolicitudStageSuccess: ['data'],
    updateSolicitudStageError: ['err'],
    getSolicitudCommentsRequest: ['data'],
    getSolicitudCommentsSuccess: ['data'],
    getSolicitudCommentsError: ['err'],
    getSolicitudDocumentsRequest: ['data'],
    getSolicitudDocumentsSuccess: ['data'],
    getSolicitudDocumentsError: ['err'],
    addCandidatesSolicitudRequest: ['data'],
    addCandidatesSolicitudSuccess: [''],
    addCandidatesSolicitudError: ['err'],
    addFileSolicitudRequest: ['data'],
    addFileSolicitudSuccess: [''],
    addFileSolicitudError: ['err'],
    // updateExpense: ['data']
  },
  {
    prefix: 'SOLICITUD_',
    defaultActions: {
      get: true,
    },
  },
)

const { Types, Creators } = SolicitudRedux
export const SolicitudTypes = Types
export default Creators

export const INITIAL_STATE = createState({
  setSolicitudStageId:null,
  solicitudStageData:{},
  solicitudCandidatesData:[],
  solicitudFilesData:[],
  create:{
    fetching: false,
    error: null
  },
  updated:{
    fetching:false,
    error:null,
    result:{}
  },
  candidates:{
    fetching:false,
    error:null,
  },
  getOne:{
    fetching:false,
    error:null,
    result:{},
    id:null
  },
  validSolicitud:{
    fetching: false,
    error:null,
    result: {}
  }
}, {
  get: true,
})

const setSolicitudStageId = (state, { id }) => state.merge({ solicitudSatgeId: id }, d)
const setSolicitudStageData = (state, { data }) => state.merge({ solicitudStageData: data }, d)
const setSolicitudCandidatesData = (state, { data }) => state.merge({ solicitudCandidatesData: data }, d)
const setSolicitudFilesData = (state, { data }) => state.merge({ solicitudFilesData: data }, d)
const resetSolicitud = state =>  state.merge({ getOne:{
  fetching:false,
  error:null,
  result:[],
  id:null
} }, d)
const resetUpdateSolicitud = state =>
  state.merge({
    validSolicitud: { fetching: false, error: null, result: null },
    solicitudStageData: null,
    updated: { fetching: false, error: null, result:null },
  }, d)

const getAllSolicitudRequest = state =>
  state.merge({ get: { fetching: true, error: null } }, d)

const getAllSolicitudError = (state, { err }) =>
state.merge({ get: { fetching: false, error: err } }, d)

const getAllSolicitudSuccess = (state, data) => {
  return state.merge({ get: { fetching: false, results: data.data } }, d)
}
const  getSolicitudStepByIdRequest = state =>
  state.merge({ getOne: { fetching: true, error: null } }, d)

const getSolicitudStepByIdError = (state, { err }) =>
state.merge({ getOne: { fetching: false, error: err } }, d)

const  getSolicitudStepByIdSuccess = (state, data) => {
  return state.merge({ getOne: { fetching: false, result: data.data, id:data.data.id } }, d)
}
const  getSolicitudByIdRequest = state =>
  state.merge({ getOne: { fetching: true, error: null } }, d)

const getSolicitudByIdError = (state, { err }) =>
state.merge({ getOne: { fetching: false, error: err } }, d)

const  getSolicitudByIdSuccess = (state, data) => {
  return state.merge({ getOne: { fetching: false, result: data.data, id:data.data.id } }, d)
}
const  updateSolicitudRequest = state =>
  state.merge({ updated:{ fetching: true, error: null } }, d)

const updateSolicitudError = (state, { err }) =>
state.merge({ updated: { fetching: false, error: err } }, d)

const  updateSolicitudSuccess = (state, data) => {
  return state.merge({ updated: { fetching: false, result: data.data.solicitud }, getOne: { result:data.data.solicitud} }, d)
}
const  updateSolicitudStatusRequest = state =>
  state.merge({ updated:{ fetching: true, error: null } }, d)

const updateSolicitudStatusError = (state, { err }) =>
state.merge({ updated: { fetching: false, error: err } }, d)

const  updateSolicitudStatusSuccess = (state, data) => {
  return state.merge({ updated: { fetching: false, result: data.data } }, d)
}
const  updateSolicitudStageRequest = state =>
  state.merge({ getOne:{ fetching: true, error: null } }, d)

const updateSolicitudStageError = (state, { err }) =>
state.merge({ getOne: { fetching: false, error: err } }, d)

const  updateSolicitudStageSuccess = (state, data) => {
  return state.merge({ getOne: { fetching: false, result: data.data,  id:data.data.id } }, d)
}
const  validateSolicitudRequest = state =>
  state.merge({ validSolicitud: { fetching: true, error: null } }, d)

const validateSolicitudError = (state, { err }) =>
state.merge({ validSolicitud: { fetching: false, error: err } }, d)

const  validateSolicitudSuccess = (state, data) => {
  return state.merge({ validSolicitud: { fetching: false, result: data.data, id:data.data.id } }, d)
}
const  getSolicitudCommentsRequest = state =>
  state.merge({ getOne: { fetching: true, error: null } }, d)

const getSolicitudCommentsError = (state, { err }) =>
state.merge({ getOne: { fetching: false, error: err } }, d)

const  getSolicitudCommentsSuccess = (state, data) => {
  return state.merge({ getOne: { fetching: false, result: data.data } }, d)
}
const  getSolicitudDocumentsRequest = state =>
  state.merge({ getOne: { fetching: true, error: null } }, d)

const getSolicitudDocumentsError = (state, { err }) =>
state.merge({ getOne: { fetching: false, error: err } }, d)

const  getSolicitudDocumentsSuccess = (state, data) => {
  return state.merge({ getOne: { fetching: false, result: data.data } }, d)
}
const postSolicitudRequest = state =>
  state.merge({ create: { fetching: true, error: null } }, d)

const postSolicitudError = (state, { err }) =>
  state.merge({ create: { fetching: false, error: err } }, d)

const postSolicitudSuccess = (state) => {
  return state.merge({ create: { fetching: false, error: null } }, d)
}
const addCandidatesSolicitudRequest = state =>
  state.merge({ candidates: { fetching: true, error: null } }, d)

const addCandidatesSolicitudError = (state, { err }) =>
  state.merge({ candidates: { fetching: false, error: err } }, d)

const addCandidatesSolicitudSuccess = (state) => {
  return state.merge({ candidates: { fetching: false, error: null } }, d)
}
const addFileSolicitudRequest = state =>
  state.merge({ candidates: { fetching: true, error: null } }, d)

const addFileSolicitudError = (state, { err }) =>
  state.merge({ candidates: { fetching: false, error: err } }, d)

const addFileSolicitudSuccess = (state) => {
  return state.merge({ candidates: { fetching: false, error: null } }, d)
}

// const updateExpense = (state, { data }) => {
//   const newSolicitud = R.clone(state.get.results.data)
//   const activeExpenseIndex = R.findIndex(R.propEq('id', data.data.id))(newSolicitud)
//   newSolicitud[activeExpenseIndex] = data.data
//   return state.merge({ get: { fetching: false, results: { data: newSolicitud } } }, d)
// }


export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.setSolicitudStageId]: setSolicitudStageId,
    [Types.setSolicitudStageData]: setSolicitudStageData,
    [Types.setSolicitudFilesData]: setSolicitudFilesData,
    [Types.setSolicitudCandidatesData]: setSolicitudCandidatesData,
    [Types.resetUpdateSolicitud]: resetUpdateSolicitud,
    [Types.resetSolicitud]: resetSolicitud,
    [Types.getAllSolicitudRequest]: getAllSolicitudRequest,
    [Types.getAllSolicitudSuccess]: getAllSolicitudSuccess,
    [Types.getAllSolicitudError]: getAllSolicitudError,
    [Types.postSolicitudRequest]: postSolicitudRequest,
    [Types.postSolicitudSuccess]: postSolicitudSuccess,
    [Types.postSolicitudError]: postSolicitudError,
    [Types.getSolicitudStepByIdRequest]: getSolicitudStepByIdRequest,
    [Types.getSolicitudStepByIdSuccess]: getSolicitudStepByIdSuccess,
    [Types.getSolicitudStepByIdError]: getSolicitudStepByIdError,
    [Types.getSolicitudByIdRequest]: getSolicitudByIdRequest,
    [Types.getSolicitudByIdSuccess]: getSolicitudByIdSuccess,
    [Types.getSolicitudByIdError]: getSolicitudByIdError,
    [Types.validateSolicitudRequest]: validateSolicitudRequest,
    [Types.validateSolicitudSuccess]: validateSolicitudSuccess,
    [Types.validateSolicitudError]: validateSolicitudError,
    [Types.updateSolicitudRequest]: updateSolicitudRequest,
    [Types.updateSolicitudSuccess]: updateSolicitudSuccess,
    [Types.updateSolicitudError]: updateSolicitudError,
    [Types.addCandidatesSolicitudRequest]: addCandidatesSolicitudRequest,
    [Types.addCandidatesSolicitudSuccess]: addCandidatesSolicitudSuccess,
    [Types.addCandidatesSolicitudError]: addCandidatesSolicitudError,
    [Types.addFileSolicitudRequest]: addFileSolicitudRequest,
    [Types.addFileSolicitudSuccess]: addFileSolicitudSuccess,
    [Types.addFileSolicitudError]: addFileSolicitudError,
    [Types.updateSolicitudStatusRequest]: updateSolicitudStatusRequest,
    [Types.updateSolicitudStatusSuccess]: updateSolicitudStatusSuccess,
    [Types.updateSolicitudStatusError]: updateSolicitudStatusError,
    [Types.updateSolicitudStageRequest]: updateSolicitudStageRequest,
    [Types.updateSolicitudStageSuccess]: updateSolicitudStageSuccess,
    [Types.updateSolicitudStageError]: updateSolicitudStageError,
    [Types.getSolicitudCommentsRequest]: getSolicitudCommentsRequest,
    [Types.getSolicitudCommentsSuccess]: getSolicitudCommentsSuccess,
    [Types.getSolicitudCommentsError]: getSolicitudCommentsError,
    [Types.getSolicitudDocumentsRequest]: getSolicitudDocumentsRequest,
    [Types.getSolicitudDocumentsSuccess]: getSolicitudDocumentsSuccess,
    [Types.getSolicitudDocumentsError]: getSolicitudDocumentsError,
    // [Types.updateExpense]: updateExpense,
  },
  {
    defaultActions: {
      get: true,
    },
    Types,
  },
)
