import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, RadioGroup } from 'advenio-components'
import { isEmpty } from 'ramda'
import { STATUS, TYPE_SOLICITUD} from '../../../Constants'
import { PanelHeader } from '../../ProfilePanel/ProfilePanel.styles'
import { stringContainsAnyOf } from '../../../utils/Utils'
import moment from 'moment'
import { notificationFail, notificationSuccess } from '../../../Data/Redux/NotificationActions'
import AuthorizationStatusActions from '../../../Data/Redux/AuthorizationStatusRedux'
import SolicitudActions from '../../../Data/Redux/SolicitudRedux'

const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 120px !important;
  padding-right: 120px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
`
const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin-top: 4%;
`
const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const Label = styled.p`
  text-align: left;
  color: #212B36;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0px;
  margin-bottom: 2px;
`
const Checkbox = ({ label, name, value }) => (
  <OriginalCheckbox
    invertedColors
    large
    hideEmptyHelpBlock
    label={label}
    name={name}
    value={value}
  />
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}

class SelectionComputer extends Component {
  state={
    flawSend:false,
    dataSend:{},
    typeLine:null,
    computerSpecial:null,
    typeComputer:null
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const {
      validSolicitud,
      messageFail,
      updatedSolicitud,
      user,
      setSolicitudStageData
    } = nextProps
    const { flawSend, dataSend } = this.state

    if(flawSend && ! isEmpty(dataSend) && !isEmpty(validSolicitud)){
      if(validSolicitud.status.codigoInterno === STATUS.authorized || validSolicitud.status.codigoInterno === STATUS.rejected){
        messageFail('No se puede cambiar el estatus de una etapa previamente autorizada/rechazada')
      }else{
        let dataSolicitud = {
          'data':{
            ...dataSend,
            'id': validSolicitud.flujo.solicitud.id
          },
          'paramsExtra':{
            'nextStatus':dataSend.statusId
          }
        }


        delete dataSolicitud.data.comentarios
        delete dataSolicitud.data.statusId
        delete dataSend.equipoEspecial
        delete dataSend.tipoComputadora
        delete dataSend.tipoLinea

        dataSend.usuarioId = user.id
        setSolicitudStageData(dataSend)
        updatedSolicitud(dataSolicitud)
      }
    }
  }

  changeRadio = (radioSelected, type) =>{
    if(type === 'typeComputer'){
      this.setState({typeComputer:radioSelected})
    }
    if(type === 'computerSpecial'){
      this.setState({computerSpecial:radioSelected})
    }
    if(type === 'typeLine'){
      this.setState({typeLine:radioSelected})
    }
  }
  onSubmit= (data) =>{
    const {  match:{ params: { solicitudStageId }}, validateSolitictud, info } = this.props
    const {
      typeLine,
      computerSpecial,
      typeComputer,
     } = this.state
    const dataSend = {
      'statusId': data.statusId,
      'comentarios': info.comentarios === null ? `${data.comentarios}. Tipo de computadora: ${typeComputer}. Equipo especial ${computerSpecial}. Tipo de linea ${typeLine}.` : data.comentarios,
      'id':solicitudStageId,
      'tipoComputadora': typeComputer || info.tipoComputadora,
      'equipoEspecial': computerSpecial || info.equipoEspecial,
      'tipoLinea': typeLine || info.tipoLinea,
    }

    this.setState({dataSend, flawSend:true})
    if(data.statusId !== 0){
      validateSolitictud({
        'id':solicitudStageId
      })
    }
  }

  renderOptions = () => [{id:0,label:"Seleccionar",value:0}].concat(this.props.status.map(s=>({
    id:s.id
    , label:s.nombre
    , value: s.id
  })))

  render() {
    const {
      info
    } = this.props
    return (
      <Container>
        <PanelHeader tabTitle >Captura de Datos</PanelHeader>
        <Form
          ref={(form) => { this.form = form }}
          onSubmit={this.onSubmit}
        >
          <Grid>
            <Row>
              <Col sm={12} md={4} lg={4}>
                <div style={{height:"227px", marginLeft:'20px'}}>

                <Label>Tipo de computadora *</Label>
                <RadioGroup
                  name="typeComputer"
                  vertical
                  radios={[
                    {
                      "name": "Computadora-Escritorio",
                      "disabled": false,
                      "value": info.tipoComputadora === 'Computadora-Escritorio' ? true :false ,
                      "label": "Computadora de escritorio"
                    },{
                      "name": "Laptop-Adminsitrativo",
                      "disabled": false,
                      "value": info.tipoComputadora === 'Laptop-Adminsitrativo' ? true :false ,
                      "label": "Laptop administrativa"
                    },{
                      "name": "laptop-Directiva",
                      "disabled": false,
                      "value": info.tipoComputadora === 'laptop-Directiva' ? true :false ,
                      "label": "Laptop directiva"
                    }
                    ,{
                      "name": "Laptop-CTO",
                      "disabled": false,
                      "value": info.tipoComputadora === 'Laptop-CTO' ? true :false ,
                      "label": "Laptop CTO"
                    }
                    ,{
                      "name": "Laptop-Reemplazo",
                      "disabled": false,
                      "value": info.tipoComputadora === 'Laptop-Reemplazo' ? true :false ,
                      "label": "Laptop reemplazo"
                    }
                    ,{
                      "name": "No-Aplica",
                      "disabled": false,
                      "value": info.tipoComputadora === 'No-Aplica' ? true :false ,
                      "label": "No aplica"
                    }
                  ]}
                  onClick={(value)=>{this.changeRadio(value, 'typeComputer')}}
                  required
                  value={info.status.id || null}
                  // options={this.renderOptions()}
                  // fullWidth
                />
                </div>
              </Col>
              <Col sm={12} md={4} lg={4}>
                <div style={{ height:"227px", marginLeft:'20px' }}>

                <Label>Equipo especial *</Label>
                <RadioGroup
                  name="computerSpecial"
                  vertical
                  radios={[
                    {
                      "name": "HP-EB-360",
                      "disabled": false,
                      "value": info.equipoEspecial === 'HP-EB-360' ? true :false,
                      "label": "Hp Elite Book 360"
                    },{
                      "name": "Macbook-Air",
                      "disabled": false,
                      "value": info.equipoEspecial === 'Macbook-Air' ? true :false,
                      "label": "Macbook Air"
                    }
                    ,{
                      "name": "Macbook-Pro",
                      "disabled": false,
                      "value": info.equipoEspecial === 'Macbook-Pro' ? true :false,
                      "label": "Macbook Pro"
                    }
                    ,{
                      "name": "No-Aplica",
                      "disabled": false,
                      "value": info.equipoEspecial === 'No-Aplica' ? true :false,
                      "label": "No aplica"
                    }
                  ]}
                  onClick={(value)=>{this.changeRadio(value, 'computerSpecial')}}
                  required
                  value={info.status.id || null}
                  // options={this.renderOptions()}
                  // fullWidth
                />
                </div>
              </Col>
              <Col sm={12} md={4} lg={4}>
                <div style={{ height:"227px", marginLeft:'20px' }}>

                <Label>Tipo de liena *</Label>
                <RadioGroup
                  name="typeLine"
                  vertical
                  radios={[
                    {
                      "name": "Directivo",
                      "disabled": false,
                      "value":  info.tipoLinea === 'Directivo' ? true : false,
                      "label": "Directivo"
                    },{
                      "name": "Administrativo",
                      "disabled": false,
                      "value":  info.tipoLinea === 'Administrativo' ? true : false,
                      "label": "Administrativo"
                    },{
                      "name": "No-Necesario",
                      "disabled": false,
                      "value":  info.tipoLinea === 'No-Necesario' ? true : false,
                      "label": "No es necesario"
                    }
                    ,{
                      "name": "Liena-De-Remplazo",
                      "disabled": false,
                      "value":  info.tipoLinea === 'Liena-De-Remplazo' ? true : false,
                      "label": "Linea de Remplazo"
                    }
                  ]}
                  onClick={(value)=>{this.changeRadio(value, 'typeLine')}}
                  required
                  value={info.status.id || null}
                  // options={this.renderOptions()}
                  // fullWidth
                />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <TextInput
                  name="comentarios"
                  label="Comentarios "
                  value={info.comentarios || null}
                  textarea
                  required
                  />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Select
                  name="statusId"
                  label="Autorización"
                  required
                  value={info.status.id || null}
                  options={this.renderOptions()}
                  fullWidth
                />
              </Col>
            </Row>
            <Row end="xs">
              <Col sm={12} md={12} lg={12}>
                <Button primary disabled={!! stringContainsAnyOf(info.status.codigoInterno,[STATUS.authorized, STATUS.info_completed ])} style={{marginBottom:12, float:"right"}} loading={false} type="submit" >
                  {/* <TextIcon label="Subir archivos" iconType="plus" iconViewBox="-2 -2 24 24" /> */}
                  Guardar
                </Button>
              </Col>
            </Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

SelectionComputer.propTypes = {
}

SelectionComputer.defaultProps = {

}

const mapStateToProps = state => ({
  user: state.auth.user,
  status: state.authorizationStatus.get.results,
  validSolicitud: state.solicitud.validSolicitud.result,
})

const mapDispatchToProps = dispatch => ({
  validateSolitictud: (data) => dispatch(SolicitudActions.validateSolicitudRequest(data)),
  updatedSolicitud: (data) => dispatch(SolicitudActions.updateSolicitudRequest(data)),
  messageFail: msg => dispatch(notificationFail(msg)),
  setSolicitudStageData: data => dispatch(SolicitudActions.setSolicitudStageData(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectionComputer))
