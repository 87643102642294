import { gql } from '@apollo/client';

const getAllOU = gql`
query
  allCatOus{
    allCatOus (filters:{not:{status:0}}){
      edges{
        node
        {
          id
          nombre
          codigoInterno
          status
          deleted
          area{
            edges{
              node{
                id
                nombre
                descripcion
                codigoInterno
                status
                deleted
              }
            }
          }
        }
      }
    }
  }
`
export{
  getAllOU
}
