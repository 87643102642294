import { createActions, createState, createReducer } from 'reduxsauce-crud'
import R from 'ramda'

const d = { deep: true }

export const SendNotificationRedux = createActions(
  {
    postSendNotificationRequest: ['data'],
    postSendNotificationSuccess: ['data'],
    postSendNotificationError: ['err'],
    // updateExpense: ['data']
  },
  {
    prefix: 'SEND_NOTIFICATION_',
    defaultActions: {
      get: true,
    },
  },
)

const { Types, Creators } = SendNotificationRedux
export const SendNotificationTypes = Types
export default Creators

export const INITIAL_STATE = createState({}, {
  get: true,
})

const postSendNotificationRequest = state =>
  state.merge({ get: { fetching: true, error: null } }, d)

const postSendNotificationError = (state, { err }) =>
  state.merge({ get: { fetching: false, error: err } }, d)

const postSendNotificationSuccess = (state, data) => {
  return state.merge({ get: { fetching: false, results: data.data } }, d)
}

// const updateExpense = (state, { data }) => {
//   const newSolicitud = R.clone(state.get.results.data)
//   const activeExpenseIndex = R.findIndex(R.propEq('id', data.data.id))(newSolicitud)
//   newSolicitud[activeExpenseIndex] = data.data
//   return state.merge({ get: { fetching: false, results: { data: newSolicitud } } }, d)
// }


export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.postSendNotificationRequest]: postSendNotificationRequest,
    [Types.postSendNotificationSuccess]: postSendNotificationSuccess,
    [Types.postSendNotificationError]: postSendNotificationError,
    // [Types.updateExpense]: updateExpense,
  },
  {
    defaultActions: {
      get: true,
    },
    Types,
  },
)
