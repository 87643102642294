import { gql } from '@apollo/client';

const catalguesAttr = {
  '$statusIdIn': `[Int!]`,
  '$status': 'Int',
  '$deleted': 'Int',
  '$after': 'String',
  '$first': 'Int',
  '$before': 'String',
  '$last': 'Int'
}

const  getAllUsersByRole = gql`
query
allUsuarios($rolUsuario:String) {
  allUsuarios(filters: {rolUsuario:$rolUsuario}) {
    edges {
      node {
        id
        nombre
        correo
      }
    }
  }
}
`
const getAllCatDeptosRh = `
query
allCatDeptosRh $params {
  allCatDeptosRh
    $filters
  {
    edges{
      node{
        id
        nombre
        codigoInterno
        status
        deleted
      }
    }
  }
}`

const getAllCatAuthorizationTypes = `
query
allCatTiposAutorizacion  $params {
  allCatTiposAutorizacion
    $filters
  {
    edges{
      node{
        id
        nombre
        descripcion
        codigoInterno
        division
        status
        deleted
        portalId
        autorizacionTipo {
          autorizacionSpec {
            edges {
              node {
                id
                nombre
                deleted
                status
              }
            }
          }
        }
      }
    }
  }
}`

const getAllCatStages = `
query
allCatEtapas $params {
  allCatEtapas
    $filters
  {
    edges{
      node{
        id
        nombre
        codigoInterno
        color
        status
        deleted
      }
    }
  }
}`

export {
  catalguesAttr,
  getAllCatDeptosRh,
  getAllCatStages,
  getAllCatAuthorizationTypes,
}
