import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, Icon, Colors } from 'advenio-components'
import { isEmpty, findIndex ,propEq, remove, mapObjIndexed, clone } from 'ramda'
import { STATUS } from '../../../Constants'
import { PanelHeader } from '../../ProfilePanel/ProfilePanel.styles'
import { parseDataGraphQL, slugify } from '../../../utils/Utils'
import moment from 'moment'
import { notificationFail, notificationSuccess } from '../../../Data/Redux/NotificationActions'
import AuthorizationStatusActions from '../../../Data/Redux/AuthorizationStatusRedux'
import SolicitudActions from '../../../Data/Redux/SolicitudRedux'

const labelContainerConfig = labelType => `
  ${labelType === 'right' ?
    `position: absolute;
     left: 23px;` : ''}
`

const Label = styled.label`
font-size: 14px;
margin-bottom: 2px;
margin-right: 4px;
display:block;
${props => labelContainerConfig(props.labelType)}
`

const ButtonRemove =styled(Icon)`
  display: inline-block;
  background-color: ${props => props.type === 'close'? Colors.red500: Colors.green500 };
  border-radius: 5px;
  width: 40px;
`
const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 120px !important;
  padding-right: 120px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
`
const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin-top: 4%;
`
const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const Checkbox = ({ label, name, value }) => (
  <OriginalCheckbox
    invertedColors
    large
    hideEmptyHelpBlock
    label={label}
    name={name}
    value={value}
  />
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}
const modelCandidate = {
  nombre:null,
  apellidoPaterno: null,
  apellidoMaterno:null,
  correoElectronico:null,
}
class SelectionTerna extends Component {
  state={
    flawSend:false,
    dataSend:{},
    candidates: [modelCandidate],
    // files:[]
  }


  componentWillMount() {
    const { info } = this.props
    if(info.candidatos.edges.length > 0 ){
      let candidates =  clone(info.candidatos.edges)
      this.setState({
        candidates: parseDataGraphQL(candidates)
      })

    }
  }


  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const {
      validSolicitud,
      messageFail,
      updatedSolicitud,
      user,
      setSolicitudStageData,
      setSolicitudCandidatesData,
      getNextStatus
    } = nextProps
    const { flawSend, dataSend, candidates } = this.state

    if(flawSend && ! isEmpty(dataSend) && !isEmpty(validSolicitud)){
      if(validSolicitud.status.codigoInterno === STATUS.authorized || validSolicitud.status.codigoInterno === STATUS.rejected){
        messageFail('No se puede cambiar el estatus de una etapa previamente autorizada/rechazada')
      }else{
        let dataNextStatus = {
          'id':dataSend.statusId
        }
        let dataSolicitudStage= {
          'id':dataSend.id,
          'statusId':dataSend.statusId,
          'comentarios':dataSend.comentarios,
          'usuarioId': user.id
        }
        let candidates = dataSend.candidatos
        candidates = candidates.map(c=> ({
          ...c,
          solicitudId:validSolicitud.flujo.solicitud.id,
        }))
        setSolicitudStageData(dataSolicitudStage)
        setSolicitudCandidatesData(candidates)
        getNextStatus(dataNextStatus)
      }
    }
  }
  addCandidate = () => {
    const { candidates } =this.state
    // candidates.push({
    //   nombre: candidates.length === 1 ? candidates.length + 1 : last(candidates).nombre + 1,
    //   apellidoPaterno:candidates.length === 1 ? candidates.length + 1 : last(candidates).nombre + 1,
    //   apellidoMaterno:candidates.length === 1 ? candidates.length + 1 : last(candidates).nombre + 1,
    //   corroeElectronico:candidates.length === 1 ? candidates.length + 1 : last(candidates).nombre + 1,
    // })
    candidates.push(modelCandidate)
    this.setState({ candidates })
  }

  removeCandidate = (index) => {
    let { candidates, files } =this.state
    candidates = remove(index, 1, candidates)
    files = remove(index, 1, files)

    this.setState({ candidates })
  }

  onSubmit= (data) =>{
    const {  match:{ params: { solicitudStageId }}, validateSolitictud, info } = this.props
    const dataSend = {
      ...data,
      'id':solicitudStageId,
    }
    this.setState({dataSend, flawSend:true})
    if(data.statusId !== 0){
      validateSolitictud({
        'id':solicitudStageId
      })
    }
  }

  renderOptions = () => [{id:0,label:"Seleccionar",value:0}].concat(this.props.status.map(s=>({
    id:s.id
    , label:s.nombre
    , value: s.id
  })))

  onchangeFiles =(e, index) =>{

    const file = e.target.files[0]
    const contentTtype = file.type
    const exp = new RegExp(/.\w+[A-Za-z]$/)
   const [extension] = file.name.match(exp)

    const file_name = slugify(file.name)
    const reader = new FileReader()
    reader.onload = (e) => {
      const file_data = e.target.result.replace(`data:${contentTtype};base64,`, '')
      let state = this.state
      state.files.push({
          'fileName':file_name,
          'archivo':file_data,
        })
      this.setState(state)
    }
    reader.readAsDataURL(file)

    // this.setState({disabledFiles:false})
  }

  render() {
    const {
      info
    } = this.props
    const {
      candidates
    } = this.state
    return (
      <Container>
        <PanelHeader tabTitle >Captura de Datos</PanelHeader>
        <Form
          ref={(form) => { this.form = form }}
          onSubmit={this.onSubmit}
        >
          <Grid>
          {/* <Row end="xs">
              <Col sm={12} md={12}>
                <Button primary style={{marginBottom:12}} onClick={this.addCandidate} loading={false} >
                  <TextIcon label="Agregar" iconType="plus" iconViewBox="-2 -2 24 24" />
                </Button>
              </Col>
            </Row> */}
            {candidates.map((c,index) => (
              <Row key={index}>
                <Col sm={12} md={3} lg={3}>
                  <TextInput
                    name={`candidatos[${index}].nombre`}
                    label={`Nombre ${index + 1}`}
                    value={c.nombre || null}
                    required
                  />
                </Col>
                <Col sm={12} md={3} lg={3}>
                  <TextInput
                    name={`candidatos[${index}].apellidoPaterno`}
                    label={`Apellido Paterno ${index + 1}`}
                    value={c.apellidoPaterno || null}
                    required
                  />
                </Col>
                <Col sm={12} md={3} lg={3}>
                  <TextInput
                    name={`candidatos[${index}].apellidoMaterno`}
                    label={`Apellido Materno ${index + 1}`}
                    value={c.apellidoMaterno || null}
                    required
                  />
                </Col>
                <Col sm={12} md={3} lg={3}>
                  <Row style={{ marginTop:0 }}>
                    <Col sm={12} md={10} lg={10}>
                      <TextInput
                        name={`candidatos[${index}].correoElectronico`}
                        label={`Correo ${index + 1}`}
                        value={c.correoElectronico || null}
                        required
                        />
                    </Col>
                    {/* <Col sm={12} md={4}lg={4} style={{marginTop:'-30px'}} key={index}>
                      <Label
                      htmlFor={`${index}`}
                      labelType={'left'}
                      >
                        Curriculum *
                      </Label>
                      <input id={`${index}`} disabled={c.curriculum || false} type="file" onChange={e => this.onchangeFiles(e, index)} accept="*"></input>
                    </Col> */}
                    <Col sm={12} md={2} lg={2}>
                      {index > 0 && (
                        <ButtonRemove type="close" disabled={c.correoElectronico || false} onClick={() => {this.removeCandidate(index)}} color="white" />
                      )}
                      {index === 0 && (
                        <ButtonRemove type="plus" onClick={() => {this.addCandidate()}} color="white" />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
              <Row>
              <Col sm={12} md={6} lg={6}>
                <TextInput
                  name="comentarios"
                  label="Comentarios"
                  value={info.comentarios || null}
                  textarea
                  required
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Select
                  name="statusId"
                  label="Autorización"
                  required
                  value={info.status.id || null}
                  options={this.renderOptions()}
                  fullWidth
                />
              </Col>
            </Row>
            <Row end="xs">
              <Col sm={12} md={12} lg={12}>
                <Button primary disabled={info.status.codigoInterno === STATUS.authorized } style={{marginBottom:12}} loading={false} type="submit" >
                  {/* <TextIcon label="Subir archivos" iconType="plus" iconViewBox="-2 -2 24 24" /> */}
                  Guardar
                </Button>
              </Col>
            </Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

SelectionTerna.propTypes = {
}

SelectionTerna.defaultProps = {

}

const mapStateToProps = state => ({
  user: state.auth.user,
  status: state.authorizationStatus.get.results,
  validSolicitud: state.solicitud.validSolicitud.result,
})

const mapDispatchToProps = dispatch => ({
  validateSolitictud: (data) => dispatch(SolicitudActions.validateSolicitudRequest(data)),
  updatedSolicitud: (data) => dispatch(SolicitudActions.updateSolicitudRequest(data)),
  getNextStatus: (data) => dispatch(AuthorizationStatusActions.getNextStatusRequest(data)),
  messageFail: msg => dispatch(notificationFail(msg)),
  setSolicitudStageData: data => dispatch(SolicitudActions.setSolicitudStageData(data)),
  setSolicitudCandidatesData: data => dispatch(SolicitudActions.setSolicitudCandidatesData(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectionTerna))
