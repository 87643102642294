import React, { Component} from "react"
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import styled from 'styled-components'
import { Button } from 'advenio-components'
import { GOOGLE_CLIENT_ID } from "../../config";

const Icon = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
    @media (max-width: 763px) {
      width: 15px;
      height: 15px;
    }
`
const responseFailure = (response) => {
  console.log(response);
}
const logout = (response) => {
  console.log(response);
}

class ButtonGoogleAuth extends Component {

  responseSuccess = (response) => {
    this.props.LoginApi({'user':response.profileObj.email,'typeAuth':'Google','token':response.accessToken})
  }

  render(){
    return (
      <div>
        <GoogleLogin
           clientId={GOOGLE_CLIENT_ID}
           render={renderProps => (
            <Button onClick={renderProps.onClick} transparent addShadow large fullWidth loading={this.props.loading} >
              <Icon src="assets/google.svg"/>
              Login Google
            </Button>
              // <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
            )}
           onSuccess={this.responseSuccess}
           onFailure={responseFailure}
           cookiePolicy={'single_host_origin'}
         />
         {/* <GoogleLogout buttonText="Logout" onLogoutSuccess={logout} /> */}
      </div>
    )
  }
}

export default ButtonGoogleAuth
