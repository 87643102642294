import React from 'react'
import { MoonLoader } from 'react-spinners'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Colors } from 'advenio-components'

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height ? `${height}px` : '100vh'};
  width: 100%;

  * {
    box-sizing: content-box;
  }
`

const LoadingSpinner = ({ containerHeight, size }) => (
  <SpinnerContainer height={containerHeight}>
    <MoonLoader color={Colors.blue500} size={size || 50} />
  </SpinnerContainer>
)

LoadingSpinner.propTypes = {
  /** Set a fixed height for the loading spinner container, in pixels */
  containerHeight: PropTypes.number,
  /** The size of the loading spinner, in pixels */
  size: PropTypes.number,
}

LoadingSpinner.defaultProps = {
  containerHeight: null,
  size: null,
}

export default LoadingSpinner
