import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import R,{isEmpty} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import RegionActions, { RegionRedux, RegionTypes } from '../Redux/RegionRedux'
import { REGION } from '../GraphQL'
import moment from 'moment'
import { parseDataGraphQL } from '../../utils/Utils'

const regionCrudObservable = createCRUDObservable({
  mainRedux: RegionRedux,
  reduxPath: 'region',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})

const getRegionEpic = (action$, store, { Api }) => (
  action$.ofType(RegionTypes.RegionRequest)
    .mergeMap(() => (
      Observable
        .fromPromise(
          Api.query({query: REGION.getAllRegions,})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              RegionActions.RegionError(response['message'])
            ]
          }

          const { allCatRegiones:{ edges } } = response.data

          // if (allRegiones.status !== '200') {
          //   

          //   return [
          //     notificationFail(allRegiones.message),
          //     RegionActions.RegionError(allRegiones)
          //   ]
          // }
          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              RegionActions.RegionSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            // AuthActions.setAuthorized(true),
            RegionActions.RegionSuccess(data),
          ]
        })
    ))
)

export const regionObservers = Object.assign({}, regionCrudObservable.observers, {})

export const regionEpic = combineEpics(
  regionCrudObservable.epic,
  getRegionEpic,
)
