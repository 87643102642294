import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, Colors } from 'advenio-components'
import { STATUS } from '../../Constants'
import { PanelHeader } from '../ProfilePanel/ProfilePanel.styles'
import moment from 'moment'
import { AWS_BUCKET_URL } from '../../config'

const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 120px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
`
const Row = styled(props => (
  <OriginalRow middle="xs" around="lg" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin: 1% 0px 3% 0;
  margin-left: 3% !important;
  margin-right: 3% !important;
  :last-child{
  }
`
const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const Info = styled.div`
  line-height: 40px;
  ${({ title })=> !! (title) && `
    font-weight: bold;
    padding-left:12px
  `}
  ${({ data })=> data && `
    line-height :20px;
    padding-left: 24px;
    color: ${Colors.gray700}
  `}
`
const InfoFile = styled.span`
  cursor: pointer;
  color: ${Colors.blue500}
`
class InfoGeneral extends Component {

  openfile=(path )=>{
    if(path !== null){
      window.open(AWS_BUCKET_URL+path)
    }
  }
  render() {
    const {
      solicitudStage:
      {
        flujo:
        {
          solicitud
        }
      },
      solicitudStage
    } = this.props
    return (
      <Container>
        <PanelHeader tabTitle >Información General</PanelHeader>
        <Grid>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" >Tipo de solicitud:</Info>
              <Info data="true" >{solicitud.tipoSolicitud.nombre}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" >Solicitado por: </Info>
              <Info data="true" >{solicitud.usuario.nombre}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
            <Info title="true" >  Unidad operativa: </Info>
            <Info data="true" > {solicitud.ou.nombre} </Info>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={4}>
             <Info title="true" > Región:</Info>
             <Info data="true" >{solicitud.region.nombre}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" >Centro Educativo: </Info>
              <Info data="true" >{solicitud.centro.nombre} </Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" >Tipo de candidato: </Info>
              <Info data="true" >{solicitud.tipoCandidato || ''} </Info>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Descripción de puesto: </Info>
              <Info data="true"  >
                <InfoFile onClick={()=> {this.openfile(solicitud.descripcionPuesto || null)}}>
                  {solicitud.descripcionPuesto  !== null ? 'Ver descripción de puesto' : ''}
                </InfoFile>
              </Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" >  Requisición: </Info>
              <Info data="true"  >
                <InfoFile onClick={()=> {this.openfile(solicitud.organigrama || null)}}>
                  {solicitud.organigrama  !== null ? 'Ver requisición' : ''}
                </InfoFile>
              </Info>            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Justificación operativa: </Info>
              <Info data="true" > {solicitud.justificacionOperativa || ''}</Info>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Área: </Info>
              <Info data="true" > {solicitud.area.nombre}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Departamento: </Info>
              <Info data="true" > {solicitud.departamento || ''}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Puesto a quien reportar: </Info>
              <Info data="true" > {solicitud.puestoReporta || ''}</Info>
            </Col>
          </Row>
          {(!! solicitud.tipoComputadora || !! solicitud.tipoLinea || !! solicitud.equipoEspecial) && (
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" > Tipo de computadora: </Info>
                <Info data="true" > {solicitud.tipoComputadora || ''}</Info>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" >  Equipo especial: </Info>
                <Info data="true" > {solicitud.equipoEspecial || ''}</Info>
              </Col>
              <Col sm={12} md={3} lg={4}>
                <Info title="true" > Tipo de linea: </Info>
                <Info data="true" > {solicitud.tipoLinea || ''}</Info>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Fecha creación: </Info>
              <Info data="true" > {moment(solicitud.fecha).format('Y-M-D')}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Fecha actualizacion: </Info>
              <Info data="true" > {moment(solicitud.fechaActualizacion).format('Y-M-D')}</Info>
            </Col>
            <Col sm={12} md={3} lg={4}>
              <Info title="true" > Prioridad: </Info>
              <Info data="true" > {solicitud.prioridad.nombre}</Info>
            </Col>
          </Row>
        </Grid>
      </Container>
    )
  }
}

InfoGeneral.propTypes = {
}

InfoGeneral.defaultProps = {

}

const mapStateToProps = ({ auth }) => ({

})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoGeneral)
