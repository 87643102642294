import { createActions, createState, createReducer } from 'reduxsauce-crud'
import R from 'ramda'

const d = { deep: true }

export const JobRedux = createActions({
    JobRequest: [''],
    JobSuccess: ['data'],
    JobError: ['err'],
    // updateExpense: ['data']
}, {
    prefix: 'JOB_',
    defaultActions: {
        get: true,
    },
}, )

const { Types, Creators } = JobRedux
export const JobTypes = Types
export default Creators

export const INITIAL_STATE = createState({}, {
    get: true,
})

const JobRequest = state =>
    state.merge({ get: { fetching: true, error: null } }, d)

const JobError = (state, { err }) =>
    state.merge({ get: { fetching: false, error: err } }, d)

const JobSuccess = (state, data) => {
    return state.merge({ get: { fetching: false, results: data.data } }, d)
}

// const updateExpense = (state, { data }) => {
//   const newSolicitud = R.clone(state.get.results.data)
//   const activeExpenseIndex = R.findIndex(R.propEq('id', data.data.id))(newSolicitud)
//   newSolicitud[activeExpenseIndex] = data.data
//   return state.merge({ get: { fetching: false, results: { data: newSolicitud } } }, d)
// }


export const reducer = createReducer(
    INITIAL_STATE, {
        [Types.JobRequest]: JobRequest,
        [Types.JobSuccess]: JobSuccess,
        [Types.JobError]: JobError,
        // [Types.updateExpense]: updateExpense,
    }, {
        defaultActions: {
            get: true,
        },
        Types,
    },
)