import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import R,{isEmpty} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { gql } from '@apollo/client';
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import SendNotificationActions, { SendNotificationRedux, SendNotificationTypes } from '../Redux/SendNotificationRedux'
import moment from 'moment'
import { parseDataGraphQL } from '../../utils/Utils'

const sendNotificationCrudObservable = createCRUDObservable({
  mainRedux: SendNotificationRedux,
  reduxPath: 'sendNotification',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})
const sendNotificationMuation=gql`
mutation
  sendNotification($id:ID!){
    sendNotification (id:$id){
      status
      message
    }
  }
`
const postSendNotificationEpic = (action$, store, { Api }) => (
  action$.ofType(SendNotificationTypes.postSendNotificationRequest)
    .mergeMap(({data}) => (
      Observable
        .fromPromise(
          Api.query(
            { query: sendNotificationMuation, variables: data, fetchPolicy: 'no-cache'}
          )
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              SendNotificationActions.postSendNotificationError(response['message'])
            ]
          }

          const { sendNotification } = response.data

          // if (allSendNotificationes.status !== '200') {
          //   

          //   return [
          //     notificationFail(allSendNotificationes.message),
          //     SendNotificationActions.SendNotificationError(allSendNotificationes)
          //   ]
          // }
          if(isEmpty(sendNotification)){
            return [
              notificationFail('No se encontraron resultados!.'),
              SendNotificationActions.postSendNotificationError([]),
            ]
          }
          const data = sendNotification

          return [
            // AuthActions.setAuthorized(true),
            SendNotificationActions.postSendNotificationSuccess(data),
          ]
        })
    ))
)

export const sendNotificationObservers = Object.assign({}, sendNotificationCrudObservable.observers, {})

export const sendNotificationEpic = combineEpics(
  sendNotificationCrudObservable.epic,
  postSendNotificationEpic,
)
