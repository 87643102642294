import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, Colors } from 'advenio-components'
import { SOLICITUD_STAGES, STATUS } from '../../Constants'
import { PanelHeader } from '../ProfilePanel/ProfilePanel.styles'
import moment from 'moment'

const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
  const Grid = styled(props => (
    <OriginalGrid fluid {...props}>
      {props.children}
    </OriginalGrid>
  ))`
  border: 1px solid #b5b5b5;
  border-radius: 8px;
  `
  const Row = styled(props => (
    <OriginalRow middle="xs" around="lg" {...props}>
      {props.children}
    </OriginalRow>
  ))`
    margin: 1% 0px 3% 0;
    margin-left: 3% !important;
    margin-right: 3% !important;
    :last-child{
    }
  `
  const Col = styled(props => (
    <OriginalCol xs={12} {...props}>
      {props.children}
    </OriginalCol>
  ))``

const Info = styled.div`
    line-height: 40px;
    ${({ title })=> !! (title) && `
      font-weight: bold;
      padding-left:12px
    `}
    ${({ data })=> data && `
      line-height :20px;
      padding-left: 24px;
      color: ${Colors.gray700}
    `}
`
class InfoAditional extends Component {

  render() {
    const {
      solicitudStage:
      {
        flujo:
        {
          solicitud
        }
      },
      solicitudStage
    } = this.props
    return (
      <Container>
        <PanelHeader tabTitle >Información Solicitante</PanelHeader>
        <Grid>
          {solicitudStage.etapa.etapa.codigoInterno !== SOLICITUD_STAGES.cred_ti && (
            <div>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" >Objetivo:</Info>
                <Info data="true" >{solicitud.objetivoPuesto|| ''}</Info>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" >A quien remplaza: </Info>
                <Info data="true" >{solicitud.aQuienReemplaza || ''}</Info>
              </Col>
              <Col sm={12} md={6} lg={4}>
              <Info title="true" >Carrera: </Info>
              <Info data="true" > {solicitud.carrera || ''} </Info>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={4}>
              <Info title="true" > Experiencia mínima:</Info>
              <Info data="true" >{solicitud.experienciaMinima || ''}</Info>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" >Conocimientos técnicos: </Info>
                <Info data="true" >{solicitud.conocimientosTecnicos || ''} </Info>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" >Séctor de preferencia: </Info>
                <Info data="true" >{solicitud.sectorPreferencia || ''} </Info>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" >Habilidad requereidas: </Info>
                <Info data="true" >{solicitud.habilidadesRequeridas || ''}</Info>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" > Edad mínima: </Info>
                <Info data="true" >{solicitud.edadMinima || ''}</Info>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Info title="true" > Edad máxima: </Info>
                <Info data="true" > {solicitud.edadMaxima || ''}</Info>
              </Col>
            </Row>
          </div>)}
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Centro de costo: </Info>
              <Info data="true" > {solicitud.centroCostos || ''}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Compañia: </Info>
              <Info data="true" > {solicitud.compania || ''}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Puesto: </Info>
              <Info data="true" > {solicitud.puesto || ''}</Info>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <Info title="true" > Plaza: </Info>
              <Info data="true" > {solicitud.plaza || ''}</Info>
            </Col>
            <Col sm={12} md={6} lg={4}>
            </Col>
            <Col sm={12} md={3} lg={4}>
              <Info title="true" > Tipo de nómina: </Info>
              <Info data="true" > {solicitud.tipoNomina|| ''}</Info>
            </Col>
          </Row>
        </Grid>
      </Container>
    )
  }
}

InfoAditional.propTypes = {
}

InfoAditional.defaultProps = {

}

const mapStateToProps = ({ auth }) => ({

})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoAditional)
