const shortid = require('shortid')
//import * as AWS from 'aws-sdk'
const AWS = require('aws-sdk')
const utils = require('../utils/Utils')
const { BUCKET } = require('../config')

export const uploadFile = (folder = null ,file) =>{
  let folderName = folder
  let response =null
  const shortId = shortid.generate()

  if (folderName === null ){
    folderName = shortid.generate()
  }

  if (!file.length) {
    return alert("Escoge un archivos para subir.");
  }

  const  s3bucket  = new AWS.S3({params: {Bucket: BUCKET},apiVersion: '2006-03-01'});

  const folderKey = `${folderName}/${shortId}/`;

  const  paramsFolder= {
    Key: folderKey
  }

  const folderAWS = s3bucket.putObject(paramsFolder, function(err, data) {
    if (err) {
      console.log(err)
      return alert("Hay un error al crear la carpeta en aws: " + err.message);
    }
    return data
  });

  const pahtFolder = folderAWS.params.Key

  if(pahtFolder !== null && pahtFolder !== '') {

    const fileName = file[0].name;

    const fileKey =  pahtFolder + utils.slugify(fileName)

    var  paramsFile = {
      Bucket: BUCKET,
      Key: fileKey,
      Body: file[0]
    }

    // var upload = new AWS.S3.ManagedUpload({params: paramsFile});
    // upload.send((err, data) => {
    //   console.log(err, data);
    //   if(data !== undefined){
    //     if(data.Location!== undefined && data.Location !== null){

    //       return true

    //     }
    //   }
    // })
    return s3bucket.upload(paramsFile).promise().then(data => data.Location)
    // s3bucket.upload(paramsFile, function(err, data) {
    //   if (err) {
    //     console.log(err)
    //     return alert("Hay un error al subir el archivo en aws: " + err.message);
    //   }
    //   pathFile = data.Location
    //   console.log(data)
    // })
    // return paramsFile.Key
  }
  return null
}

export const getFile = (key=null) =>{
  const  s3bucket  = new AWS.S3({params: {Bucket: BUCKET},apiVersion: '2006-03-01'});
  s3bucket.getObject({
    Key: key
   }, function(err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else  console.log(data);  return data;            // successful response
    /*
    data = {
     AcceptRanges: "bytes",
     ContentLength: 3191,
     ContentType: "image/jpeg",
     ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"",
     LastModified: <Date Representation>,
     Metadata: {
     },
     TagCount: 2,
     VersionId: "null"
    }
    */
  });
}
