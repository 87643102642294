import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import R,{isEmpty} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import CenterActions, { CenterRedux, CenterTypes } from '../Redux/CenterRedux'
import { CENTER } from '../GraphQL'
import moment from 'moment'
import { parseDataGraphQL } from '../../utils/Utils'

const centerCrudObservable = createCRUDObservable({
  mainRedux: CenterRedux,
  reduxPath: 'center',
  // dataHandlers: {
  //   get: response => response.data,
  // },
})

const getCenterEpic = (action$, store, { Api }) => (
  action$.ofType(CenterTypes.CenterRequest)
    .mergeMap(() => (
      Observable
        .fromPromise(
          Api.query({query: CENTER.getAllCenters,})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              CenterActions.CenterError(response['message'])
            ]
          }

          const { allCatCentros:{ edges } } = response.data

          // if (allCenteres.status !== '200') {
          //   

          //   return [
          //     notificationFail(allCenteres.message),
          //     CenterActions.CenterError(allCenteres)
          //   ]
          // }
          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              CenterActions.CenterSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            // AuthActions.setAuthorized(true),
            CenterActions.CenterSuccess(data),
          ]
        })
    ))
)

export const centerObservers = Object.assign({}, centerCrudObservable.observers, {})

export const centerEpic = combineEpics(
  centerCrudObservable.epic,
  getCenterEpic,
)
