import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { COOKIE_DOMAIN } from '../../config'
import Api from '../Api'

import { authEpic } from './AuthObservable'
import { authorizationStatusEpic } from './AuthorizationStatusObservable'
import { centerEpic } from './CenterObservable'
import { regionEpic } from './RegionObservable'
import { resetPasswordEpic } from './ResetPasswordObservable'
import { operativeUnitEpic } from './OperativeUnitObservable'
import { sendNotificationEpic } from './SendNotificationObservable'
import { solicitudEpic } from './SolicitudObservable'
import { solicitudTypesEpic } from './SolicitudTypesObservable'
import { stageAuthorizeEpic } from './StageAuthorizeObservable'
import { userEpic } from './UserObservable'
import { jobEpic } from './JobObservable'
import { flowAuthorizationStagesEpic } from './FlowAuthorizationStagesObservable'
import { cataloguesEpic } from './CataloguesObservable'

const rootEpic = combineEpics(
    authEpic(COOKIE_DOMAIN),
    authorizationStatusEpic,
    centerEpic,
    regionEpic,
    resetPasswordEpic,
    operativeUnitEpic,
    sendNotificationEpic,
    solicitudEpic,
    solicitudTypesEpic,
    stageAuthorizeEpic,
    userEpic,
    jobEpic,
    flowAuthorizationStagesEpic,
    cataloguesEpic
)

export default createEpicMiddleware(rootEpic, {
    dependencies: { Api }
})