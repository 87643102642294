import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink, from, gql, createHttpLink, ApolloError } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from "@apollo/client/link/error"
import { getCookie } from '../utils/Utils';
import { API_DOMAIN } from '../config';
import { TYPES } from '../Data/GraphQL'

const typeDefs = TYPES

// const httpLink = new HttpLink({ uri: 'https://127.0.0.1:7797/graphql-auth' });

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-and-network',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
    },
};
const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (getCookie('vacantes_api_key')) {
        operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    authorization: `Bearer ${getCookie('vacantes_api_key')}`,
                }
            }));
        } else {
            operation.setContext(({ headers = {} }) => ({       
                headers: {
                    ...headers,
                    // 'Access-Control-Allow-Origin': `*`,
                    // 'Origin': `https://vacantes-dev.talisis.com`
                // authorization: `Bearer ${getCookie('vacantes_api_key')}` || null,
            }
        }));
        // console.log(operation.getContext('headers'));    
    }
    return forward(operation);
})

const handleError = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
        // graphQLErrors.forEach(({ message }) =>
        //   
        // );
    }

    if (networkError) { console.log(`[Network error]: ${networkError}`); }
});

const directionalLink = new RetryLink().split(
    (operation) => operation.getContext().headers.authorization === 'undefined' || operation.getContext().headers.authorization === undefined,
    new HttpLink({ uri: `${API_DOMAIN}/graphql-auth` }),
    new HttpLink({ uri: `${API_DOMAIN}/graphql` })
);

const apolloClient = new ApolloClient({
    link: from([
        authMiddleware,
        directionalLink
    ]),
    cache: new InMemoryCache(),
    typeDefs,
    //defaultOptions,
})

export default apolloClient
