import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { equals, clone, isEmpty } from 'ramda'
import { connect } from 'react-redux'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, Colors } from 'advenio-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { OUID, AUTORIZATIONTYPEID} from '../../Constants'
import LoadingSpinner from '../../shared/LoadingSpinner'
import { parseDataGraphQL } from '../../utils/Utils'
import { AWS_BUCKET_URL } from '../../config'

import InfoAdicionalSol from '../../Components/SatgeAuthorization/CaptureData/InfoAdicionalSol'

const labelContainerConfig = labelType => `
  ${labelType === 'right' ?
    `position: absolute;
     left: 23px;` : ''}
`
const InfoFile = styled.span`
  cursor: pointer;
  color: ${Colors.blue500}
  display: block;
  margin-top: 5px;
`
const Label = styled.label`
font-size: 14px;
margin-bottom: 2px;
margin-right: 4px;
display:block;
${props => labelContainerConfig(props.labelType)}
`
export const FormContainer = styled.div`
  margin: 16px;
`
export const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 0 !important;
  padding-right: 0 !important;
`
export const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin-top: 4%;
`
export const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``
export const Header = styled(Title2)``
const Checkbox = ({ label, name, value }) => (
  <OriginalCheckbox
    inputAlign
    invertedColors
    large
    hideEmptyHelpBlock
    label={label}
    name={name}
    value={value}
  />
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}



class SolicitudForm extends Component {
  state = {
    regions:[{id:0,label:"Seleccionar",value:0}],
    ous: [{id:0,label:"Seleccionar",value:0}],
    centers: [{id:0,label:"Seleccionar",value:0}],
    areas: [{id:0,label:"Seleccionar",value:0}],
    jobs: [{id:0,label:"Seleccionar",value:0}],
    loadingOu: false,
    loadingRegion: false,
    loadingCentro: false,
    loadingAreaId :false,
    loadingjobs :false,
    idSolicitud: '',
  }


  componentWillMount() {
    //  quitar para desarrollar
    const{
      info:{
        centro,
        region,
        ou,
        area,
        tipoSolicitud,
        id

      },
      info
    } =this.props
    if(id !== undefined){
      this.changeSolicitudTypes({'target':{'value':tipoSolicitud.id}})
      this.changeOUs({'target':{'value':ou.id}})
      this.changeRegions({'target':{'value':region.id}})
      // this.changeJobs({'target':{'value':nombrePuesto.id}})
    }
  }

  componentDidMount = () => {
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (equals(this.props, nextProps) && equals(this.state, nextState)) return false

  //   return true
  // }

  openfile=(path )=>{
    if(path !== null){
      window.open(AWS_BUCKET_URL+path)
    }
  }

  changeSolicitudTypes = ({target:{ value:idSolicitud }}) => {
    this.setState({
       loadingOu: true
      ,loadingCentro: true
      ,loadingRegion: true
      ,loadingAreaId: true
      ,loadingjobs: true
      ,idSolicitud
    })


    setTimeout(() => {
      let  ous = [{id:0,label:"Seleccionar",value:0}]
      let regions = [{id:0,label:"Seleccionar",value:0}]
      let centers = [{id:0,label:"Seleccionar",value:0}]
      let areas = [{id:0,label:"Seleccionar",value:0}]
      if(idSolicitud !== 0 && idSolicitud !== null && idSolicitud !== ' ' && idSolicitud !== '0'){
        const{ operativeUnit, region} =this.props
        if(idSolicitud == AUTORIZATIONTYPEID.REPLACEMENT_OPERATION)
        {
          const filterOU = operativeUnit.filter(ou => ou.id !== OUID.TALISIS)
          
          ous = ous.concat(this.renderOptions(filterOU))
        }else {
          const filterOU = operativeUnit.filter(ou => ou.id === OUID.TALISIS)
          ous = ous.concat(this.renderOptions(filterOU))
        }
      }
      this.setState({
        ous
        ,regions
        ,centers
        ,areas
        ,loadingOu: false
        ,loadingCentro: false
        ,loadingRegion: false
        ,loadingAreaId: false
        ,loadingjobs: false
      })
    }, 1000);

  }

  changeOUs = ({target:{ value }}) => {
    this.setState({
     loadingCentro: true
     ,loadingRegion: true
     ,loadingAreaId: true
     ,loadingjobs: true
   })
   setTimeout(() => {

    let regions = [{id:0,label:"Seleccionar",value:0}]
    let centers = [{id:0,label:"Seleccionar",value:0}]
    let areas = [{id:0,label:"Seleccionar",value:0}]
    let jobs = [{id:0,label:"Seleccionar",value:0}]

    if(value !== 0 && value !== null && value !== ' ' && value !== '0'){

      const { center, operativeUnit, region, job } =this.props

      const ouSelected = operativeUnit.filter(ou => ou.id === value)[0]
      const filertAreas = parseDataGraphQL(ouSelected['area']['edges'])
      const filterRegion = region.filter(r => r.ou.id === ouSelected.id) || []
      const filterjobs = job.filter(j => j.ou.id === ouSelected.id) || []
      regions = regions.concat(this.renderOptions(filterRegion))
      areas = areas.concat(this.renderOptions(filertAreas))
      jobs = jobs.concat(this.renderOptions(filterjobs))

    }
    this.setState({
       regions
       ,centers
       ,areas
       ,jobs
       ,loadingCentro: false
       ,loadingRegion: false
       ,loadingAreaId: false
       ,loadingjobs: false
    })
   }, 1000);

  }

  changeRegions = ({target:{ value }}) => {
    this.setState({
     loadingCentro: true
   })

   setTimeout(() => {
    const form = this.props.form.getModel()

    let centers = [{id:0,label:"Seleccionar",value:0}]
    if(value !== 0 && value !== null && value !== ' ' && value !== '0'){
      const { center, operativeUnit, region} =this.props

      const filterCenters = center.filter(c => c['ou']['id'] === form.regionId)
      centers = centers.concat(this.renderOptions(filterCenters))
    }
    this.setState({
      centers
       ,loadingCentro: false
    })
   }, 1500);

  }

  // changeJobs = ({target:{ value }}) => {
  //   this.setState({
  //    loadingCentro: true
  //  })

  //  setTimeout(() => {
  //   const form = this.props.form.getModel()

  //   let centers = [{id:0,label:"Seleccionar",value:0}]
  //   if(value !== 0 && value !== null && value !== ' ' && value !== '0'){
  //     const { center, operativeUnit, region, job} =this.props

  //     const filterCenters = center.filter(c => c['ou']['id'] === form.regionId)
  //     centers = centers.concat(this.renderOptions(filterCenters))
  //   }
  //   this.setState({
  //     centers
  //      ,loadingCentro: false
  //   })
  //  }, 1500);

  // }

  renderSolicitudTypesOptions = () => {
    const solicitudTypesClone = [...this.props.solicitudTypes]
    let selectionOptions = [{id:0,label:"Seleccionar",value:0}]
    return selectionOptions.concat(solicitudTypesClone.filter(flow => flow.deleted ===  0 && flow.status === 1 && flow.autorizacionTipo.autorizacionSpec.edges.length > 0).map(type => ({
      id: type.id,
      label: type.nombre,
      value: type.id,
    })))
  }

  renderOptions = (options) => {

  const optionsClone = [...options]
  let selectionOptions = [{id:0,label:"Seleccionar",value:0}]

  if(optionsClone.length === 0 ) return []

  return optionsClone.map(type => ({
    id: type.id,
      label: type.nombre,
      value: type.id,
    }))
  }



  render() {
    const { info, showRelation, paymentAutomatic, onUploadFiles, disabledFiles, loadingFiles, onchangeFiles} = this.props
    const { ous, centers, jobs , regions, areas,loadingOu, loadingCentro, loadingjobs, loadingRegion, loadingAreaId,idSolicitud } = this.state

    return (
      <FormContainer>
        <Grid>
          <Row>
            <Col sm={6} md={3}>
              <Select
                name="tipoSolicitudId"
                label="Tipo de Solicitud"
                required
                value={! isEmpty(info.tipoSolicitud)? info.tipoSolicitud.id : null}
                options={this.renderSolicitudTypesOptions()}
                disabled={ ! isEmpty(info.tipoSolicitud)}
                //emptyElement
                //hideEmptyHelpBlock
                onChangeCallback={(e)=> {this.changeSolicitudTypes(e)}}
                //fullWidth
              />
            </Col>
            <Col sm={6} md={3}>
              {/* { loadingOu ?
                <div> <LoadingSpinner size={50}/></div>
              : */}
                <Select
                  name="ouId"
                  label="Unidad Operativa"
                  required
                  value={! isEmpty(info.ou)? info.ou.id : null}
                  options={ous}
                  // emptyElement
                  //hideEmptyHelpBlock
                  disabled={ous.length === 1}
                  onChangeCallback={(e)=> {this.changeOUs(e)}}
                  loading={loadingOu}
                  //fullWidth
                />
              {/* } */}
            </Col>
            <Col sm={6} md={3}>
            {/* { loadingRegion ?
              <LoadingSpinner/>
            : */}
              <Select
                name="regionId"
                label="Region"
                required
                value={! isEmpty(info.region)? info.region.id : null}
                options={regions}
                // emptyElement
                //hideEmptyHelpBlock
                disabled={regions.length === 1}
                onChangeCallback={(e)=> {this.changeRegions(e)}}
                loading={loadingRegion}
              />
            {/* } */}
            </Col>
            <Col sm={6} md={3}>
            {/* {loadingCentro ?
              <LoadingSpinner/>
            : */}
              <Select
                name="centroId"
                label="Centro Educativo"
                required
                value={! isEmpty(info.centro)? info.centro.id : null}
                options={centers}
                // emptyElement
                // hideEmptyHelpBlock
                disabled={centers.length === 1}
                loading={loadingCentro}
                //fullWidth
              />
            {/* } */}
            </Col>
            </Row>

          <Row>
           <Col sm={6} md={3}>
              <Select
                name="nombrePuestoId"
                label="Nombre del Puesto"
                required
                value={! isEmpty(info.nombrePuesto)? info.nombrePuesto.id : null}
                options={jobs}
                // emptyElement
                // hideEmptyHelpBlock
                disabled={jobs.length === 1}
                loading={loadingjobs}
                //fullWidth
                />
            </Col>
            <Col sm={6} md={3}>
            <Select
                name="areaId"
                label="Área"
                required
                value={! isEmpty(info.area)? info.area.id : null}
                options={areas}
                // emptyElement
                // hideEmptyHelpBlock
                disabled={areas.length === 1}
                loading={loadingAreaId}
                //fullWidth
              />
            </Col>
            <Col sm={6} md={3}>
              <TextInput
                name="departamento"
                label="Departamento"
                value={info.departamento}
                required
              />
            </Col>
            <Col sm={6} md={3}>
              <TextInput
                name="puestoReporta"
                label=" Puesto a quien reporta"
                value={info.puestoReporta}
                required
              />
            </Col>
          </Row>
         <Row>
              <Col sm={6} md={4}>
                <TextInput
                  label="Correo del jefe directo"
                  name="correoJefeDirecto"
                  value={info.correoJefeDirecto}
                  type='email'
                  required
                />
              </Col>
              <Col sm={6} md={4}>
                <TextInput
                  label="¿Por qué es necesaria esta posición?"
                  name="justificacionOperativa"
                  value={info.justificacionOperativa}
                  textarea
                  required
                />
              </Col>
              <Col sm={6} md={4}>
                <Select
                  name="prioridadId"
                  label="Prioridad"
                  required
                  value={! isEmpty(info.prioridad)? info.prioridad.id : null}
                  options={[
                    {id:0 ,label:"Seleccionar",value:0},
                    {id:"UHJpb3JpZGFkQ2F0YWxvZ09iamVjdDox" ,label:"Baja",value:"UHJpb3JpZGFkQ2F0YWxvZ09iamVjdDox"},
                    {id:"UHJpb3JpZGFkQ2F0YWxvZ09iamVjdDoy" ,label:"Media",value:"UHJpb3JpZGFkQ2F0YWxvZ09iamVjdDoy"},
                    {id:"UHJpb3JpZGFkQ2F0YWxvZ09iamVjdDoz" ,label:"Alta",value:"UHJpb3JpZGFkQ2F0YWxvZ09iamVjdDoz"},
                  ]}
                  fullWidth
                />
              </Col>
          </Row>

          <Row>
           <InfoAdicionalSol info={info} typeSolicitud = {idSolicitud}/>
          </Row>


          {/* <Row>
            <Col xs={12}> */}
              <Row  style={{marginTop:0, paddingBottom: '25px'}} >
                <Col sm={6} md={6}>
                  <Label
                  htmlFor={'descripcionPuesto'}
                  labelType={'left'}
                  >
                    Descripcion Puesto
                  </Label>
                  <input id="descripcionPuesto" type="file" onChange={e => onchangeFiles(e, 'descriptionJob')} accept="*"></input>
                  <InfoFile onClick={()=> {this.openfile(info.descripcionPuesto || null)}}>
                    {info.descripcionPuesto  !== null ? 'Ver descripción puesto' : ''}
                  </InfoFile>
                </Col>
                <Col sm={6} md={6}>
                  <Label
                    htmlFor={'organigrama'}
                    labelType={'left'}
                  >
                    Requisición
                  </Label>
                  <input id="organigrama"onChange={e => onchangeFiles(e, 'organigram')}type="file" accept="*"></input>
                  <InfoFile onClick={()=> {this.openfile(info.organigrama || null)}}>
                    {info.organigrama  !== null ? 'Ver organigrama' : ''}
                  </InfoFile>
                </Col>
                {/* <Col sm={6} md={4}>
                <Button primary disabled={false} loading={loadingFiles} onClick={onUploadFiles}>
                    <TextIcon label="Subir archivos" iconType="plus" iconViewBox="-2 -2 24 24" />
                  </Button>
                </Col> */}
              </Row>
            {/* </Col>
          </Row> */}
        </Grid>
     </FormContainer>
    )
  }
}

SolicitudForm.propTypes = {
}

SolicitudForm.defaultProps = {
  info: {
    nombrePuesto: {} ,
    area: {} ,
    departamento: null ,
    puestoReporta: null ,
    correoJefeDirecto: null ,
    ou: {} ,
    region: {} ,
    centro: {} ,
    justificacionOperativa: null ,
    tipoSolicitud: {} ,
    prioridad: {} ,
    organigrama: null,
    descripcionPuesto: null,
    objetivoPuesto: null,
    aQuienReemplaza:null,
    carrera:null,
    experienciaMinima: 0,
    conocimientosTecnicos:null,
    sectorPreferencia:null,
    edadMinima:null,
    edadMaxima:null,
    habilidadesRequeridas:null
  }
}

const mapStateToProps = state => ({
  center: state.center.get.results,
  operativeUnit: state.operativeUnit.get.results,
  region: state.region.get.results,
  solicitudTypes:  state.catalogues.getCatAuthTypes.results,
  job: state.job.get.results
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SolicitudForm)

