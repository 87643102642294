import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon } from 'advenio-components'
import { isEmpty } from 'ramda'
import { STATUS } from '../../../Constants'
import { PanelHeader } from '../../ProfilePanel/ProfilePanel.styles'
import moment from 'moment'
import { notificationFail, notificationSuccess } from '../../../Data/Redux/NotificationActions'
import AuthorizationStatusActions from '../../../Data/Redux/AuthorizationStatusRedux'
import SolicitudActions from '../../../Data/Redux/SolicitudRedux'

const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
   padding-left: 120px !important;
   padding-right: 120px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
`
const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin-top: 4%;
`
const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const Checkbox = ({ label, name, value }) => (
  <OriginalCheckbox
    invertedColors
    large
    hideEmptyHelpBlock
    label={label}
    name={name}
    value={value}
  />
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}

class Compensations extends Component {
  state={
    flawSend:false,
    dataSend:{}
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const {
      validSolicitud,
      messageFail,
      updatedSolicitud,
      user,
      setSolicitudStageData
    } = nextProps
    const { flawSend, dataSend } = this.state

    if(flawSend && ! isEmpty(dataSend) && !isEmpty(validSolicitud)){
      if(validSolicitud.status.codigoInterno === STATUS.authorized || validSolicitud.status.codigoInterno === STATUS.rejected){
        messageFail('No se puede cambiar el estatus de una etapa previamente autorizada/rechazada')
      }else{
        let dataSolicitud = {
          'data':{
            ...dataSend,
            'id': validSolicitud.flujo.solicitud.id
          },
          'paramsExtra':{
            'nextStatus':dataSend.statusId
          }
        }

        delete dataSolicitud.data.comentarios
        delete dataSolicitud.data.statusId

        dataSend.usuarioId = user.id

        setSolicitudStageData(dataSend)
        updatedSolicitud(dataSolicitud)
      }
    }
  }

  onSubmit= (data) =>{
    const {  match:{ params: { solicitudStageId }}, validateSolitictud, info } = this.props
    const dataSend = {
      ...data,
      'id':solicitudStageId,
      'comentarios':  info.comentarios === null ? `${data.comentarios}. Sueldo minimo: ${data.sueldoMinimo}. Sueldo Maximo: ${data.sueldoMaximo}. En Presupuesto: ${data.enPresupuesto? 'Si':'No'}.` : data.comentarios
    }

    this.setState({dataSend, flawSend:true})
    if(data.statusId !== 0){
      validateSolitictud({
        'id':solicitudStageId
      })
    }
  }

  renderOptions = () => [{id:0,label:"Seleccionar",value:0}].concat(this.props.status.map(s=>({
    id:s.id
    , label:s.nombre
    , value: s.id
  })))

  render() {
    const {
      info
    } = this.props
    return (
      <Container>
        <PanelHeader tabTitle >Captura de Datos</PanelHeader>
        <Form
          ref={(form) => { this.form = form }}
          onSubmit={this.onSubmit}
        >
          <Grid>
            <Row>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="sueldoMinimo"
                  label="Sueldo Minimo"
                  value={info.sueldoMinimo || 0}
                  type={'number'}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="sueldoMaximo"
                  label="Sueldo Maximo"
                  value={info.sueldoMaximo || 0}
                  type={'number'}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="nivel"
                  label="Nivel"
                  value={info.nivel || 0}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="centroCostos"
                  label="Centro de costo"
                  value={info.centroCostos || null}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="tipoNomina"
                  label=" Tipo de Nomina"
                  value={info.tipoNomina || null}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Checkbox
                  name="enPresupuesto"
                  label="Entra en el Presupuesto *"
                  value={info.enPresupuesto || false}
                  type={'number'}
                />
              </Col>
              </Row>
              <Row>
              <Col sm={12} md={6} lg={6}>
                <TextInput
                  name="comentarios"
                  label="Comentarios"
                  value={info.comentarios || null}
                  textarea
                  required
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Select
                  name="statusId"
                  label="Autorización"
                  required
                  value={info.status.id || null}
                  options={this.renderOptions()}
                  fullWidth
                />
              </Col>
            </Row>
            <Row end="xs">
              <Col sm={12} md={12}>
                <Button primary disabled={info.status.codigoInterno === STATUS.authorized } style={{marginBottom:12}} loading={false} type="submit" >
                  {/* <TextIcon label="Subir archivos" iconType="plus" iconViewBox="-2 -2 24 24" /> */}
                  Guardar
                </Button>
              </Col>
            </Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

Compensations.propTypes = {
}

Compensations.defaultProps = {

}

const mapStateToProps = state => ({
  user: state.auth.user,
  status: state.authorizationStatus.get.results,
  validSolicitud: state.solicitud.validSolicitud.result,
})

const mapDispatchToProps = dispatch => ({
  validateSolitictud: (data) => dispatch(SolicitudActions.validateSolicitudRequest(data)),
  updatedSolicitud: (data) => dispatch(SolicitudActions.updateSolicitudRequest(data)),
  messageFail: msg => dispatch(notificationFail(msg)),
  setSolicitudStageData: data => dispatch(SolicitudActions.setSolicitudStageData(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Compensations))
