import { gql } from '@apollo/client';

const getAllCenters = gql`
query
  allCatCentros{
    allCatCentros(filters:{not:{status:0}}){
      edges{
        node{
          id
          nombre
          codigoInterno
          status
          deleted
          regionId
          ou{
            id
            nombre
            ou{
              id
              nombre
              codigoInterno
            }
          }
        }
      }
    }
  }
`
export{
  getAllCenters
}
