import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, Colors } from 'advenio-components'
import { STATUS } from '../../Constants'
import { PanelHeader } from '../ProfilePanel/ProfilePanel.styles'
import moment from 'moment'

const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
  const Grid = styled(props => (
    <OriginalGrid fluid {...props}>
      {props.children}
    </OriginalGrid>
  ))`
    padding-left: 11rem;
    border: 1px solid #b5b5b5;
    border-radius: 8px;
  `
  const Row = styled(props => (
    <OriginalRow middle="xs" around="lg" {...props}>
      {props.children}
    </OriginalRow>
  ))`
    margin: 1% 0px 3% 0;
    margin-left: 3% !important;
    margin-right: 3% !important;
    :last-child{
    }
  `
  const Col = styled(props => (
    <OriginalCol xs={12} {...props}>
      {props.children}
    </OriginalCol>
  ))``

const Info = styled.div`
    line-height: 40px;
    ${({ title })=> !! (title) && `
      font-weight: bold;
      padding-left:12px
    `}
    ${({ data })=> data && `
      line-height :20px;
      padding-left: 24px;
      color: ${Colors.gray700}
    `}
`
class InfoCompensations extends Component {

  render() {
    const {
      solicitudStage:
      {
        flujo:
        {
          solicitud
        }
      },
      solicitudStage
    } = this.props
    return (
      <Container>
        <PanelHeader tabTitle >Información Compensacion</PanelHeader>
        <Grid>
          <Row>
          </Row>
          <Row>
            <Col sm={12} md={3} lg={3}>
             <Info title="true" > Sueldo Minimo:</Info>
             <Info data="true" >{solicitud.sueldoMinimo || null}</Info>
            </Col>
            <Col sm={12} md={3} lg={3}>
              <Info title="true" >Sueldo Maximo: </Info>
              <Info data="true" >{solicitud.sueldoMaximo || null} </Info>
            </Col>
            <Col sm={12} md={3} lg={3}>
              <Info title="true" >Nivel: </Info>
              <Info data="true" >{solicitud.nivel || null} </Info>
            </Col>
            <Col sm={12} md={3} lg={3}>
              <Info title="true" >En presupuesto: </Info>
              <Info data="true" >{solicitud.enPresupuesto !== null ? Boolean(solicitud.enPresupuesto) ? 'Si' : 'No' : null } </Info>
            </Col>
          </Row>
          <Row>
          </Row>
        </Grid>
      </Container>
    )
  }
}

InfoCompensations.propTypes = {
}

InfoCompensations.defaultProps = {

}

const mapStateToProps = ({ auth }) => ({

})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoCompensations)
