import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Card as OriginalCard,
  Colors,
  Icon as OriginalIcon,
} from 'advenio-components'
import moment from 'moment'
import { Avatar } from '../../Components/ModalElements'
import { empty, isEmpty } from 'ramda'

const IconCustom = styled.img`
    width: 13px;
    height: 13px;
    margin-right: 5px;
`
const Card = styled(OriginalCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100px;
  border-left: ${({ isNew }) => isNew && `4px solid ${Colors.advenioBlue500}`};
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  cursor: grab;
  margin: 0;
  border-radius: 7px;
  z-index: ${props => props.isActive || props.isDragging ? '' : ''};
`

const Icon = styled(OriginalIcon)`
  position: absolute;
  right: 5px;
  fill: ${Colors.advenioGray100};
`
const InfoCont = styled.div`
  display: flex;
  margin-bottom: 16px;
`
const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`
const Name = styled.div`
  font-size: 16px;
  color: ${Colors.advenioGray700};
  ${({assigned}) => assigned &&`
  font-size:12px;
 `}  
`
const Contact = styled.div`
  width: 100%;
  opacity: 0.5;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${Colors.advenioGray700};
  text-transform: uppercase;
  margin-top: 4px;
`
const Span = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const ActionsCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Children = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 3px 5px;
  border-radius: 5px;
  color: ${Colors.white300};
  background-color:${Colors.red500}
`
const Status = styled.div`
  font-size: 12px;
  color: ${Colors.white300};
  display: flex;
  align-items: center;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${({ status }) => {
    if (status) { return Colors.green500 }
    else { return Colors.red500}
    return 'transparent'
  }};

  svg {
    fill: ${Colors.white300};
    margin-right: 3px;
  }
`
const TaskReminder = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${Colors.advenioGray700};
`

class CustomCard extends Component {
  state = {
    modalOpened: false,
  }

  checkStatus = (status) => {
    
    if (status) return '/assets/checked-inverse.svg'
    if (!status) return '/assets/cancel_icon.svg'
    return ''
  }

  // checkReminderDate = (date) => {
  //   const reminderDate = moment(date)
  //   const today = moment()
  //   if (reminderDate.isBefore(today, 'hour')) return 'error'
  //   if (reminderDate.isSameOrBefore(today.add(1, 'days'))) return 'warning'
  //   return 'correct'
  // }

  // handleModalClose = () => this.setState({ modalOpened: false })

  // renderAssigned_to = (assigned_to,users) => {
  //   if(assigned_to !== undefined && assigned_to !== null) {
  //       let user =  users.filter(user => user.id === assigned_to.id) || []
  //       if(!isEmpty(user)){
  //         return user[0].name
  //       }
  //    }
  //    return null
  //  }
  render() {
    const { name, id: stageId, isDragging, isActive, color, description, deleted, stage,
      onCardClick, autorize:{nombre:autorizeName}, status, departament,catStageId, orden } = this.props
    // const reminderStatus = this.checkReminderDate(reminder)
    // const assigned = user.role === 'Back Office Admin' ? this.renderAssigned_to(assigned_to,users) :null

    return (
      <Card
        // warning={warning}
        isDragging={isDragging}
        onClick={() => { onCardClick(stage) }}
        // isNew={isNew}
        isActive={isActive}
      >
        {/* <Icon type="plus" /> */}
        <InfoCont>
          <Avatar
            width="30px"
            height="30px"
            marginRight="10px"
            color={color}
          >
            {name && name[0].toUpperCase()}
          </Avatar>
          <TextCont>
            <Name>{name}</Name>
            <Contact><Span>Autorizador: {autorizeName} </Span></Contact>
            <Contact><Span>Departamento: {departament}</Span></Contact>
              <Contact>
                Etapa Id: {catStageId} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Orden: {orden}
              </Contact>
          </TextCont>
        </InfoCont>
        <ActionsCont>
          <Status
            status={status}
          >
            {/* <OriginalIcon type={this.checkStatus(status)} width={14} height={14} color={Colors.white300} /> */}
            <IconCustom src={this.checkStatus(status)}/>
            {status ? 'Habilitado': 'Deshabilitado'}
          </Status>
          {/* <TaskReminder>
            {reminder_name === 'Call Scheduled'? 'Llamar' :
             reminder_name === 'Email Scheduled'? 'Enviar Email':
             reminder_name === 'WhatsApp Scheduled'? 'Enviar WhatsApp': 'Sin Actividad'
            }
          </TaskReminder> */}
          {/* {(Boolean(deleted)) && (
            <Children>
              <IconCustom src="/assets/warning-circle.svg"/>
              Eliminado
            </Children>
          )} */}
        </ActionsCont>
      </Card>
    )
  }
}

CustomCard.propTypes = {
  warning: PropTypes.bool,
  name: PropTypes.string,
  isDragging: PropTypes.bool,
  isNew: PropTypes.bool,
  isActive: PropTypes.bool,
}

CustomCard.defaultProps = {
  warning: false,
  isDragging: false,
  isNew: false,
  isActive: false,
  childrenNum: null,
}

export default CustomCard
