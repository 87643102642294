import { combineReducers } from 'redux'
import { resettableReducer } from 'reduxsauce'
import { NotificationsManagerReducer } from 'advenio-components'
import configureStore from './CreateStore'
import epicMiddleware from '../Observables'

const resettable = resettableReducer('RESET')

export default (middlewares) => {
    /* ------------- Assemble The Reducers ------------- */
    const rootReducer = combineReducers({
        auth: require('./AuthRedux').reducer,
        authorizationStatus: require('./AuthorizationStatusRedux').reducer,
        catalogues: require('./CataloguesRedux').reducer,
        center: require('./CenterRedux').reducer,
        flujoAuthorizationStages: require('./FlowAuthorizationStagesRedux').reducer,
        job: require('./JobRedux').reducer,
        notifications: NotificationsManagerReducer,
        operativeUnit: require('./OperativeUnitRedux').reducer,
        region: require('./RegionRedux').reducer,
        resetPassword: require('./ResetPasswordRedux').reducer,
        sendNotification: require('./SendNotificationRedux').reducer,
        solicitud: require('./SolicitudRedux').reducer,
        solicitudTypes: require('./SolicitudTypesRedux').reducer,
        stageAuthorize: require('./StageAuthorizeRedux').reducer,
        user: require('./UserRedux').reducer,
    })

    return configureStore(resettable(rootReducer), [epicMiddleware, ...middlewares])
}