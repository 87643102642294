export const ENV = process.env.NODE_ENV
export const AWS_IDENTITY_POOL_ID = process.env.AWS_IDENTITY_POOL_ID
export const BUCKET = 'talisis-vacantes'
export const AWS_BUCKET_URL = process.env.AWS_BUCKET_S3
export const SENTRY_ENV = process.env.SENTRY_ENV

export const DOMAIN = process.env.API_DOMAIN

export const API_DOMAIN = SENTRY_ENV === 'development' ? `https://localhost:7797` : SENTRY_ENV === 'production' ? `https://api.${DOMAIN}/v1.0.2` : `https://api-dev.${DOMAIN}/v1.0.2`
// export const API_DOMAIN = `https://api-dev.${DOMAIN}/v1.0.2`

export const SENTRY_URL = '283240fd667c4864b7187ddf38f34ff3@sentry.talisis.com/30'

export const TENANT = process.env.TENANTID
export const AUTH365_CLIENT = process.env.AUTH365_CLIENT_ID
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID
export const PORT = process.env.PORT


const HOST = ENV === 'development' ? 'localhost' : DOMAIN

export const COOKIE_DOMAIN = HOST
export const PLATFORMS_LINKS = HOST

export const getUrl = (platform) => {
        switch (ENV) {
        case 'development':
            return platform
        default:
            return `https://${platform}.${DOMAIN}`
    }
}


export const getPlatformLink = platform => {

    return PLATFORMS_LINKS === 'localhost' ? `http://${PLATFORMS_LINKS}:8080` : SENTRY_ENV !== 'production' ? `https://${platform}-qa.${PLATFORMS_LINKS}` : `https://${platform}.${PLATFORMS_LINKS}`
}
