import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Container,
  ContainerSubtitle,
  ColumnTitle,
  ColumnSubtitle,
  ColumnStatus,
  Placeholder,
  draggableItemStyle,
  droppableContainerStyle,
} from './DragAndDropElements'
import CustomCard from './CustomCard'
import {
  Colors,
} from 'advenio-components'
import { COLORSSTAGE } from '../../Constants'

const IconCustom = styled.img`
    width: 13px;
    height: 13px;
    margin-right: 5px;
`

const checkStatus = (status) => {
    
  if (status) return '/assets/checked-inverse.svg'
  if (!status) return '/assets/cancel_icon_black.svg'
  return ''
}
const DroppableColumn = ({
  width,
  flow,
  columnId,
  flowStatus,
  flowDeleted,
  stagesNo,
  stages,
  title,
  envIsDragging,
  currentDragging,
  isDropDisabled,
  onCardClick,
  onCloumnClick,
  loadingUpdate
}) => (
  <Container status={flowStatus} width={width} >
    <ColumnTitle name={title} onClick={() => { onCloumnClick(flow) }} >
      {title} 
    </ColumnTitle>
    <ContainerSubtitle>
      <ColumnSubtitle>{stages && stagesNo} Etapas</ColumnSubtitle>
      { !Boolean(flowStatus) &&(
        <ColumnStatus status={flowStatus} >
          <IconCustom src={checkStatus(flowStatus)}/>
          {flowStatus ? 'Habilitado': 'Deshabilitado'}
        </ColumnStatus>
      )}
    </ContainerSubtitle>
    <Droppable droppableId={columnId} isDropDisabled={isDropDisabled}>
      {(prov, { isDraggingOver }) => {
        const { innerRef: dropInnerRef, placeholder: dropPlaceholder } = prov
        return (
          <div
          ref={dropInnerRef}
          style={droppableContainerStyle(isDraggingOver, false)}
          {...prov.droppableProps}
          >
          {stages && stagesNo > 0 &&
              stages.map(({node:stage}, index) => {
                if (!stage) {
                  return null
                }
                const{
                  id
                  ,nombre
                  ,status
                  ,deleted
                  ,autorizador:autoriza
                  ,etapaId
                  ,etapa
                  ,orden
                  ,descripcion
                  ,departamento:{nombre:departamentName}
                } = stage
                return (
                  <Draggable key={id} draggableId={id} isDragDisabled={loadingUpdate} index={index}>
                    {(provided, snapshot) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={draggableItemStyle(
                            provided.draggableProps.style,
                            snapshot.isDragging,
                          )}
                        >
                          <CustomCard
                            onCardClick={onCardClick}
                            stage={stage}
                            // isNew={isNew}
                            name={nombre || ''}
                            catStageId={etapaId}
                            orden={orden}
                            description={descripcion||''}
                            departament={departamentName||''}
                            // contact={phonne || ''}
                            // avatar={'assets/parents.svg'}
                            // reminder={next_reminder && moment(next_reminder)}
                            // reminder_name={next_reminder_name}
                            // childrenNum={no_of_active_children}
                            isDragging={snapshot.isDragging}
                            isActive={currentDragging == id}
                            deleted={deleted}
                            status={status}
                            // last_name_paternal={last_name_paternal}
                            // comments={comments}
                            // phone={phone}
                            id={id}
                            color={etapa.color}
                            autorize={autoriza}
                            // users={users}
                            // user={user}
                          />
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                )
              })}
            {dropPlaceholder}
            <Placeholder
              // Hide placeholder on source column and if the column is the destination
              visible={envIsDragging && !isDraggingOver && envIsDragging.droppableId !== columnId}
            />
          </div>
        )
      }}
    </Droppable>
  </Container>
)

DroppableColumn.propTypes = {
  columnId: PropTypes.string.isRequired,
  cards: PropTypes.array,
  title: PropTypes.string.isRequired,
  envIsDragging: PropTypes.object,
  currentDragging: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

DroppableColumn.defaultProps = {
  envIsDragging: null,
  currentDragging: null,
  cards: [],
}

export default DroppableColumn
