import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { equals, clone, isEmpty, pluck, findIndex, indexOf, isNil, propEq, comparator, sort, differenceWith } from 'ramda'
import { connect } from 'react-redux'
import { Checkbox as OriginalCheckbox, Select, Button, Colors, TextIcon } from 'advenio-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Form } from 'formsy-react'
import { parseDataGraphQL, stringContainsAnyOf } from '../../utils/Utils'
// import { FormContainer } from '../Solicitud/SolicitudForm'

export const FormContainer = styled.div`
  margin: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 0 !important;
  padding-right: 0 !important;
`
export const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
`
export const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const SubTitle = styled.div`
  justify-content: center;
  display: flex;
  margin-top: -20px;
  color: ${Colors.gray700};
  margin-bottom: 30px;
  font-size: 24px;
`

const ContainerCheckBox = styled.div`
  display: flex;
  justify-content: center;
`
const ButtonContainer = styled.div`
  margin-top: 28px;
  margin-bottom: 28px;
`

const cmpStages = (a,b) => a.node.etapa.id === b.node.etapa.id

const Checkbox = ({ label, name, value }) => (
  <ContainerCheckBox>
    <OriginalCheckbox
      inputAlign
      invertedColors
      large
      hideEmptyHelpBlock
      label={label}
      name={name}
      value={value}
    />
  </ContainerCheckBox>
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool,
}

const emptyStage = {
  'node':{
    'etapa':{'id':null},
    'autorizador':{'id':null},
    'departamento':{'id':null},
  }
}

class FlowAuthorizationDetail extends Component {
  state = {
    stages:[],
    stagesOptions:[],
    errorMessage:null,
  }

  
  componentWillMount() {
    const { flow, catStagesOptions } = this.props
    let stages = []
    let stagesOptions = []
    if(!isEmpty(flow) && flow.autorizacionSpec !== undefined){
      if( flow.autorizacionSpec.edges.length > 0){
        stages = clone(flow.autorizacionSpec.edges)
      }
    }
    if(catStagesOptions.length > 1){
      stagesOptions = catStagesOptions
    }

    this.setState({stages, stagesOptions})
  }

  componentDidMount = () => {
  }

  addStage = () => {
    let { stages } = this.state
    stages.push(emptyStage)
    
    this.setState({stages})
  }
  
  deleteStage = (index) =>{
    let { stages } = this.state
    
    stages = Array.from(stages)
    stages.splice(index,1)
    this.setState({stages})
  }

  changeForm = (data) => {
    const patt = RegExp(/-[0-9]*$/)
    const pattNumber = RegExp(/[0-9]*$/)
    // const pattName = RegExp(/^([A-Za-z]?+[_]?+[A-Za-z]+)*$/)
    // const pattName = RegExp(/(?:[A-Za-z]+[_]?[A-Za-z])+/)
    // const pattName = RegExp(/(?:[a-zA-Z]*[_]*[a-zA-Z]+)*/)
    const pattName = RegExp(/^([a-zA-Z]*[_]*[a-zA-Z])+/)
    let stages = clone(this.state.stages)
    Object.keys(data).map((value,index)=>{
      let result = {'id':null}
      const [idx] = value.match(pattNumber)
      const [name] = value.match(pattName)
      if (value.match(patt)){
        if(data[value] !== ''){
          result = {'id': data[value]}
        }
        stages[idx].node[name] = result        
      }
    })
    this.props.handledErrorMessage(null)
    this.setState({stages})
    
    return 
  }

  // changeStage = ({target:{value}}) => {
  //   let { stagesOptions,stages } = this.state
    
  //   this.setState({stagesOptions})
  // }

  submitFlow = (data) => {
    const { editFlow,handledErrorMessage } = this.props
    const { stages } = this.state
    let stageIds = []
    let duplicateStage = stages.filter((value,index)=>{
      if(!!stringContainsAnyOf(value.node.etapa.id,stageIds )){
        return value
      }
      stageIds.push(value.node.etapa.id)
    })

    if (stages.length === 0){
      handledErrorMessage('Tienes que agregar al menos 1 Etapa.')
      return 
    }
    if (duplicateStage.length > 0){
      handledErrorMessage('Revisa que no se esté duplicando las Etapas')
      return 
    }
    // let isDuplicate = stageIds.filter((item, index) => index !== stageIds.indexOf(item));
    let payload ={
      stagesIds: parseDataGraphQL(stages).map((stage,idx)=>{
        let etapa = {}
        Object.keys(stage).map((value,index)=>{
          if(stringContainsAnyOf(value,['etapa','autorizador']) || value === 'departamento'){
            etapa[value+'Id'] = stage[value].id
          }
        })
        return etapa
      }),
      flujoId:data.flujoId,
      status: data.status
    }
    editFlow(payload)
  }

  renderStages = () => {
    const { stages, stagesOptions } = this.state
    const { catUsersOptions, catDeptoRhOptions, loading } = this.props
    return stages.map(({node:s},index) => ( 
      <Row key={index}>
          <Col sm={12} md={4}>

            <Select
              name={`etapa-${index}`}
              label={`${index+1} etapa`}
              required
              value={s.etapa.id}
              options={stagesOptions}
            />
          </Col>
          <Col sm={12} md={3}>
            <Select
              name={`autorizador-${index}`}
              label='Aurorizador'
              required
              value={s.autorizador.id}
              options={catUsersOptions}
            />
          </Col>
          <Col sm={12} md={3}>
            <Select
              name={`departamento-${index}`}
              label='Departamento'
              required
              value={s.departamento.id}
              options={catDeptoRhOptions}
            />
          </Col> 
          <Col sm={12} md={2}>
            {/* <ButtonContainer> */}
              <Button danger small onClick={() =>{this.deleteStage(index)}} loading={loading}>
                <TextIcon label="Etapa" iconType="remove" iconViewBox="-2 -2 24 24" />
              </Button>
            {/* </ButtonContainer> */}
          </Col> 
      </Row>
    ))
  }

  render() {
    const { modalType, flow, editFlow, catStagesOptions,catFlowOptions, loading} = this.props
    console.log("🚀 ~ file: FlowAuthorizationDetail.jsx:244 ~ FlowAuthorizationDetail ~ render ~ modalType:", modalType)
    return (
      <FormContainer>
        <Form
           ref={(form) => { this.form = form }}
           onValidSubmit={this.submitFlow}
           onChange={this.changeForm}
        >
        <Grid>
          <Row>
            <Col sm={12} md={4}></Col>
            <Col sm={12} md={3}>
            <Select
                name="flujoId"
                label="Nombre"
                value={ modalType === 'Alta' ? null : flow.tipo !== undefined ? flow.tipo.id : null}
                // value={null}
                options={catFlowOptions}
                disabled={modalType==='Editar'}
              />
            </Col>
            <Col sm={12} md={3}>
              <Checkbox
                name="status"
                label="Habilitado"
                value={flow.status !== undefined ? Boolean(flow.status) : null}
              />
            </Col>
            <Col sm={12} md={2}>
              {/* <ButtonContainer> */}
                <Button className={'addStage'} primary small onClick={() =>{this.addStage()}} loading={loading}>
                  <TextIcon label="Etapa" iconType="plus" iconViewBox="-2 -2 24 24" />
                </Button>
              {/* </ButtonContainer> */}
            </Col>
            </Row>
              {this.renderStages()}
            {/* <Row>
              <Col sm={12} md={4}>
                <Select
                  name='stage_id'
                  label='etapa'
                  required
                  options={[]}
                  // value={flow.autorizacionSpec !== undefined ? clone(pluck('id',pluck('etapa',pluck( 'node' ,flow.autorizacionSpec.edges)))) : null}
                  // help={'Opcion para agregar el insumo en los centros'}
                />
              </Col>
              <Col sm={12} md={4}>
                <Select
                  name='autorizator'
                  label='Aurorizador'
                  required
                  options={[]}
                  // value={flow.autorizacionSpec !== undefined ? clone(pluck('id',pluck('etapa',pluck( 'node' ,flow.autorizacionSpec.edges)))) : null}
                  // help={'Opcion para agregar el insumo en los centros'}
                />
              </Col>
              <Col sm={12} md={4}>
                <Select
                  name='departament_rh'
                  label='Departamento'
                  required
                  options={[]}
                  // value={flow.autorizacionSpec !== undefined ? clone(pluck('id',pluck('etapa',pluck( 'node' ,flow.autorizacionSpec.edges)))) : null}
                  // help={'Opcion para agregar el insumo en los centros'}
                />
              </Col> 
          </Row>*/}
          <Row>
            <Col sm={12} md={10}></Col>
            <Col sm={12} md={2}>
            <ButtonContainer>
              <Button className={'saveFlowAuth'} style={{width:'94px'}} primary small type="submit" loading={loading}>
                {/* <Icon type={'plus'} width={14} height={14} color={Colors.white300} /> */}
                Guardar
              </Button>
            </ButtonContainer>
            </Col>
          </Row>
        </Grid>
        </Form>
     </FormContainer>
    )
  }
}

FlowAuthorizationDetail.propTypes = {
}

FlowAuthorizationDetail.defaultProps = {
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FlowAuthorizationDetail)

// QXV0aG9yaXphdGlvbkZsb3dTcGVjT2JqZWN0OjI
// QXV0aG9yaXphdGlvblR5cGVDYXRhbG9nT2JqZWN0OjI