import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import styled from 'styled-components'
import { Form } from 'formsy-react'
import { Button, Colors, GlobalStyles, Title1, Title2, TextInput } from 'advenio-components'
import ResetPasswordActions from '../../Data/Redux/ResetPasswordRedux'

const AdvenioLogo = styled.img`
  width: 350px;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    width: 100%;
  }
`
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
`
const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Card = styled.div`
  ${GlobalStyles.borderRadius};
  width: 100%;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0,0,0, .1);

  @media (min-width: 768px) {
    min-width: 400px;
  }
`
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px 0;

  @media (max-width: 767px) {
    padding: 16px 8px 0;
  }
`
const Heading = styled(Title2)`
  color: ${Colors.blueText};
`
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;

  @media (max-width: 767px) {
    padding: 16px;
  }
`
const ErrorContainer = styled.div`
  ${GlobalStyles.borderRadius};
  padding: 16px;
  background-color: ${Colors.red100};
  border: 1px solid ${Colors.red300};
`
const ErrorTitle = styled(Title1)`
  color: ${Colors.redText};
`
const ErrorText = styled.p`
  display: block;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 14px;
  color: ${Colors.redText};
`
const Back = styled.span`
  color: ${Colors.blue500};
  cursor: pointer;
`
const CenteredDiv = styled.div`
  text-align: center;
`

class ResetPassword extends Component {
  componentWillMount() {
    this.props.setSuccessFalse()
  }

  resetPassword = email => this.props.requestPasswordReset(email)

  backToLogin = () => {
    this.props.goTo('/login')
  }

  render() {
    const { loading, resetError, success } = this.props

    return (
      <Container>
        <ContentWrapper>
          <AdvenioLogo src="assets/talisis_logo.png" />
          <Card>
            <CardHeader>
              <Heading>{success ? '¡Listo!' : 'Recuperar Contraseña'}</Heading>
            </CardHeader>
            <CardBody>
              {resetError && (
                <ErrorContainer>
                  <ErrorTitle>Error:</ErrorTitle>
                  <ErrorText>{resetError}</ErrorText>
                </ErrorContainer>
              )}
              {success ?
                <Form>
                  <CenteredDiv>
                    Se te ha enviado un correo.<br />
                    Sigue las instrucciones para recuperar tu contraseña.
                  </CenteredDiv>
                </Form>
                :
                <Form onSubmit={this.resetPassword}>
                  <TextInput
                    type="email"
                    id="email"
                    name="email"
                    label="Correo"
                  />
                  <Button type="submit" loading={loading} primary fullWidth>Solicitar</Button>
                </Form>
              }
            </CardBody>
          </Card>
          <Back onClick={this.backToLogin}>Regresar a la página de ingreso</Back>
        </ContentWrapper>
      </Container>
    )
  }
}

ResetPassword.propTypes = {
  /** Loading flag */
  loading: PropTypes.bool.isRequired,
  /** Error message returned if the password reset failed */
  resetError: PropTypes.string.isRequired,
  /** Flag marking whether the Password Reset request was successful */
  success: PropTypes.bool.isRequired,
  /** Navigate to the given URI */
  goTo: PropTypes.func.isRequired,
  /** Submit the password reset to the API */
  requestPasswordReset: PropTypes.func.isRequired,
  /** Reset the 'success' flag value */
  setSuccessFalse: PropTypes.func.isRequired,
}

const mapStateToProps = ({ resetPassword }) => ({
  loading: resetPassword.sending.fetching,
  resetError: resetPassword.error,
  success: resetPassword.success,
})

const mapDispatchToProps = dispatch => ({
  requestPasswordReset: email => dispatch(ResetPasswordActions.resetPasswordRequest(email)),
  setSuccessFalse: () => dispatch(ResetPasswordActions.setSuccessFalse()),
  goTo: path => dispatch(push(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
