import createCRUDObservable from 'redux-observable-crud'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { gql } from '@apollo/client';
import R,{isEmpty} from 'ramda'
import { ApolloError, isApolloError } from '@apollo/client/errors'
import { notificationFail, notificationSuccess } from '../Redux/NotificationActions'
import CataloguesActions, { CataloguesRedux, CataloguesTypes } from '../Redux/CataloguesRedux'
import { CATALOGUES } from '../GraphQL'
import { parseDataGraphQL, paramsGraphQL, paramsGraphQLPagination } from '../../utils/Utils'

const cataloguesCrudObservable = createCRUDObservable({
  mainRedux: CataloguesRedux,
  reduxPath: 'catalogues',
})

const getCatalogDptoRhEpic = (action$, store, { Api }) => (
  action$.ofType(CataloguesTypes.catalogDptoRhRequest)
    .mergeMap(({data}) => {
      let getAllCatDeptosRh=''
      let sendData={}
      let params = '', filters = '',binding = '', paramsGroup = ''
      if(! isEmpty(data.data)){
        ({ params, binding } = paramsGraphQL(CATALOGUES.catalguesAttr, data.data))
        sendData={...data.data}
        filters= `(filters:{ ${binding} })`
        paramsGroup = `(${params})`
      }
      const createQuery = CATALOGUES.getAllCatDeptosRh.replace('$params',paramsGroup).replace('$filters',filters)

      getAllCatDeptosRh = gql`${createQuery}`
      sendData={...sendData ,}

      return Observable
        .fromPromise(
          Api.query({query: getAllCatDeptosRh,variables: sendData, fetchPolicy: 'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              CataloguesActions.catalogDptoRhError(response['message'])
            ]
          }

          const { allCatDeptosRh:{ edges } } = response.data

          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              CataloguesActions.catalogDptoRhSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            CataloguesActions.catalogDptoRhSuccess(data),
          ]
        })
      })
)
const getAllCatStagesEpic = (action$, store, { Api }) => (
  action$.ofType(CataloguesTypes.catalogStagesRequest)
    .mergeMap(({data}) => {
      let getAllCatStages=''
      let sendData={}
      let params = '', filters = '',binding = '', paramsGroup = ''
      if(! isEmpty(data.data)){
        ({ params, binding } = paramsGraphQL(CATALOGUES.catalguesAttr, data.data))
        sendData={...data.data}
        filters= `(filters:{ ${binding} })`
        paramsGroup = `(${params})`
      }
      const createQuery = CATALOGUES.getAllCatStages.replace('$params',paramsGroup).replace('$filters',filters)

      getAllCatStages = gql`${createQuery}`
      sendData={...sendData ,}

      return Observable
        .fromPromise(
          Api.query({query: getAllCatStages,variables: sendData, fetchPolicy: 'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              CataloguesActions.catalogStagesError(response['message'])
            ]
          }

          const { allCatEtapas:{ edges } } = response.data

          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              CataloguesActions.catalogStagesSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            CataloguesActions.catalogStagesSuccess(data),
          ]
        })
      })
)
const getAllCatAuthorizationTypesEpic = (action$, store, { Api }) => (
  action$.ofType(CataloguesTypes.catAuthorizationTypesRequest)
    .mergeMap(({data}) => {
      let getAllCatAuthorizationTypes=''
      let sendData={}
      let params = '', filters = '',binding = '', paramsGroup = ''
      if(! isEmpty(data.data)){
        ({ params, binding } = paramsGraphQL(CATALOGUES.catalguesAttr, data.data))
        sendData={...data.data}
        filters= `(filters:{ ${binding} })`
        paramsGroup = `(${params})`
      }
      const createQuery = CATALOGUES.getAllCatAuthorizationTypes.replace('$params',paramsGroup).replace('$filters',filters)
      getAllCatAuthorizationTypes = gql`${createQuery}`
      sendData={...sendData ,}

      return Observable
        .fromPromise(
          Api.query({query: getAllCatAuthorizationTypes,variables: sendData, fetchPolicy: 'no-cache'})
          .then(response => response)
          .catch(err=> err)
        )
        .flatMap((response) => {

          if (response instanceof ApolloError) {
            
            return [
              notificationFail(response['message']),
              CataloguesActions.catAuthorizationTypesError(response['message'])
            ]
          }

          const { allCatTiposAutorizacion:{ edges } } = response.data

          if(isEmpty(edges)){
            return [
              notificationFail('No se encontraron resultados!.'),
              CataloguesActions.catAuthorizationTypesSuccess([]),
            ]
          }
          const data = parseDataGraphQL(edges)

          return [
            CataloguesActions.catAuthorizationTypesSuccess(data),
          ]
        })
      })
)

export const cataloguesObservers = Object.assign({}, cataloguesCrudObservable.observers, {})

export const cataloguesEpic = combineEpics(
  cataloguesCrudObservable.epic,
  getCatalogDptoRhEpic,
  getAllCatStagesEpic,
  getAllCatAuthorizationTypesEpic,
)
