import { gql } from '@apollo/client';

const stagesByAuthorizeAttr = {
    '$autorizador': 'ID',
    '$autorizadorDirecto': 'ID',
    '$jefeDirecto': 'String',
    '$statusIdIn': `[Int!]`,
    '$deleted': 'Int',
    '$after': 'String',
    '$first': 'Int',
    '$before': 'String',
    '$last': 'Int'
}
const getStagesByAuthorize = `
query
allSolicitudEtapas($params){
  allSolicitudEtapas(
      filters: { $binding }
    )
    {
      edges {
        node {
          id
          nombre
          descripcion
          autorizador {
            nombre
            correo
          }
          flujo {
            solicitud {
              id
              nombrePuesto {
                id
                nombre
              }
              nombrePuestoDo
              fecha
              ou{
                id
                nombre
                codigoInterno
              }
              correoJefeDirecto
              usuario {
                nombre
                correo
              }
            }
          }
          status {
            id
            codigoInterno
            nombre
          }
          etapa {
            etapa {
              codigoInterno
            }
          }
        }
      }
    }
}`
const getAuthorizationStatus = gql `
query
allCatEstatus{
  allCatEstatus(filters: { codigoInternoIn: [ "AUTORIZADO", "RECHAZADO", "EN-VALIDACION"] }) {
      edges {
          node {
              id
              nombre
          }
      }
  }
}
`
const getNextStatus = gql `
query
catEstatus($id: ID!){
  catEstatus(id: $id) {
    id
    nombre
    codigoInterno
  }
}
`
    // const getStagesByAuthorize = gql`
    // query
    // allSolicitudEtapas($autorizador:ID!, $statusIdIn:[Int!]) {
    //   allSolicitudEtapas(filters: {autorizador:$autorizador, statusIdIn:$statusIdIn}) {
    //     edges {
    //       node {
    //         id
    //         nombre
    //         descripcion
    //         autorizador {
    //           nombre
    //           correo
    //         }
    //         flujo {
    //           solicitud {
    //             id
    //              nombrePuesto {
    // id
    // nombre
    // }
    //             nombrePuestoDo
    //             fecha
    //             ou{
    //               id
    //               nombre
    //               codigoInterno
    //             }
    //             correoJefeDirecto
    //             usuario {
    //               nombre
    //               correo
    //             }
    //           }
    //         }
    //         status {
    //           id
    //           codigoInterno
    //           nombre
    //         }
    //         etapa {
    //           etapa {
    //             codigoInterno
    //           }
    //         }
    //       }
    //     }
    //   }
    // }`

export {
    getStagesByAuthorize,
    stagesByAuthorizeAttr,
    getAuthorizationStatus,
    getNextStatus,
}