import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, Colors } from 'advenio-components'
import { isEmpty } from 'ramda'
import { STATUS } from '../../../Constants'
import { PanelHeader } from '../../ProfilePanel/ProfilePanel.styles'
import moment from 'moment'
import { notificationFail, notificationSuccess } from '../../../Data/Redux/NotificationActions'
import AuthorizationStatusActions from '../../../Data/Redux/AuthorizationStatusRedux'
import SolicitudActions from '../../../Data/Redux/SolicitudRedux'
import { AWS_BUCKET_URL } from '../../../config'

// const Container = styled.div`
//   width: 100%;
//   height: 62vh;
//   display: flex;
//   `
const DocumentContainer = styled.div`
  margin-top:10px
`
const DocumentLink = styled.span`
  cursor: ${ props => props.isArchivo ? 'pointer':'default'};
  color: ${ props => props.isArchivo ? Colors.blue500:'black'};
`
const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 120px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
`
const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin-top: 4%;
`
const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const Checkbox = ({ label, name, value }) => (
  <OriginalCheckbox
    invertedColors
    large
    hideEmptyHelpBlock
    label={label}
    name={name}
    value={value}
  />
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}

class AutorizationADP extends Component {
  state={
    flawSend:false,
    dataSend:{}
  }
  componentWillMount() {
    const { info } = this.props
  }
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const {
      validSolicitud,
      messageFail,
      updatedSolicitud,
      user,
      setSolicitudStageData,
      getNextStatus,
    } = nextProps
    const { flawSend, dataSend } = this.state

    if(flawSend && ! isEmpty(dataSend) && !isEmpty(validSolicitud)){
      if(validSolicitud.status.codigoInterno === STATUS.authorized || validSolicitud.status.codigoInterno === STATUS.rejected){
        messageFail('No se puede cambiar el estatus de una etapa previamente autorizada/rechazada')
      }else{
        let dataNextStatus = {
            'id':dataSend.statusId
          }

        dataSend.usuarioId = user.id

        setSolicitudStageData(dataSend)
        getNextStatus(dataNextStatus)

      }
    }
  }

  onSubmit= (data) =>{
    const {  match:{ params: { solicitudStageId }}, validateSolitictud, info } = this.props
    const dataSend = {
      ...data,
      'id':solicitudStageId,
    }
    this.setState({dataSend, flawSend:true})
    if(data.statusId !== 0){
      validateSolitictud({
        'id':solicitudStageId
      })
    }
  }

  renderOptions = () => [{id:0,label:"Seleccionar",value:0}].concat(this.props.status.map(s=>({
    id:s.id
    , label:s.nombre
    , value: s.id
  })))

  openfile=(path )=>{
    if(path !== null){
      window.open(AWS_BUCKET_URL+path)
    }
  }

  render() {
    const {
      info,
      solicitudStage
    } = this.props

    return (
      <Container>
        <PanelHeader tabTitle >Captura de Datos</PanelHeader>
        <Grid>
        <Form
          ref={(form) => { this.form = form }}
          onSubmit={this.onSubmit}
        >
            <Row>
              {info.documentos.map((documento,index) => (
                <Col key={index} sm={12} md={3} lg={3}>
                  <DocumentContainer>
                    <DocumentLink isArchivo={!! documento.archivo}  onClick={()=> {this.openfile(documento.archivo || null)}} >{documento.nombre}</DocumentLink>
                  </DocumentContainer>
                </Col>
              ))}
            </Row>
            <Row>
              <Col sm={12} md={12} lg={6}>
                <TextInput
                  name="comentarios"
                  label="Comentarios"
                  value={info.comentarios || null}
                  textarea
                  required
                />
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Select
                  name="statusId"
                  label="Autorización"
                  required
                  value={info.status.id || null}
                  options={this.renderOptions()}
                  fullWidth
                />
              </Col>
            </Row>
            <Row end="xs">
              <Col sm={12} md={12}>
                <Button primary disabled={info.status.codigoInterno === STATUS.authorized } style={{marginBottom:12}} loading={false} type="submit" >
                  {/* <TextIcon label="Subir archivos" iconType="plus" iconViewBox="-2 -2 24 24" /> */}
                  Guardar
                </Button>
              </Col>
            </Row>
        </Form>
          </Grid>
      </Container>
    )
  }
}

AutorizationADP.propTypes = {
}

AutorizationADP.defaultProps = {

}

const mapStateToProps = state => ({
  user: state.auth.user,
  status: state.authorizationStatus.get.results,
  validSolicitud: state.solicitud.validSolicitud.result,
})

const mapDispatchToProps = dispatch => ({
  validateSolitictud: (data) => dispatch(SolicitudActions.validateSolicitudRequest(data)),
  updatedSolicitud: (data) => dispatch(SolicitudActions.updateSolicitudRequest(data)),
  getNextStatus: (data) => dispatch(AuthorizationStatusActions.getNextStatusRequest(data)),
  messageFail: msg => dispatch(notificationFail(msg)),
  setSolicitudStageData: data => dispatch(SolicitudActions.setSolicitudStageData(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutorizationADP))
