
export const COLORS = {
  RED:'#e83f3a',
  BLUE:'#1D1933'
}

export const ROLES = {
  STAFF_CH: 'Um9sZU9iamVjdDoz',
  ADMIN_VACANTES: 'Um9sZU9iamVjdDox',
  RECLUTADOR_STAFF: 'Um9sZU9iamVjdDoy',
}

export const COLORSOU = {
  TALISIS:'#1D1933',
  UNID: '#1435A4',
  UERRE:'#50B83C',
  ADVENIO:'#F92135',
  HH: '#FAED00',
  IESalud:'#00ACAC'
}
export const COLORSSTAGE = {
  "U3RlcENhdGFsb2dPYmplY3Q6MQ==":'#f44336',
  "U3RlcENhdGFsb2dPYmplY3Q6Mg==":'#e91e63',
  "U3RlcENhdGFsb2dPYmplY3Q6Mw==":'#9c27b0',
  "U3RlcENhdGFsb2dPYmplY3Q6NA==":'#673ab7',
  "U3RlcENhdGFsb2dPYmplY3Q6NQ==":'#3f51b5',
  "U3RlcENhdGFsb2dPYmplY3Q6Ng==":'#2196f3',
  "U3RlcENhdGFsb2dPYmplY3Q6Nw==":'#03DDF4',
  "U3RlcENhdGFsb2dPYmplY3Q6OA==":'#00d4c0',
  "U3RlcENhdGFsb2dPYmplY3Q6OQ==":'#009688',
  "U3RlcENhdGFsb2dPYmplY3Q6MTA=":'#4caf50',
  "U3RlcENhdGFsb2dPYmplY3Q6MTE=":'#8bc34a',
  "U3RlcENhdGFsb2dPYmplY3Q6MTI=":'#cddc39',
  "U3RlcENhdGFsb2dPYmplY3Q6MTM=":'#ffeb3b',
  "U3RlcENhdGFsb2dPYmplY3Q6MTQ=":'#ffc107',
  "U3RlcENhdGFsb2dPYmplY3Q6MTU=":'#ff9800',
  "U3RlcENhdGFsb2dPYmplY3Q6MTY=":'#ff5722',
  "U3RlcENhdGFsb2dPYmplY3Q6MTc=":'#795548',
  "U3RlcENhdGFsb2dPYmplY3Q6MTg=":'#848484',
  "U3RlcENhdGFsb2dPYmplY3Q6MTk=":'#212121',
  "U3RlcENhdGFsb2dPYmplY3Q6MjA=":'#212121',
  "U3RlcENhdGFsb2dPYmplY3Q6MjE=":'#d50000',
  "U3RlcENhdGFsb2dPYmplY3Q6MjI=":'#1a237e',
  "U3RlcENhdGFsb2dPYmplY3Q6MjM=":'#18ffa8',
  "U3RlcENhdGFsb2dPYmplY3Q6MjQ=":'#76ff03',
  "U3RlcENhdGFsb2dPYmplY3Q6MjU=":'#679BB4',
  "U3RlcENhdGFsb2dPYmplY3Q6MjY=":'#FB00FB',
}

export const OUID = {
  TALISIS:'T3VDYXRhbG9nT2JqZWN0OjE=',
  UNID: 'T3VDYXRhbG9nT2JqZWN0OjI=',
  UERRE:'T3VDYXRhbG9nT2JqZWN0OjM=',
  ADVENIO:'T3VDYXRhbG9nT2JqZWN0OjQ=',
  HH: 'T3VDYXRhbG9nT2JqZWN0OjU=',
  IESalud:'T3VDYXRhbG9nT2JqZWN0OjY='
}

export const TYPE_SOLICITUD={
  NEW_STAFF:'NUEVA-STAFF',
  REPLACEMENT_STAFF:'REEMPLAZO-STAFF',
  NEW_OPERATION:'NUEVA-OPERACION',
  REPLACEMENT_OPERATION:'REEMPLAZO-OPERACION',
}

export const AUTORIZATIONTYPEID = {
  NEW_STAFF:'QXV0aG9yaXphdGlvblR5cGVDYXRhbG9nT2JqZWN0OjE=',
  REPLACEMENT_STAFF: 'QXV0aG9yaXphdGlvblR5cGVDYXRhbG9nT2JqZWN0OjI=',
  NEW_OPERATION: 'QXV0aG9yaXphdGlvblR5cGVDYXRhbG9nT2JqZWN0OjM=',
  REPLACEMENT_OPERATION:'QXV0aG9yaXphdGlvblR5cGVDYXRhbG9nT2JqZWN0OjQ=',
}

export const SOLICITUDTYPEID = {
  NEW_STAFF:'U29saWNpdHVkVGlwb09iamVjdDox',
  REPLACEMENT_STAFF: 'U29saWNpdHVkVGlwb09iamVjdDoy',
  REPLACEMENT_OPERATION:'U29saWNpdHVkVGlwb09iamVjdDo0',
}

 export const SOLICITUD_STAGES = {
  aut_vacante:'AUTORIZACION-VACANTE',
  aut_do:'AUTORIZACION-DO',
  aut_comp:'AUTORIZACION-COMPENSACIONES',
  pub_job:'PUBLICACION-BOLSA',
  sel_ter:'SELECCION-TERNA',
  pro_int:'PROGRAMAR-ENTREVISTAS',
  sel_can:'SELECCION-CANDIDATO',
  sign_cont:'FIRMA-CONTRATO',
  aut_director:'AUTORIZACION-DIRECTOR',
  aut_eficency:'AUTORIZACION-EFICIENCIAS',
  aut_ch:'AUTORIZACION-CH',
  check_do:'REVISION-DO',
  aut_dir_gral:'AUT-DIRECCION-GRAL',
  aut_dir_unid_hh:'AUT-DIRECTOR-UNID-HH',
  aut_dir_ope_tal:'AUT-DIR-OPERACIONES=TALISIS',
  aut_rector_reg:'AUT-RECTOR-REGIONAL',
  info_adl_sol:'INFO-ADICIONAL-SOLICITANTE',
  asg_rec:'ASIGNAR-RECLUTADOR',
  dir_ch_ope:'DIR-CH-OPERACIONES',
  info_adl_nom:'INFO-ADICIONAL-NOMINA',
  validation_hrbp:'VALIDACION-HRBP',
  aut_tal:'AUT-DIR-ADM-TALISIS',
  cred_ti:'CREDENCIALES-TI',
  sel_compu:'SELECCION-EQUIPO',
  aut_cand:'AUTOSERVICIO-CANDIDATO',
 }

 export const STATUS ={
   in_progess:'EN-PROCESO',
   in_authorization:'EN-AUTORIZACION',
   in_validation:'EN-VALIDACION',
   authorized:'AUTORIZADO',
   rejected:'RECHAZADO',
   info_completed:'INFO-COMPLETA',
   finalized:'FINALIZADO',
   not_applicable:'NO-APLICA',
   assigned_recruiter:'RECLUTADOR-ASIGNADO',
   canceled:'CANCELADO',
 }

 export const DEPARMENT_RH = {
  authorize:'AUTORIZA',
  recuit:'RECLUTA',
  onboarding:'ONBOARDING',
 }

 export const TABS_PROFILE = {
  infoGeneral: 'Información General',
  dataCapture: 'Captura de Datos',
  commPrevious: 'Comentarios Previos',
  infoCompensation: 'Información Compensacion',
  infoAditional: 'Información Solicitante',
}


export const exeObjext=[{
  "id": "U29saWNpdHVkT2JqZWN0OjY=",
  "nombrePuesto": {
    "id": "UHVlc3RvQ2F0YWxvZ09iamVjdDoxNg==",
    "nombre": "DIRECTOR OFICINA DE TRANSFORMACIÓN",
    "__typename": "PuestoCatalogObject"
  },
  "nombrePuestoDo": null,
  "statusId": 1,
  "ou": {
    "nombre": "Unid",
    "codigoInterno": "UNID",
    "__typename": "OuCatalogObject"
  },
  "centro": {
    "nombre": "Corporativo Monterrey",
    "__typename": "CentroCatalogObject"
  },
  "status": {
    "nombre": "En proceso",
    "__typename": "StatusCatalogObject"
  },
  "fecha": "2022-08-31T17:03:34.320000",
  "tipoSolicitud": {
    "nombre": "Reemplazo STAFF",
    "__typename": "SolicitudTipoObject"
  },
  "usuario": {
    "nombre": "Jose Valero",
    "correo": "jose.valero@talisis.com",
    "__typename": "UserObject"
  },
  "flujo": {
    "edges": [
      {
        "node": {
          "etapas": {
            "edges": [
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3Nw==",
                  "nombre": "Autorización / Director del área",
                  "descripcion": "Da la autorización",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Humberto Salazar",
                    "correo": "humberto.salazar@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DIRECTOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDoy",
                    "codigoInterno": "EN-AUTORIZACION",
                    "nombre": "En autorización",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OA==",
                  "nombre": "Validación HRBP",
                  "descripcion": "Valida cambio de perfil o sueldo en reemplazo de vacantes",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "VALIDACION-HRBP",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OQ==",
                  "nombre": "Autorización / Compensaciones",
                  "descripcion": "Revisa solicitud y valida que la posición este en el presupuesto.\nIndica el sueldo propuesto y el nivel de la posición",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-COMPENSACIONES",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MA==",
                  "nombre": "Autorización / DO",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MQ==",
                  "nombre": "Autorización / Director Capital Humano Talisis",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-CH",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mg==",
                  "nombre": "Asignación de reclutador",
                  "descripcion": "El responsable de atracción de talento STAFF asigna al reclutador que llevará el proceso",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "ASIGNAR-RECLUTADOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mw==",
                  "nombre": "Publicación en bolsa de trabajo",
                  "descripcion": "Publicación en bolsa de trabajo para recepción de curriculums.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-newspaper-o",
                      "codigoInterno": "PUBLICACION-BOLSA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NA==",
                  "nombre": "Selección",
                  "descripcion": "Atracción de talento selecciona los mejores candidatos.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-group",
                      "codigoInterno": "SELECCION-TERNA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NQ==",
                  "nombre": "Programación de entrevistas",
                  "descripcion": "Programación de entrevistas con los diferentes candidatos",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-calendar",
                      "codigoInterno": "PROGRAMAR-ENTREVISTAS",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Ng==",
                  "nombre": "Selección de candidato",
                  "descripcion": "Solicitante selecciona al mejor candidato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-male",
                      "codigoInterno": "SELECCION-CANDIDATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Nw==",
                  "nombre": "Selección de equipo de computo",
                  "descripcion": "Selección del equipo de computo/telefono de acuerdo al puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "SELECCION-EQUIPO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OA==",
                  "nombre": "Proceso administrativo TI",
                  "descripcion": "Alta de cuentas de acceso a las diferentes plataformas",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "CREDENCIALES-TI",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OQ==",
                  "nombre": "Proceso administrativo ADP",
                  "descripcion": "El área de Nómina proveé los datos necesarios para el contrato",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Claudia Paez",
                    "correo": "claudia.paez@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "INFO-ADICIONAL-NOMINA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo5MA==",
                  "nombre": "Firma de contrato",
                  "descripcion": "Capital Humano firma de contrato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-edit",
                      "codigoInterno": "FIRMA-CONTRATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              }
            ],
            "__typename": "SolicitudStepObjectConnection"
          },
          "__typename": "SolAuthorizationFlowObject"
        },
        "__typename": "SolAuthorizationFlowObjectEdge"
      }
    ],
    "__typename": "SolAuthorizationFlowObjectConnection"
  },
  "__typename": "SolicitudObject",
  "stage_current": "Autorización / Director del área",
  "circleBarAuthorization": 0,
  "dataAuthorization": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proAuthorization": 0,
  "circleBarRecruitment": 0,
  "dataRecruitment": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proRecruitment": 0
},{
  "id": "U29saWNpdHVkT2JqZWN0OjY=",
  "nombrePuesto": {
    "id": "UHVlc3RvQ2F0YWxvZ09iamVjdDoxNg==",
    "nombre": "DIRECTOR OFICINA DE TRANSFORMACIÓN",
    "__typename": "PuestoCatalogObject"
  },
  "nombrePuestoDo": null,
  "statusId": 1,
  "ou": {
    "nombre": "UERRE",
    "codigoInterno": "UERRE",
    "__typename": "OuCatalogObject"
  },
  "centro": {
    "nombre": "Corporativo Monterrey",
    "__typename": "CentroCatalogObject"
  },
  "status": {
    "nombre": "En proceso",
    "__typename": "StatusCatalogObject"
  },
  "fecha": "2022-08-31T17:03:34.320000",
  "tipoSolicitud": {
    "nombre": "Reemplazo STAFF",
    "__typename": "SolicitudTipoObject"
  },
  "usuario": {
    "nombre": "Jose Valero",
    "correo": "jose.valero@talisis.com",
    "__typename": "UserObject"
  },
  "flujo": {
    "edges": [
      {
        "node": {
          "etapas": {
            "edges": [
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3Nw==",
                  "nombre": "Autorización / Director del área",
                  "descripcion": "Da la autorización",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Humberto Salazar",
                    "correo": "humberto.salazar@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DIRECTOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDoy",
                    "codigoInterno": "EN-AUTORIZACION",
                    "nombre": "En autorización",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OA==",
                  "nombre": "Validación HRBP",
                  "descripcion": "Valida cambio de perfil o sueldo en reemplazo de vacantes",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "VALIDACION-HRBP",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OQ==",
                  "nombre": "Autorización / Compensaciones",
                  "descripcion": "Revisa solicitud y valida que la posición este en el presupuesto.\nIndica el sueldo propuesto y el nivel de la posición",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-COMPENSACIONES",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MA==",
                  "nombre": "Autorización / DO",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MQ==",
                  "nombre": "Autorización / Director Capital Humano Talisis",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-CH",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mg==",
                  "nombre": "Asignación de reclutador",
                  "descripcion": "El responsable de atracción de talento STAFF asigna al reclutador que llevará el proceso",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "ASIGNAR-RECLUTADOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mw==",
                  "nombre": "Publicación en bolsa de trabajo",
                  "descripcion": "Publicación en bolsa de trabajo para recepción de curriculums.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-newspaper-o",
                      "codigoInterno": "PUBLICACION-BOLSA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NA==",
                  "nombre": "Selección",
                  "descripcion": "Atracción de talento selecciona los mejores candidatos.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-group",
                      "codigoInterno": "SELECCION-TERNA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NQ==",
                  "nombre": "Programación de entrevistas",
                  "descripcion": "Programación de entrevistas con los diferentes candidatos",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-calendar",
                      "codigoInterno": "PROGRAMAR-ENTREVISTAS",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Ng==",
                  "nombre": "Selección de candidato",
                  "descripcion": "Solicitante selecciona al mejor candidato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-male",
                      "codigoInterno": "SELECCION-CANDIDATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Nw==",
                  "nombre": "Selección de equipo de computo",
                  "descripcion": "Selección del equipo de computo/telefono de acuerdo al puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "SELECCION-EQUIPO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OA==",
                  "nombre": "Proceso administrativo TI",
                  "descripcion": "Alta de cuentas de acceso a las diferentes plataformas",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "CREDENCIALES-TI",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OQ==",
                  "nombre": "Proceso administrativo ADP",
                  "descripcion": "El área de Nómina proveé los datos necesarios para el contrato",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Claudia Paez",
                    "correo": "claudia.paez@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "INFO-ADICIONAL-NOMINA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo5MA==",
                  "nombre": "Firma de contrato",
                  "descripcion": "Capital Humano firma de contrato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-edit",
                      "codigoInterno": "FIRMA-CONTRATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              }
            ],
            "__typename": "SolicitudStepObjectConnection"
          },
          "__typename": "SolAuthorizationFlowObject"
        },
        "__typename": "SolAuthorizationFlowObjectEdge"
      }
    ],
    "__typename": "SolAuthorizationFlowObjectConnection"
  },
  "__typename": "SolicitudObject",
  "stage_current": "Autorización / Director del área",
  "circleBarAuthorization": 0,
  "dataAuthorization": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proAuthorization": 0,
  "circleBarRecruitment": 0,
  "dataRecruitment": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proRecruitment": 0
},{
  "id": "U29saWNpdHVkT2JqZWN0OjY=",
  "nombrePuesto": {
    "id": "UHVlc3RvQ2F0YWxvZ09iamVjdDoxNg==",
    "nombre": "DIRECTOR OFICINA DE TRANSFORMACIÓN",
    "__typename": "PuestoCatalogObject"
  },
  "nombrePuestoDo": null,
  "statusId": 1,
  "ou": {
    "nombre": "IESalud",
    "codigoInterno": "IESalud",
    "__typename": "OuCatalogObject"
  },
  "centro": {
    "nombre": "Corporativo Monterrey",
    "__typename": "CentroCatalogObject"
  },
  "status": {
    "nombre": "En proceso",
    "__typename": "StatusCatalogObject"
  },
  "fecha": "2022-08-31T17:03:34.320000",
  "tipoSolicitud": {
    "nombre": "Reemplazo STAFF",
    "__typename": "SolicitudTipoObject"
  },
  "usuario": {
    "nombre": "Jose Valero",
    "correo": "jose.valero@talisis.com",
    "__typename": "UserObject"
  },
  "flujo": {
    "edges": [
      {
        "node": {
          "etapas": {
            "edges": [
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3Nw==",
                  "nombre": "Autorización / Director del área",
                  "descripcion": "Da la autorización",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Humberto Salazar",
                    "correo": "humberto.salazar@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DIRECTOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDoy",
                    "codigoInterno": "EN-AUTORIZACION",
                    "nombre": "En autorización",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OA==",
                  "nombre": "Validación HRBP",
                  "descripcion": "Valida cambio de perfil o sueldo en reemplazo de vacantes",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "VALIDACION-HRBP",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OQ==",
                  "nombre": "Autorización / Compensaciones",
                  "descripcion": "Revisa solicitud y valida que la posición este en el presupuesto.\nIndica el sueldo propuesto y el nivel de la posición",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-COMPENSACIONES",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MA==",
                  "nombre": "Autorización / DO",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MQ==",
                  "nombre": "Autorización / Director Capital Humano Talisis",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-CH",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mg==",
                  "nombre": "Asignación de reclutador",
                  "descripcion": "El responsable de atracción de talento STAFF asigna al reclutador que llevará el proceso",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "ASIGNAR-RECLUTADOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mw==",
                  "nombre": "Publicación en bolsa de trabajo",
                  "descripcion": "Publicación en bolsa de trabajo para recepción de curriculums.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-newspaper-o",
                      "codigoInterno": "PUBLICACION-BOLSA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NA==",
                  "nombre": "Selección",
                  "descripcion": "Atracción de talento selecciona los mejores candidatos.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-group",
                      "codigoInterno": "SELECCION-TERNA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NQ==",
                  "nombre": "Programación de entrevistas",
                  "descripcion": "Programación de entrevistas con los diferentes candidatos",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-calendar",
                      "codigoInterno": "PROGRAMAR-ENTREVISTAS",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Ng==",
                  "nombre": "Selección de candidato",
                  "descripcion": "Solicitante selecciona al mejor candidato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-male",
                      "codigoInterno": "SELECCION-CANDIDATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Nw==",
                  "nombre": "Selección de equipo de computo",
                  "descripcion": "Selección del equipo de computo/telefono de acuerdo al puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "SELECCION-EQUIPO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OA==",
                  "nombre": "Proceso administrativo TI",
                  "descripcion": "Alta de cuentas de acceso a las diferentes plataformas",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "CREDENCIALES-TI",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OQ==",
                  "nombre": "Proceso administrativo ADP",
                  "descripcion": "El área de Nómina proveé los datos necesarios para el contrato",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Claudia Paez",
                    "correo": "claudia.paez@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "INFO-ADICIONAL-NOMINA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo5MA==",
                  "nombre": "Firma de contrato",
                  "descripcion": "Capital Humano firma de contrato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-edit",
                      "codigoInterno": "FIRMA-CONTRATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              }
            ],
            "__typename": "SolicitudStepObjectConnection"
          },
          "__typename": "SolAuthorizationFlowObject"
        },
        "__typename": "SolAuthorizationFlowObjectEdge"
      }
    ],
    "__typename": "SolAuthorizationFlowObjectConnection"
  },
  "__typename": "SolicitudObject",
  "stage_current": "Autorización / Director del área",
  "circleBarAuthorization": 0,
  "dataAuthorization": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proAuthorization": 0,
  "circleBarRecruitment": 0,
  "dataRecruitment": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proRecruitment": 0
},{
  "id": "U29saWNpdHVkT2JqZWN0OjY=",
  "nombrePuesto": {
    "id": "UHVlc3RvQ2F0YWxvZ09iamVjdDoxNg==",
    "nombre": "DIRECTOR OFICINA DE TRANSFORMACIÓN",
    "__typename": "PuestoCatalogObject"
  },
  "nombrePuestoDo": null,
  "statusId": 1,
  "ou": {
    "nombre": "HH ",
    "codigoInterno": "HH",
    "__typename": "OuCatalogObject"
  },
  "centro": {
    "nombre": "Corporativo Monterrey",
    "__typename": "CentroCatalogObject"
  },
  "status": {
    "nombre": "En proceso",
    "__typename": "StatusCatalogObject"
  },
  "fecha": "2022-08-31T17:03:34.320000",
  "tipoSolicitud": {
    "nombre": "Reemplazo STAFF",
    "__typename": "SolicitudTipoObject"
  },
  "usuario": {
    "nombre": "Jose Valero",
    "correo": "jose.valero@talisis.com",
    "__typename": "UserObject"
  },
  "flujo": {
    "edges": [
      {
        "node": {
          "etapas": {
            "edges": [
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3Nw==",
                  "nombre": "Autorización / Director del área",
                  "descripcion": "Da la autorización",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Humberto Salazar",
                    "correo": "humberto.salazar@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DIRECTOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDoy",
                    "codigoInterno": "EN-AUTORIZACION",
                    "nombre": "En autorización",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OA==",
                  "nombre": "Validación HRBP",
                  "descripcion": "Valida cambio de perfil o sueldo en reemplazo de vacantes",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "VALIDACION-HRBP",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OQ==",
                  "nombre": "Autorización / Compensaciones",
                  "descripcion": "Revisa solicitud y valida que la posición este en el presupuesto.\nIndica el sueldo propuesto y el nivel de la posición",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-COMPENSACIONES",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MA==",
                  "nombre": "Autorización / DO",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MQ==",
                  "nombre": "Autorización / Director Capital Humano Talisis",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-CH",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mg==",
                  "nombre": "Asignación de reclutador",
                  "descripcion": "El responsable de atracción de talento STAFF asigna al reclutador que llevará el proceso",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "ASIGNAR-RECLUTADOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mw==",
                  "nombre": "Publicación en bolsa de trabajo",
                  "descripcion": "Publicación en bolsa de trabajo para recepción de curriculums.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-newspaper-o",
                      "codigoInterno": "PUBLICACION-BOLSA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NA==",
                  "nombre": "Selección",
                  "descripcion": "Atracción de talento selecciona los mejores candidatos.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-group",
                      "codigoInterno": "SELECCION-TERNA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NQ==",
                  "nombre": "Programación de entrevistas",
                  "descripcion": "Programación de entrevistas con los diferentes candidatos",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-calendar",
                      "codigoInterno": "PROGRAMAR-ENTREVISTAS",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Ng==",
                  "nombre": "Selección de candidato",
                  "descripcion": "Solicitante selecciona al mejor candidato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-male",
                      "codigoInterno": "SELECCION-CANDIDATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Nw==",
                  "nombre": "Selección de equipo de computo",
                  "descripcion": "Selección del equipo de computo/telefono de acuerdo al puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "SELECCION-EQUIPO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OA==",
                  "nombre": "Proceso administrativo TI",
                  "descripcion": "Alta de cuentas de acceso a las diferentes plataformas",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "CREDENCIALES-TI",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OQ==",
                  "nombre": "Proceso administrativo ADP",
                  "descripcion": "El área de Nómina proveé los datos necesarios para el contrato",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Claudia Paez",
                    "correo": "claudia.paez@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "INFO-ADICIONAL-NOMINA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo5MA==",
                  "nombre": "Firma de contrato",
                  "descripcion": "Capital Humano firma de contrato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-edit",
                      "codigoInterno": "FIRMA-CONTRATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              }
            ],
            "__typename": "SolicitudStepObjectConnection"
          },
          "__typename": "SolAuthorizationFlowObject"
        },
        "__typename": "SolAuthorizationFlowObjectEdge"
      }
    ],
    "__typename": "SolAuthorizationFlowObjectConnection"
  },
  "__typename": "SolicitudObject",
  "stage_current": "Autorización / Director del área",
  "circleBarAuthorization": 0,
  "dataAuthorization": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proAuthorization": 0,
  "circleBarRecruitment": 0,
  "dataRecruitment": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proRecruitment": 0
},{
  "id": "U29saWNpdHVkT2JqZWN0OjY=",
  "nombrePuesto": {
    "id": "UHVlc3RvQ2F0YWxvZ09iamVjdDoxNg==",
    "nombre": "DIRECTOR OFICINA DE TRANSFORMACIÓN",
    "__typename": "PuestoCatalogObject"
  },
  "nombrePuestoDo": null,
  "statusId": 1,
  "ou": {
    "nombre": "ADVENIO",
    "codigoInterno": "ADVENIO",
    "__typename": "OuCatalogObject"
  },
  "centro": {
    "nombre": "Corporativo Monterrey",
    "__typename": "CentroCatalogObject"
  },
  "status": {
    "nombre": "En proceso",
    "__typename": "StatusCatalogObject"
  },
  "fecha": "2022-08-31T17:03:34.320000",
  "tipoSolicitud": {
    "nombre": "Reemplazo STAFF",
    "__typename": "SolicitudTipoObject"
  },
  "usuario": {
    "nombre": "Jose Valero",
    "correo": "jose.valero@talisis.com",
    "__typename": "UserObject"
  },
  "flujo": {
    "edges": [
      {
        "node": {
          "etapas": {
            "edges": [
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3Nw==",
                  "nombre": "Autorización / Director del área",
                  "descripcion": "Da la autorización",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Humberto Salazar",
                    "correo": "humberto.salazar@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DIRECTOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDoy",
                    "codigoInterno": "EN-AUTORIZACION",
                    "nombre": "En autorización",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OA==",
                  "nombre": "Validación HRBP",
                  "descripcion": "Valida cambio de perfil o sueldo en reemplazo de vacantes",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "VALIDACION-HRBP",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo3OQ==",
                  "nombre": "Autorización / Compensaciones",
                  "descripcion": "Revisa solicitud y valida que la posición este en el presupuesto.\nIndica el sueldo propuesto y el nivel de la posición",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-COMPENSACIONES",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MA==",
                  "nombre": "Autorización / DO",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-DO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4MQ==",
                  "nombre": "Autorización / Director Capital Humano Talisis",
                  "descripcion": "Revisa la solicitud, organigrama y descripción de puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "AUTORIZA",
                      "nombre": "Autorizaciones",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "AUTORIZACION-CH",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mg==",
                  "nombre": "Asignación de reclutador",
                  "descripcion": "El responsable de atracción de talento STAFF asigna al reclutador que llevará el proceso",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "ASIGNAR-RECLUTADOR",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Mw==",
                  "nombre": "Publicación en bolsa de trabajo",
                  "descripcion": "Publicación en bolsa de trabajo para recepción de curriculums.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-newspaper-o",
                      "codigoInterno": "PUBLICACION-BOLSA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NA==",
                  "nombre": "Selección",
                  "descripcion": "Atracción de talento selecciona los mejores candidatos.",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-group",
                      "codigoInterno": "SELECCION-TERNA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4NQ==",
                  "nombre": "Programación de entrevistas",
                  "descripcion": "Programación de entrevistas con los diferentes candidatos",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-calendar",
                      "codigoInterno": "PROGRAMAR-ENTREVISTAS",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Ng==",
                  "nombre": "Selección de candidato",
                  "descripcion": "Solicitante selecciona al mejor candidato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "RECLUTA",
                      "nombre": "Reclutamiento",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-male",
                      "codigoInterno": "SELECCION-CANDIDATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4Nw==",
                  "nombre": "Selección de equipo de computo",
                  "descripcion": "Selección del equipo de computo/telefono de acuerdo al puesto",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "SELECCION-EQUIPO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OA==",
                  "nombre": "Proceso administrativo TI",
                  "descripcion": "Alta de cuentas de acceso a las diferentes plataformas",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "CREDENCIALES-TI",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo4OQ==",
                  "nombre": "Proceso administrativo ADP",
                  "descripcion": "El área de Nómina proveé los datos necesarios para el contrato",
                  "fechaAutorizacion": null,
                  "autorizador": {
                    "nombre": "Claudia Paez",
                    "correo": "claudia.paez@talisis.com",
                    "__typename": "UserObject"
                  },
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "",
                      "codigoInterno": "INFO-ADICIONAL-NOMINA",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              },
              {
                "node": {
                  "id": "U29saWNpdHVkU3RlcE9iamVjdDo5MA==",
                  "nombre": "Firma de contrato",
                  "descripcion": "Capital Humano firma de contrato",
                  "fechaAutorizacion": null,
                  "autorizador": null,
                  "etapa": {
                    "autorizador": {
                      "nombre": "Jose Valero",
                      "correo": "jose.valero@talisis.com",
                      "__typename": "UserObject"
                    },
                    "departamento": {
                      "codigoInterno": "ONBOARDING",
                      "nombre": "Onboarding",
                      "__typename": "DepartamentoRHCatalogObject"
                    },
                    "etapa": {
                      "fontIcon": "fa-edit",
                      "codigoInterno": "FIRMA-CONTRATO",
                      "__typename": "StepCatalogObject"
                    },
                    "__typename": "AuthorizationFlowStepObject"
                  },
                  "status": {
                    "id": "U3RhdHVzQ2F0YWxvZ09iamVjdDox",
                    "codigoInterno": "EN-PROCESO",
                    "nombre": "En proceso",
                    "__typename": "StatusCatalogObject"
                  },
                  "__typename": "SolicitudStepObject"
                },
                "__typename": "SolicitudStepObjectEdge"
              }
            ],
            "__typename": "SolicitudStepObjectConnection"
          },
          "__typename": "SolAuthorizationFlowObject"
        },
        "__typename": "SolAuthorizationFlowObjectEdge"
      }
    ],
    "__typename": "SolAuthorizationFlowObjectConnection"
  },
  "__typename": "SolicitudObject",
  "stage_current": "Autorización / Director del área",
  "circleBarAuthorization": 0,
  "dataAuthorization": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proAuthorization": 0,
  "circleBarRecruitment": 0,
  "dataRecruitment": [
    {
      "name": "Grupo 5",
      "value": 0
    },
    {
      "name": "Grupo 4",
      "value": 0
    },
    {
      "name": "Grupo 3",
      "value": 0
    },
    {
      "name": "Grupo 2",
      "value": 0
    },
    {
      "name": "Grupo 1",
      "value": 0
    }
  ],
  "proRecruitment": 0
}]