import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { DragDropContext } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Button, TextIcon, Modal } from 'advenio-components'
import { clone, differenceWith, isEmpty, isNil, prop, sort, sortBy } from 'ramda'
import { PanelHeader } from '../../../Components/ProfilePanel/ProfilePanel.styles'
import { Root } from '../../../Components/DragAndDrop/DragAndDropElements'
import DroppableColumn from '../../../Components/DragAndDrop/DroppableColumn'
import FlowStageDetail  from '../../../Components/Flow/FlowStageDetail'
import FlowAuthorizationDetail  from '../../../Components/Flow/FlowAuthorizationDetail'
import UserActions  from '../../../Data/Redux/UserRedux'
import CatalguesActions from '../../../Data/Redux/CataloguesRedux'
import FlowAuthorizationStagesActions from '../../../Data/Redux/FlowAuthorizationStagesRedux'
import ErrorIsland from '../../../Components/ErrorIsland'
import WarningIsland from '../../../Components/WarningIsland'
import { validateFields } from '../../Solicitud'

const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`

const ContainerHeader = styled.div`
  display: inline-block;
  width:100%;
`

const ErrorRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`

const WarningRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`

const ButtonContainer = styled.div`
  display: inline-block;
  float: right;
  margin-right: 26px;
`

// Utility function to help us reorder the results
const reorder = (list, startIndex, endIndex) => {
  
  let result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  result = result.map(({node:stage,__typename},index)=> ({
    '__typename':__typename,
    'node':{
      ...stage,
      orden:index + 1
    } 
  }))
  return result
}

const diff = function(a, b) { return a.node.orden - b.node.orden; }
const sortByOrden = sortBy(prop('node.orden'));
const cmp = (x, y) => {
  if (y.tipo !== undefined){
    return x.id === y.tipo.id 
  }
  return x.id === y.id
}

class FlowAuthorizationStages extends Component {
  state={
    isDragging: null,
    currentDragging: null,
    flows:[],
    with:'auto',
    openModalFlow: false,
    openModalStage: false,
    usersOptions: [],
    departamentRhOptions: [],
    catStagesOptions: [],
    flowsOptions: [],
    stage:{},
    flow:{},
    errorMessage:null,
    modalType:'',
  }

  componentWillMount() {
    const width =  (window.innerWidth / 4 - 50).toString()+'px;';
    const { getAllUsersActive, getAllCatDeptosRh, getAllCatStages, getCatAuthorizationTypes } = this.props
    this.setState({width})
    getAllUsersActive({
      'data':{
        'deleted':0,
        'status':1
      },
    })
    getAllCatDeptosRh({
      'data':{
        'deleted':0,
        'status':1
      },
    })
    getAllCatStages({'data':{}})
    // getCatAuthorizationTypes({'data':{}})
  }

  componentWillReceiveProps(nextProps) {
    const {
      flujoAuthorizationStages,
      users,
      catDptoRh,
      catStages,
      catflows,
    } = nextProps
    const stages={}
    if(this.props.flujoAuthorizationStages !== flujoAuthorizationStages){
      flujoAuthorizationStages.map((flujo) => {
        stages[`${flujo.id}`] = true
      });
      this.setState({
        flows: flujoAuthorizationStages.filter(flow=> !isEmpty(flow.autorizacionSpec.edges)),
        ...stages
      })
    }
    if(this.props.users !== users){
      this.renderUsersOptions(users)
    }
    if(this.props.catDptoRh !== catDptoRh){
      this.renderDepartamentRhOptions(catDptoRh)
    }
    if(this.props.catStages !== catStages){
      this.renderCatStagesOptions(catStages,'Editar')
    }
    if(this.props.catflows !== catflows){
      this.renderCatflowsOptions(catflows)
    }
    this.onCloseModal()
  }

  onCloseModal = () => this.setState({openModalStage:false,openModalFlow:false})
  editStage = (data) => {
    const { stage } = this.state
    this.setState({errorMessage:null})
    const keys = Object.keys(data)
    let flawError = 0
    keys.map((key) => {
      if (validateFields(data, key)) {
        flawError += 1
      }
    })
    if(flawError > 0){
      this.setState({errorMessage:"Favor de llenar los campos."})
      return false
    }
    data={
      ...data,
      stageId:stage.id,
      deleted: Boolean(stage.deleted)
    }
    this.props.postStageFlowAuthorization(data)
  }
  
  editFlow = (data) => {
    this.setState({errorMessage:null})
    const keys = Object.keys(data)
    let flawError = 0
    keys.map((key) => {
      if (validateFields(data, key)) {
        flawError += 1
      }
    })
    if(flawError > 0){
      this.setState({errorMessage:"Favor de llenar los campos."})
      return false
    }
    
    this.props.postAutorizationFlow(data)
  }

  errorMessage = (errorMessage)=>this.setState({errorMessage})

  onDragStart = ({ source }) => {
    // console.log('dragStart',source);
    const { flujoAuthorizationStages } = this.props
    const { droppableId } = source
    const stages={}
    flujoAuthorizationStages.map((flujo) => {
      if(flujo.id === droppableId){
        stages[`${flujo.id}`] = false
      }else{
        stages[`${flujo.id}`] = true
      }
    });
    this.setState({
      ...stages
    })
  }
  
  onDragEnd = ({ source, destination, draggableId }) => {
    const { setStageFlowAuthorization } = this.props
      // onDragEnd = (result) => {
    // console.log('dragEnd',source, destination, draggableId);
    
    let flows = clone(this.state.flows)

    let [flow] = flows.filter(f=> f.id === source.droppableId)
    flow = {
      ...flow,
      autorizacionSpec:{
        edges: sort(diff,flow.autorizacionSpec.edges)
      }
    }
    
    if (!destination || (destination.index === source.index) ) {
      return;
    }
    const stage = flow.autorizacionSpec.edges[destination.index]
    if (Boolean(stage.node.deleted) || !Boolean(stage.node.status)) {
      return;
    }
    
    const stages = reorder(
      flow.autorizacionSpec.edges,
      source.index,
      destination.index,
    );
    flows = flows.map(f=>{
      if(f.id === source.droppableId){
        f.autorizacionSpec.edges = stages
      }
      return f
    })
    this.setState({flows});
    setStageFlowAuthorization({
      'data':{
        'start':source.index,
        'end':destination.index,
        'flujoId':source.droppableId,
      },
    })
    // console.log('sendDrag',{'origen':source.index,'destino':destination.index,'flujo':source.droppableId});
  }

  handleModalCardClick = (stage)=>{
    this.setState({openModalStage:true, stage})
  }

  handleModalColumnClick = (flow, modalType = 'Editar') => {
    if(flow.autorizacionSpec !== undefined){
      flow = {...flow,
        autorizacionSpec:{
          edges: sort(diff,flow.autorizacionSpec.edges)
        }
      }
    }
    
    this.setState({openModalFlow:true,flow, modalType},()=>{
      this.renderCatStagesOptions(this.props.catStages,modalType)
      this.renderCatflowsOptions(this.props.catflows,modalType)
    })
  }

  toggleModalStage = ()=> this.setState({openModalStage:!this.state.openModalStage,stage:{}, errorMessage:null})
  toggleModalFlow = ()=> this.setState({openModalFlow:!this.state.openModalFlow, flow:{}, errorMessage:null,modalType:''})

  renderUsersOptions = (users) => {
    let usersArry = clone([...users])
    let usersOptions = clone([{id:"",label:"Seleccionar",value:""}])
    usersOptions = usersOptions.concat(usersArry.map(user => ({
      id: user.id,
      label: user.nombre,
      value: user.id,
    })))
    this.setState({usersOptions})
    return
  }

  renderDepartamentRhOptions = (catDptoRh) => {
    let catDptoRhArry = clone([...catDptoRh])
    let departamentRhOptions = clone([{id:"",label:"Seleccionar",value:""}])
    departamentRhOptions = departamentRhOptions.concat(catDptoRhArry.map(dptorh => ({
      id: dptorh.id,
      label: dptorh.nombre,
      value: dptorh.id,
    })))
    this.setState({departamentRhOptions})
    return
  }
  renderCatflowsOptions = (catflows, modalType = 'Alta') => {
    const { flow } = clone(this.state)
    let catflowsArry = clone([...catflows])
    // let flowsOptions = clone([{id:"",label:"Seleccionar",value:""}])
    let flowsOptions = clone([])
    let originFlows =  flowsOptions = flowsOptions.concat(catflowsArry.filter(flow => modalType ==='Alta' ? (flow.deleted ===  0 && flow.status === 1) : true).map(flow => ({
      id: flow.id,
      label: flow.nombre,
      value: flow.id,
    })))
    
    if(this.state.flows.length >0){
      const flows = clone(this.state.flows).filter(f=> f.tipo !== undefined)
      
      flowsOptions = differenceWith(cmp, flowsOptions,flows)
      if(flow.tipo !== undefined){
        flowsOptions = flowsOptions.concat(originFlows.filter(o=>o.id=== flow.tipo.id)[0])
      }
      
    }
    flowsOptions.splice(0,0,{id:"",label:"Seleccionar",value:""})
    this.setState({flowsOptions})
    return
  }

  renderCatStagesOptions= (catStages,modalType) => {
    let catStagesArry = clone([...catStages].filter(stage => {
      if(modalType === 'Alta'){
        if(stage.status === 1){
          return stage
        }
      }
      else if (modalType === 'Editar'){
        return stage
      }
    }))
    let catStagesOptions = clone([{id:"",label:"Seleccionar",value:""}])
    catStagesOptions = catStagesOptions.concat(catStagesArry.map(stage => ({
      id: stage.id,
      label: stage.nombre,
      value: stage.id,
    })))
    this.setState({catStagesOptions})
    return
  }

  render() {
    const {
      loading,
      loadingUpdate,
    } = this.props
    const {
      isDragging,
      currentDragging,
      flows,
      width,
      openModalFlow,
      flow,
      openModalStage,
      stage,
      departamentRhOptions,
      catStagesOptions,
      flowsOptions,
      usersOptions,
      errorMessage,
      modalType,
    } = this.state

    return (
      <Container>
        <ContainerHeader>
          <PanelHeader tabTitle >Flujo de Solicitudes</PanelHeader>
          <ButtonContainer>
            <Button  className={'addFlowAuthorization'} primary style={{}} onClick={() =>{this.handleModalColumnClick({"status":true},'Alta')}}>
              <TextIcon label="Flujo de solicitud" iconType="plus" iconViewBox="-2 -2 24 24" />
              {/* <IconCustom  src='/assets/plus_white.svg' />
              Flujo de solicitudes */}
            </Button>
          </ButtonContainer>
        </ContainerHeader>
        {loading ? (
          <Modal isOpened={loading} loading={loading} />
        ):
        ( 
          <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            <Root>
            {flows.map((flujo) => {
              return <DroppableColumn
                width={width}
                columnId={flujo.id}
                flow={flujo}
                flowStatus={flujo.status}
                flowDeleted={flujo.deleted}
                stages={sort(diff,flujo.autorizacionSpec.edges)}
                stagesNo={flujo.autorizacionSpec.edges.length}
                title={flujo.nombre}
                key={flujo.nombre}
                envIsDragging={isDragging}
                currentDragging={currentDragging}
                isDropDisabled={this.state[`${flujo.id}`]}
                onCloumnClick={this.handleModalColumnClick}
                onCardClick={this.handleModalCardClick}
                loadingUpdate={loadingUpdate}
              />
              })}
            </Root>
          </DragDropContext>
        )}
        <Modal
          isOpened={openModalStage && !isNil(stage) }
          onClose={this.toggleModalStage}
          customWidth="50%"
          allowOverflow
          headerTitle={'Editar Etapa del Flujo'}
        >
          <FlowStageDetail 
            stage={stage} 
            editStage={this.editStage}
            usersOptions={usersOptions}
            departamentRhOptions={departamentRhOptions}
          />
          {!isNil(errorMessage) && (
          <ErrorRow>
            <ErrorIsland error={errorMessage} />
          </ErrorRow>
        )}
        </Modal>
        <Modal
          isOpened={openModalFlow }
          onClose={this.toggleModalFlow}
          customWidth="70%"
          
          headerTitle={`${modalType} Flujo de Solicitud`}
          >
          {!isNil(errorMessage) && (
            <ErrorRow>
              <ErrorIsland error={errorMessage} />
            </ErrorRow>
          )}
          {(modalType === 'Editar') && (
            <WarningRow>
              <WarningIsland warning={'Las etapas en este modal serán Habilitadas'}/>
            </WarningRow>
          )}
          <FlowAuthorizationDetail 
            modalType={modalType}
            flow={flow} 
            editFlow={this.editFlow}
            catStagesOptions={catStagesOptions}
            catUsersOptions={usersOptions}
            catDeptoRhOptions={departamentRhOptions}
            catFlowOptions={flowsOptions}
            handledErrorMessage={this.errorMessage}
            loading={loadingUpdate}
          />
        </Modal>
      </Container>
    )
  }
}

FlowAuthorizationStages.propTypes = {
}

FlowAuthorizationStages.defaultProps = {

}

const mapStateToProps = state => ({
  loading: state.flujoAuthorizationStages.get.fetching|| state.user.getAll.fetching|| state.flujoAuthorizationStages.upgrade.fetching,
  loadingUpdate: state.flujoAuthorizationStages.upgrade.fetching,
  users: state.user.getAll.results,
  catDptoRh: state.catalogues.getDptoRh.results,
  catStages: state.catalogues.getCatStages.results,
  catflows: state.catalogues.getCatAuthTypes.results,
  autorizators: state.user.getAll.results,
  flujoAuthorizationStages: state.flujoAuthorizationStages.get.results,
})

const mapDispatchToProps = dispatch => ({
  getAllUsersActive: (data) => dispatch(UserActions.userActivesRequest(data)),
  getAllCatDeptosRh: (data) => dispatch(CatalguesActions.catalogDptoRhRequest(data)),
  getAllCatStages: (data) => dispatch(CatalguesActions.catalogStagesRequest(data)),
  getCatAuthorizationTypes: (data) => dispatch(CatalguesActions.catAuthorizationTypesRequest(data,true)),
  setStageFlowAuthorization: (data) => dispatch(FlowAuthorizationStagesActions.updateFlowAuthorizationStagesRequest(data)),
  postAutorizationFlow: (data) => dispatch(FlowAuthorizationStagesActions.postAutorizationFlowRequest(data)),
  postStageFlowAuthorization: (data) => dispatch(FlowAuthorizationStagesActions.postStageFlowAuthorizationRequest(data)),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlowAuthorizationStages))
