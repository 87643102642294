import { gql } from '@apollo/client';

const solicitudAttr = {
    '$nombrePuestoId': 'ID!',
    '$nombrePuestoDo': 'String!',
    '$areaId': 'ID!',
    '$departamento': 'String!',
    '$puestoReporta': 'String!',
    '$correoJefeDirecto': 'String!',
    '$ouId': 'ID!',
    '$regionId': 'ID!',
    '$centroId': 'ID!',
    '$justificacionOperativa': 'String!',
    '$comoOperaActualmente': 'String!',
    '$justificacionEstrategica': 'String!',
    '$enPresupuesto': 'Boolean!'
        //    # , '$sueldoPropuesto':'Float!'
        //    # , '$sueldoAutorizado':'Float!'
        ,
    '$sueldoMinimo': 'Float!',
    '$sueldoMaximo': 'Float!',
    '$nivel': 'String!',
    '$usuarioId': 'ID!',
    '$tipoSolicitudId': 'ID!',
    '$statusId': 'ID!',
    '$descripcionPuesto': 'String!',
    '$organigrama': 'String!',
    '$tipoCandidato': 'String!',
    '$centroCostos': 'String!',
    '$compania': 'String!',
    '$plaza': 'String!',
    '$puesto': 'String!',
    '$tipoNomina': 'String!',
    '$organigramaFile': 'Upload!',
    '$organigramaFileName': 'String!',
    '$descripcionPuestoFile': 'Upload!',
    '$descripcionPuestoFileName': 'String!'
        //   ## Datos para segunda fase del proceso, Reclutamiento
        ,
    '$objetivoPuesto': 'String!',
    '$aQuienReemplaza': 'String!',
    '$carrera': 'String!',
    '$experienciaMinima': 'Int!',
    '$conocimientosTecnicos': 'String!',
    '$sectorPreferencia': 'String!',
    '$habilidadesRequeridas': 'String!',
    '$edadMinima': 'Int!',
    '$edadMaxima': 'Int!',
    '$prioridadId': 'ID!',
    '$tipoComputadora': 'String!',
    '$equipoEspecial': 'String!',
    '$tipoLinea': 'String!'
}
const solicitudStageAttr = {
    '$id': 'ID!',
    '$comentarios': 'String!',
    '$statusId': 'ID!',
    '$fechaAutorizacion': 'DateTime!',
    '$usuarioId': 'ID!'
}

const allSolicitudsFilterAttr = {
    '$usuario': 'String',
    '$deleted': 'Int',
    '$after': 'String',
    '$first': 'Int',
    '$before': 'String',
    '$last': 'Int'
}

const postSolicitud = `
mutation
  addSolicitud($params){
    addSolicitud(input:{ $binding })
    {
      solicitud {
        id
        nombrePuesto{
          id
          nombre
        }
        nombrePuestoDo
        area {
            id
            nombre
            codigoInterno
        }
        departamento
        puestoReporta
        tipoSolicitud {
            id
            nombre
        }
        ou {
            nombre
            codigoInterno
        }
        region {
            nombre
        }
        centro {
            nombre
        }
        usuario {
            nombre
            correo
        }
        justificacionOperativa
        comoOperaActualmente
        justificacionEstrategica
        fechaActualizacion
        descripcionPuesto
        organigrama
        tipoCandidato
        prioridad {
            id
            nombre
        }
        fecha
        usuario {
            nombre
            correo
        }
      }
    }
  }
`
const getAllSolicitudsFilter = `
query
  allSolicitudes($params){
    allSolicitudes(
    filters:{ $binding }
    $pagination){
      pageInfo{
        startCursor
        endCursor
      }
      edges{
        node{
          id
          nombrePuesto{
            id
            nombre
          }
          nombrePuestoDo
          statusId
          ou {
              nombre
              codigoInterno
          }
          centro {
              nombre
          }
          status {
              nombre
          }
          fecha
          tipoSolicitud {
              id
              nombre
          }
          usuario {
              nombre
              correo
          }
          flujo{
            edges{
              node{
                etapas{
                  edges{
                    node{
                      id
                      nombre
                      descripcion
                      fechaAutorizacion
                      # Autorizador alterno (Director area o solicitante)
                      autorizador {
                          nombre
                          correo
                      }
                      etapa {
                          # Autorizador de la etapa
                          autorizador {
                              nombre
                              correo
                          }
                          departamento {
                              codigoInterno
                              nombre
                          }
                          etapa {
                              fontIcon
                              codigoInterno
                          }
                      }
                      status {
                          id
                          codigoInterno
                          nombre
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const getSolicitudStepById = `
query
solicitudEtapa($id:ID!) {
  solicitudEtapa(id:$id) {
    id
    nombre
    descripcion
    comentarios
    orden
    fechaActualizacion
    fechaAutorizacion
    autorizador {
      id
      nombre
      correo
    }
    flujo {
      solicitud {
        id
        nombrePuesto{
          id
          nombre
        }
        nombrePuestoDo
        area {
          id
          nombre
        }
        departamento
        puestoReporta
        correoJefeDirecto
        objetivoPuesto
        aQuienReemplaza
        carrera
        experienciaMinima
        conocimientosTecnicos
        sectorPreferencia
        habilidadesRequeridas
        edadMinima
        edadMaxima
        descripcionPuesto
        organigrama
        tipoCandidato
        centroCostos
        compania
        tipoNomina
        puesto
        plaza
        prioridad {
          id
          nombre
        }
        tipoSolicitud {
          codigoInterno
          id
          nombre
        }
        ou {
          nombre
        }
        region {
          nombre
        }
        centro {
          nombre
        }
        usuario {
          id
          nombre
          correo
        }
        justificacionOperativa
        comoOperaActualmente
        justificacionEstrategica
        sueldoPropuesto
        sueldoAutorizado
        sueldoMinimo
        sueldoMaximo
        nivel
        enPresupuesto
        fechaActualizacion
        fecha
        tipoComputadora
        equipoEspecial
        tipoLinea
        candidatos{
          edges{
            node{
              id
              nombre
              apellidoPaterno
              apellidoMaterno
              correoElectronico
            }
          }
        }
      }
    }
    etapa {
      etapa {
        codigoInterno
        esCompensaciones
      }
    }
    status {
      id
      codigoInterno
      nombre
    }
    requisitos {
      edges {
        node {
          id
          nombre
          descripcion
          archivo
          codigoInterno
        }
      }
    }
  }
}`
const getSolicitudComments = `
query
allSolicitudEtapas($flujo:ID, $orden:Int){
  allSolicitudEtapas(filters: {flujo: $flujo, ordenLt: $orden}) {
    edges {
        node {
            id
            nombre
            descripcion
            codigoInterno
            comentarios
            fechaAutorizacion
            orden
            autorizador {
                nombre
                correo
            }
            status {
                codigoInterno
            }
            etapa {
                autorizador {
                    nombre
                    correo
                }
            }
        }
    }
  }
}
`
const getSolicitudDocuments = `
query
allEtapaRequisitos($etapaId:ID!){
  allEtapaRequisitos(
    filters:{
      etapaId: $etapaId
    }
  ){
  edges {
    node {
        id
        nombre
        archivo
        codigoInterno
        status
        fechaActualizacion
      }
    }
  }
}
`
const updateSolicitud = `
mutation
updateSolicitud($id:ID!, $params) {
  updateSolicitud(id:$id, input:{
      $binding
  })
  {
    solicitud {
      id
      nombrePuesto{
        id
        nombre
      }
      nombrePuestoDo
      area {
        id
        nombre
        codigoInterno
      }
      departamento
      puestoReporta
      tipoSolicitud {
        id
        nombre
      }
      ou {
        nombre
        codigoInterno
      }
      region {
        nombre
      }
      centro {
        nombre
      }
      usuario {
        nombre
        correo
      }
      justificacionOperativa
      comoOperaActualmente
      justificacionEstrategica
      fechaActualizacion
      descripcionPuesto
      organigrama
      tipoCandidato
      prioridad {
        id
        nombre
      }
      fecha
      usuario {
        nombre
        correo
      }
      objetivoPuesto
	    aQuienReemplaza
	    carrera
	    experienciaMinima
	    conocimientosTecnicos
	    sectorPreferencia
	    edadMinima
	    edadMaxima
	    habilidadesRequeridas
    }
  }
}`
const updateSolicitudStage = `
mutation
updateStep($params) {
  updateStep(input:{
      $binding
  })
  {
    solicitudEtapa {
      id
      nombre
      descripcion
      comentarios
      orden
      fechaActualizacion
      fechaAutorizacion
      autorizador {
        id
        nombre
        correo
      }
      flujo {
        solicitud {
          id
          nombrePuesto{
            id
            nombre
          }
          nombrePuestoDo
          area {
            id
            nombre
            codigoInterno
          }
          departamento
          puestoReporta
          correoJefeDirecto
          objetivoPuesto
          aQuienReemplaza
          carrera
          experienciaMinima
          conocimientosTecnicos
          sectorPreferencia
          habilidadesRequeridas
          edadMinima
          edadMaxima
          descripcionPuesto
          organigrama
          tipoCandidato
          centroCostos
          compania
          tipoNomina
          puesto
          plaza
          prioridad {
            id
            nombre
          }
          tipoSolicitud {
            id
            nombre
            codigoInterno
          }
          ou {
            codigoInterno
            nombre
          }
          region {
            nombre
          }
          centro {
            nombre
          }
          usuario {
            id
            nombre
            correo
          }
          justificacionOperativa
          comoOperaActualmente
          justificacionEstrategica
          sueldoPropuesto
          sueldoAutorizado
          sueldoMinimo
          sueldoMaximo
          nivel
          enPresupuesto
          fechaActualizacion
          fecha
          tipoComputadora
          equipoEspecial
          tipoLinea
          candidatos{
            edges{
              node{
                id
                nombre
                apellidoPaterno
                apellidoMaterno
                correoElectronico
              }
            }
          }
        }
      }
      etapa {
        codigoInterno
        etapa {
          codigoInterno
          esCompensaciones
        }
        autorizador{
          nombre
          correo
        }
      }
      status {
        id
        codigoInterno
        nombre
      }
      requisitos {
        edges {
          node {
            id
            nombre
            descripcion
            archivo
            codigoInterno
          }
        }
      }
    }
  }
}`

const getSolicitudById = `
query
solicitud($id: ID!){
  solicitud(id: $id) {
    id
    nombrePuesto{
      id
      nombre
    }
    departamento
    puestoReporta
    correoJefeDirecto
    justificacionOperativa
    descripcionPuesto
    organigrama
    area {
      id
      nombre
      descripcion
      codigoInterno
    }
    ou{
      id
      nombre
      codigoInterno
    }
    region{
      id
      nombre
      codigoInterno
    }
    centro{
      id
      nombre
      codigoInterno
    }
    tipoSolicitud {
      id
      nombre
      descripcion
      codigoInterno
  	}
    prioridad{
      id
      nombre
      codigoInterno
    }
	  objetivoPuesto
	  aQuienReemplaza
	  carrera
	  experienciaMinima
	  conocimientosTecnicos
	  sectorPreferencia
	  edadMinima
	  edadMaxima
	  habilidadesRequeridas
  }
}`

const getAllSolicitudsFilter3 = gql `
query
  allSolicitudes($usuario:String){
    allSolicitudes(filters:{ usuario: $usuario }){
      edges{
        node{
          id
          nombrePuesto{
            id
            nombre
          }
          nombrePuestoDo
          statusId
          ou {
              nombre
              codigoInterno
          }
          centro {
              nombre
          }
          status {
              nombre
          }
          fecha
          tipoSolicitud {
              id
              nombre
          }
          usuario {
              nombre
              correo
          }
          flujo{
            edges{
              node{
                etapas{
                  edges{
                    node{
                      id
                      nombre
                      descripcion
                      fechaAutorizacion
                      # Autorizador alterno (Director area o solicitante)
                      autorizador {
                          nombre
                          correo
                      }
                      etapa {
                          # Autorizador de la etapa
                          autorizador {
                              nombre
                              correo
                          }
                          departamento {
                              codigoInterno
                              nombre
                          }
                          etapa {
                              fontIcon
                              codigoInterno
                          }
                      }
                      status {
                          id
                          codigoInterno
                          nombre
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const getAllSolicituds = gql `
query
  allSolicitudes{
    allSolicitudes{
      edges{
        node{
          id
          nombrePuesto{
            id
            nombre
          }
          nombrePuestoDo
          statusId
          ou {
              nombre
              codigoInterno
          }
          centro {
              nombre
          }
          status {
              nombre
          }
          fecha
          tipoSolicitud {
              id
              nombre
          }
          usuario {
              nombre
              correo
          }
          flujo{
            edges{
              node{
                etapas{
                  edges{
                    node{
                      id
                      nombre
                      descripcion
                      fechaAutorizacion
                      # Autorizador alterno (Director area o solicitante)
                      autorizador {
                          nombre
                          correo
                      }
                      etapa {
                          # Autorizador de la etapa
                          autorizador {
                              nombre
                              correo
                          }
                          departamento {
                              codigoInterno
                              nombre
                          }
                          etapa {
                              fontIcon
                              codigoInterno
                          }
                      }
                      status {
                          id
                          codigoInterno
                          nombre
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const getAllSolicitudTypes = gql `
query
  allSolicitudTipos{
    allSolicitudTipos(filters:{not:{status:0}}){
      edges{
        node{
          id
          nombre
          codigoInterno
          status
          deleted
        }
      }
    }
  }
`
const postSolicitud2 = gql `
mutation
  addSolicitud($fileTest: Upload!,){
    addSolicitud(input:{ fileTest :$fileTest })
    {
      solicitud {
        id
        nombrePuesto{
            id
            nombre
          }
        nombrePuestoDo
        area {
            id
            nombre
            codigoInterno
        }
        departamento
        puestoReporta
        tipoSolicitud {
            id
            nombre
        }
        ou {
            nombre
            codigoInterno
        }
        region {
            nombre
        }
        centro {
            nombre
        }
        usuario {
            nombre
            correo
        }
        justificacionOperativa
        comoOperaActualmente
        justificacionEstrategica
        fechaActualizacion
        descripcionPuesto
        organigrama
        tipoCandidato
        prioridad {
            id
            nombre
        }
        fecha
        usuario {
            nombre
            correo
        }
      }
    }
  }
`
const addCandidatesSolicitud = gql `
mutation
addCandidato(
     $nombre: String!
    ,$apellidoPaterno: String!
    ,$apellidoMaterno: String!
    ,$correoElectronico: String!
    ,$solicitudId: ID!
  ){
    addCandidato(input:{
        nombre: $nombre,
        apellidoPaterno: $apellidoPaterno,
        apellidoMaterno: $apellidoMaterno,
        correoElectronico: $correoElectronico,
        solicitudId: $solicitudId,
     })
     {
        candidato {
           nombre
           apellidoPaterno
           apellidoMaterno
           correoElectronico
        }
     }
  }
`
const addFileSolicitud = gql `
mutation
addFileSolicitud(
     $id: ID!
    ,$candidatoId: ID!
    ,$fileName: String!
    ,$archivo: Upload!
  ){
    addFileSolicitud(input:{
      id :$id
      fileName :$fileName
      candidatoId :$candidatoId
      archivo :$archivo
     })
     {
      solicitudEtapaRequisitos {
        id
        nombre
        descripcion
        archivo
        codigoInterno
        status
        etapaId
        fechaActualizacion
        }
     }
  }
`
export {
    solicitudAttr,
    allSolicitudsFilterAttr,
    getAllSolicitudsFilter,
    getAllSolicituds,
    getAllSolicitudTypes,
    postSolicitud,
    postSolicitud2,
    getSolicitudStepById,
    getSolicitudComments,
    updateSolicitud,
    solicitudStageAttr,
    updateSolicitudStage,
    addCandidatesSolicitud,
    addFileSolicitud,
    getSolicitudById,
    getSolicitudDocuments,
}