import { gql } from '@apollo/client';


const queryFlowAuthorizationAttr = {
  '$statusIdIn': `[Int!]`,
  '$status': 'Int',
  '$deleted': 'Int',
  '$after': 'String',
  '$first': 'Int',
  '$before': 'String',
  '$last': 'Int',
}

const mutationFlowAuthorizationAttr = {
  '$flujoId': 'ID',
  '$stageId': 'ID',
  '$stagesIds': `[CreateStageInput]`,
  '$status': 'Boolean',
  '$start': 'Int',
  '$end': 'Int',
  '$departamentoRhId': 'ID',
  '$usuarioId': 'ID',
  '$deleted': 'Boolean',
}
const getAllFlujoAuthorizationStages = `
query
allFlujosAutorizacion $params {
  allFlujosAutorizacion
      $filters
    {
    edges{
      node{
        id
        nombre
        descripcion
        tipoId
        tipo{
          id
          nombre
        }
        diasVencimiento
        numRecordatorios
        codigoInterno
        status
        deleted
        autorizacionSpec{
          edges {
              node{
                id
                autorizacionId
                autorizacion{
                  id
                  nombre
                  codigoInterno
                }
                etapaId
                etapa{
                  id
                  nombre
                  codigoInterno
                  color
                }
                diasVencimiento
                numRecordatorios
                orden
                nombre
                descripcion
                msFlowRequerido
                msEndPonit
                msJsonTemplate
                codigoInterno
                status
                deleted
                usuarioId
                autorizador{
                  id
                  nombre
                  correo
                }
                departamentoRhId
                departamento{
                  id
                  nombre
                  codigoInterno
                }
              }
            }          
        }
      }
    }

  }

}`
const updateFlujoAuthorizationStages = `
mutation
setStageFlujoAutorizacion $params {
  setStageFlujoAutorizacion
      $input
    {
    flujo{
      id
      nombre
      descripcion
      tipoId
      tipo{
        id
        nombre
      }
      diasVencimiento
      numRecordatorios
      codigoInterno
      status
      deleted
      autorizacionSpec{
        edges {
          node{
            id
            autorizacionId
            autorizacion{
              id
              nombre
              codigoInterno
            }
            etapaId
            etapa{
              id
              nombre
              codigoInterno
            }
            diasVencimiento
            numRecordatorios
            orden
            nombre
            descripcion
            msFlowRequerido
            msEndPonit
            msJsonTemplate
            codigoInterno
            status
            deleted
            usuarioId
            autorizador{
              id
              nombre
              correo
            }
            departamentoRhId
            departamento{
              id
              nombre
              codigoInterno
            }
          }
        }          
      }
    }
  }
}`
const postFlujoAutorizacion = `
mutation 
addFlujoAutorizacion $params {
  addFlujoAutorizacion
    $input
  {
    authorizationFlowSpec{
      id
      nombre
      descripcion
      tipoId
      tipo{
        id
        nombre
      }
      diasVencimiento
      numRecordatorios
      codigoInterno
      status
      deleted
      autorizacionSpec{
        edges {
          node{
            id
            autorizacionId
            autorizacion{
              id
              nombre
              codigoInterno
            }
            etapaId
            etapa{
              id
              nombre
              codigoInterno
              color
            }
            diasVencimiento
            numRecordatorios
            orden
            nombre
            descripcion
            msFlowRequerido
            msEndPonit
            msJsonTemplate
            codigoInterno
            status
            deleted
            usuarioId
            autorizador{
              id
              nombre
              correo
            }
            departamentoRhId
            departamento{
              id
              nombre
              codigoInterno
            }
          }
        }          
      }
    }
  }
}
`
const postStageFlujoAutorizacion = `
mutation 
updateStageFlujoAutorizacion $params {
  updateStageFlujoAutorizacion
    $input
  {
    flujo{
      id
      nombre
      descripcion
      tipoId
      tipo{
        id
        nombre
      }
      diasVencimiento
      numRecordatorios
      codigoInterno
      status
      deleted
      autorizacionSpec{
        edges {
          node{
            id
            autorizacionId
            autorizacion{
              id
              nombre
              codigoInterno
            }
            etapaId
            etapa{
              id
              nombre
              codigoInterno
              color
            }
            diasVencimiento
            numRecordatorios
            orden
            nombre
            descripcion
            msFlowRequerido
            msEndPonit
            msJsonTemplate
            codigoInterno
            status
            deleted
            usuarioId
            autorizador{
              id
              nombre
              correo
            }
            departamentoRhId
            departamento{
              id
              nombre
              codigoInterno
            }
          }
        }          
      }
    }
  }
}
`

export {
  queryFlowAuthorizationAttr,
  mutationFlowAuthorizationAttr,
  getAllFlujoAuthorizationStages,
  updateFlujoAuthorizationStages,
  postFlujoAutorizacion,
  postStageFlujoAutorizacion,
}
