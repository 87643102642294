import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import styled from 'styled-components'
import { Grid as OriginalGrid, Row as OriginalRow, Col as OriginalCol } from 'react-flexbox-grid'
import { Checkbox as OriginalCheckbox, Select, Title2, TextInput,InputFile, Button, TextIcon, RadioGroup } from 'advenio-components'
import { isEmpty } from 'ramda'
import { STATUS, TYPE_SOLICITUD,SOLICITUDTYPEID, AUTORIZATIONTYPEID} from '../../../Constants'
import { PanelHeader } from '../../ProfilePanel/ProfilePanel.styles'
import { stringContainsAnyOf } from '../../../utils/Utils'
import moment from 'moment'
import { notificationFail, notificationSuccess } from '../../../Data/Redux/NotificationActions'
import AuthorizationStatusActions from '../../../Data/Redux/AuthorizationStatusRedux'
import SolicitudActions from '../../../Data/Redux/SolicitudRedux'


const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`
const Grid = styled(props => (
  <OriginalGrid fluid {...props}>
    {props.children}
  </OriginalGrid>
))`
  padding-left: 120px !important;
  padding-right: 120px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
`
const Row = styled(props => (
  <OriginalRow middle="xs" {...props}>
    {props.children}
  </OriginalRow>
))`
  margin-top: 4%;
`
const Col = styled(props => (
  <OriginalCol xs={12} {...props}>
    {props.children}
  </OriginalCol>
))``

const Label = styled.p`
  text-align: left;
  color: #212B36;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0px;
  margin-bottom: 2px;
`
const Checkbox = ({ label, name, value }) => (
  <OriginalCheckbox
    invertedColors
    large
    hideEmptyHelpBlock
    label={label}
    name={name}
    value={value}
  />
)

Checkbox.propTypes = {
  /** The checkbox label text */
  label: PropTypes.string.isRequired,
  /** The checkbox name value */
  name: PropTypes.string.isRequired,
  /** The checkbox value */
  value: PropTypes.bool.isRequired,
}

class InfoAdicionalSol extends Component {
  state={
    flawSend:false,
    dataSend:{},
    radioSelected:null
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const {
      validSolicitud,
      messageFail,
      updatedSolicitud,
      user,
      setSolicitudStageData
    } = nextProps
    const { flawSend, dataSend } = this.state

    if(flawSend && ! isEmpty(dataSend) && !isEmpty(validSolicitud)){
      if(validSolicitud.status.codigoInterno === STATUS.authorized || validSolicitud.status.codigoInterno === STATUS.rejected){
        messageFail('No se puede cambiar el estatus de una etapa previamente autorizada/rechazada')
      }else{
        let dataSolicitud = {
          'data':{
            ...dataSend,
            'id': validSolicitud.flujo.solicitud.id
          },
          'paramsExtra':{
            'nextStatus':"U3RhdHVzQ2F0YWxvZ09iamVjdDo2" // FIX: this get from catalogue state Redux
          }
        }

        delete dataSolicitud.data.comentarios
        delete dataSolicitud.data.statusId

        dataSend.usuarioId = user.id
        dataSend.comentarios = 'Información completada'
        dataSend.statusId = "U3RhdHVzQ2F0YWxvZ09iamVjdDo2"// FIX: this get from catalogue state Redux

        setSolicitudStageData(dataSend)
        updatedSolicitud(dataSolicitud)
      }
    }
  }

  changeRadio = (radioSelected) =>{
    this.setState({radioSelected})
  }
  onSubmit= (data) =>{
    const {  match:{ params: { solicitudStageId }}, validateSolitictud, info } = this.props
    const { radioSelected } = this.state
    const dataSend = {
      ...data,
      'id':solicitudStageId,
      'tipoCandidato': radioSelected
    }
    delete dataSend.radioGroup
    this.setState({dataSend, flawSend:true})
    validateSolitictud({
      'id':solicitudStageId
    })
  }

  renderOptions = () => this.props.status.map(s=>({
    id:s.id
    , label:s.nombre
    , value: s.id
  }))

  render() {
    const {
      info,typeSolicitud
    } = this.props
    return (
      <Container>
      {/*<PanelHeader tabTitle >Captura de Datos</PanelHeader>*/ } 
      {/* <Form
          ref={(form) => { this.form = form }}
      onSubmit={this.onSubmit} >   */}
     
          {/* <Grid> */}
            <Row>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="objetivoPuesto"
                  label="Objetivo del puesto"
                  value={info.objetivoPuesto || null}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
              {!! stringContainsAnyOf( typeSolicitud, [AUTORIZATIONTYPEID.REPLACEMENT_OPERATION,AUTORIZATIONTYPEID.REPLACEMENT_STAFF]) && (
                <TextInput
                  name="aQuienReemplaza"
                  label="Correo de a quien reemplaza"
                  value={info.aQuienReemplaza || null}
                  type='email'
                  required
                />)
              }
              </Col>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="carrera"
                  label="Carrera"
                  value={info.carrera || null}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="experienciaMinima"
                  label="Experiencia mínima (años)"
                  value={info.experienciaMinima || 0}
                  type={'number'}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="conocimientosTecnicos"
                  label="Conocimientos técnicos"
                  value={info.conocimientosTecnicos || null}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="sectorPreferencia"
                  label="Sector de preferencia"
                  value={info.sectorPreferencia || null}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="edadMinima"
                  label="Edad mínima (años)"
                  value={info.edadMinima || 0}
                  type={'number'}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <TextInput
                  name="edadMaxima"
                  label="Edad máxima (años)"
                  value={info.edadMaxima || 0}
                  type={'number'}
                  required
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                <div style={{height:"84px"}}>

                <Label>Tipo de Candidato *</Label>
                <RadioGroup
                  name="radioGroup"
                  radios={[
                    {
                      "name": "Interno",
                      "disabled": false,
                      "value": false,
                      "label": "Interno"
                    },{
                      "name": "Externo",
                      "disabled": false,
                      "value": false,
                      "label": "Externo"
                    },{
                      "name": "Ambos",
                      "disabled": false,
                      "value": false,
                      "label": "Ambos"
                    }
                  ]}
                  onClick={(value)=>{this.changeRadio(value)}}
                  required
               //   value={info.status.id || null}
                  // options={this.renderOptions()}
                  // fullWidth
                />
                </div>
              </Col>
            </Row>
            <Row>
            <Col sm={1} md={3} lg={3}></Col>
              <Col sm={9} md={6} lg={6}>
                <TextInput
                  name="habilidadesRequeridas"
                  label="Habilidades requeridas "
                  value={info.habilidadesRequeridas || null}
                  textarea
                  required
                  />
              </Col>
             {/* <Col sm={2} md={3} lg={3}>
                <Button primary disabled={!! stringContainsAnyOf(info.status.codigoInterno,[STATUS.authorized, STATUS.info_completed ])} style={{marginBottom:12, float:"right"}} loading={false} type="submit" >
              
                Guardar
                </Button>
              </Col>  */}
            </Row>
            <Row end="xs">
            </Row>
          {/* </Grid> */}
       {/* </Form>  */}
      </Container>
    )
  }
}

InfoAdicionalSol.propTypes = {
}

InfoAdicionalSol.defaultProps = {
}

const mapStateToProps = state => ({
  user: state.auth.user,
  status: state.authorizationStatus.get.results,
  validSolicitud: state.solicitud.validSolicitud.result,
})

const mapDispatchToProps = dispatch => ({
  validateSolitictud: (data) => dispatch(SolicitudActions.validateSolicitudRequest(data)),
  updatedSolicitud: (data) => dispatch(SolicitudActions.updateSolicitudRequest(data)),
  messageFail: msg => dispatch(notificationFail(msg)),
  setSolicitudStageData: data => dispatch(SolicitudActions.setSolicitudStageData(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoAdicionalSol))
