import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { push } from 'react-router-redux'
import { Form } from 'formsy-react'
import { Checkbox as OriginalCheckbox,  Table, TableCell, TableHeader,Select, Title2, TextInput,InputFile, Button, TextIcon,Colors, Icon, Modal } from 'advenio-components'
import { clone, empty, isEmpty, isNil } from 'ramda'
import { PanelHeader } from '../../../Components/ProfilePanel/ProfilePanel.styles'
import CatalguesActions from '../../../Data/Redux/CataloguesRedux'
import { Grid , Row, Col } from '../../../Components/Flow/FlowAuthorizationDetail'

const Container = styled.div`
  margin: 16px;
  padding-bottom: 20px;
`

const TableContainer = styled.div`
  width: 99%;
  padding: 16px 0px;
  margin: 0px auto;
`

const ContainerHeader = styled.div`
  display: inline-block;
  width:100%;
`

const ButtonContainer = styled.div`
  float: right;
`

const ButtonModal = styled.div`
  float: right;
  margin-right: 15px;
  margin-bottom: 15px;
`
const CellContainer = styled.div`
margin: 0;
display: block;
max-width: 100%;
overflow: visible;
text-overflow: ellipsis;
white-space: nowrap;
word-wrap: normal;
font-size: 14px;
line-height: 20px;
padding: 14px;
color: ${props=> props.deleted ? "#FFFFFF" : '#637381'};
height: 100%;
text-align: center;
background-color:  ${props=> Boolean(props.deleted) ? "#FC9AA4" : '#FFFFFF'};
white-space:  ${props=> props.solicitudName ? "nowrap" : 'initial'};
`

const SolicitudName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.blue500};
  cursor: pointer;
`

const Status = styled.span`
  padding: 8px 16px;
  background-color:  ${props=> props.colorFill ? "#50B83C" : '#F92135'};
  border-radius: 20px;
  color:  #FFFFFF;
  font-size: 14px;
`

class Flow extends Component {
  state={
    with:'auto',
    openModal:false,
    flow:{}
  }

  componentWillMount() {
    const width =  (window.innerWidth / 4 - 50).toString()+'px;';
    const { getCatAuthorizationTypes } = this.props
    this.setState({width})
    //  getCatAuthorizationTypes({'data':{}})
  }

  navigateToFlow = (solicitudId,nombre,division) => {
    this.setState({openModal:true,flow:{nombre,division}})
  }

  closeModal = () => this.setState({openModal:false})
 
  handleModal = ()=>{
    this.setState({openModal:true,flow:{}})
  }

  submitFlow = (data) =>{
    console.log(data)
  }

  render() {
    const {
      results,
      loading
    } = this.props
    const {
      openModal,
      flow
    } = this.state

    return (
      <Container>
       {loading ? ( <Modal isOpened={loading} loading={loading} /> )
        :
        <React.Fragment>
          <ContainerHeader>
            <PanelHeader tabTitle >Tipos de Solicitudes</PanelHeader>
            <ButtonContainer>
              <Button primary style={{}} onClick={() =>{this.handleModal()}}>
                <TextIcon label="Solicitud" iconType="plus" iconViewBox="-2 -2 24 24" />
              </Button>
            </ButtonContainer>
          </ContainerHeader>
          <TableContainer>
            <Table data={results}
            roundedCorners
            withBorder
              columns={[
                {
                id: '1',
                Header: () => <TableHeader title="Solicitud" center blue />,
                accessor: ({ nombre, id,division, deleted }) => ({ nombre, id,division, deleted }),
                Cell:({ value: {  id, nombre,division, deleted }, index }) =>
                  <CellContainer solicitudName center deleted={deleted} index={index} >
                            <SolicitudName onClick={() => this.navigateToFlow(id,nombre,division)}>
                              {nombre}
                            </SolicitudName>
                  </CellContainer>,
                },
                {
                id: '2',
                Header: () => <TableHeader title="Division" center blue />,
                accessor: ({ division:nombre, deleted }) => ({ nombre, deleted }),
                Cell:({ value: { nombre, deleted }, index }) => <CellContainer center deleted={deleted} index={index} >{nombre}</CellContainer>,
                },
                {
                id: '3',
                Header: () => <TableHeader title="Estatus" center blue />,
                accessor: ({status, deleted }) => ({ status,deleted }),
                Cell:({ value: {status, deleted }, index }) => <CellContainer center deleted={deleted} index={index} ><Status colorFill={status}>{status ? 'Habilidato':'Deshabilitado'}</Status></CellContainer>,
                },
              ]}
            />
          </TableContainer>
        </React.Fragment>
        }
        <Modal
          isOpened={openModal }
          onClose={this.closeModal}          
          headerTitle={`${isEmpty(flow)?'Nueva':'Editar'} Solicitud`}
        >
          <Form
           ref={(form) => { this.form = form }}
           onValidSubmit={this.submitFlow}
          >
            <Grid>
              <Row>
                <Col sm={12} md={2}></Col>
                <Col sm={12} md={4}>
                  <TextInput
                    name="nombre"
                    label="Nombre"
                    value={isNil(flow.nombre)? null:flow.nombre}
                    required
                  />
                </Col>  
                <Col sm={12} md={4}>
                  <TextInput
                    name="division"
                    label="Division"
                    value={isNil(flow.division)? null:flow.division}
                    required
                  />
                </Col>
                <Col sm={12} md={2}></Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                <ButtonModal>
                  <Button primary type="submit">
                    Guardar
                  </Button>
                </ButtonModal>
                </Col>
              </Row>
            </Grid>
          </Form>
        </Modal>
      </Container>
    )
  }
}

Flow.propTypes = {
}

Flow.defaultProps = {

}

const mapStateToProps = state => ({
  results: state.catalogues.getCatAuthTypes.results,
  loading: state.catalogues.getCatAuthTypes.fetching,
})

const mapDispatchToProps = dispatch => ({
  getCatAuthorizationTypes: (data) => dispatch(CatalguesActions.catAuthorizationTypesRequest(data)),
  goTo: path => dispatch(push(path)),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Flow))
