import { createActions, createState, createReducer } from 'reduxsauce-crud'
import R from 'ramda'

const d = { deep: true }

export const UserRedux = createActions(
  {
    userByRoleRequest: ['data'],
    userByRoleSuccess: ['data'],
    userByRoleError: ['err'],
    userActivesRequest: ['data'],
    userActivesSuccess: ['data'],
    userActivesError: ['err'],
  },
  {
    prefix: 'USER_',
    defaultActions: {
      get: true,
    },
  },
)

const { Types, Creators } = UserRedux
export const UserTypes = Types
export default Creators

export const INITIAL_STATE = createState({
  getAll:{
    fetching:false,
    error:null,
    results:[]
  }
}, {
  get: true,
})

const userByRoleRequest = state =>
  state.merge({ get: { fetching: true, error: null } }, d)

const userByRoleError = (state, { err }) =>
  state.merge({ get: { fetching: false, error: err } }, d)

const userByRoleSuccess = (state, data) => {
  return state.merge({ get: { fetching: false, results: data.data } }, d)
}
const userActivesRequest = state =>
  state.merge({ getAll: { fetching: true, error: null } }, d)

const userActivesError = (state, { err }) =>
  state.merge({ getAll: { fetching: false, error: err } }, d)

const userActivesSuccess = (state, data) => {
  return state.merge({ getAll: { fetching: false, results: data.data } }, d)
}


export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.userByRoleRequest]: userByRoleRequest,
    [Types.userByRoleSuccess]: userByRoleSuccess,
    [Types.userByRoleError]: userByRoleError,
    [Types.userActivesRequest]: userActivesRequest,
    [Types.userActivesSuccess]: userActivesSuccess,
    [Types.userActivesError]: userActivesError,
  },
  {
    defaultActions: {
      get: true,
    },
    Types,
  },
)
