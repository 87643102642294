import React, { Component, useState } from "react"
import { MsalContext } from "@azure/msal-react"
import { loginRequest } from "../../auth365Config"
import { callMsGraph } from "../../graph"
import styled from 'styled-components'
import { Button } from 'advenio-components'
import { render } from "react-dom"

const Icon = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
    @media (max-width: 763px) {
      width: 15px;
      height: 15px;
    }
`
class ButtonMSAuth365 extends Component {

  static contextType = MsalContext

  constructor(props) {
    super(props)
    this.state = {
      responseLogin:null,
      graphData:null
    }
  }

  loginAuth365 = () => {
    const {
      responseLogin,
      graphData
    } = this.state


    this.context.instance.loginPopup(loginRequest).then( response => this.setState({responseLogin:response})).catch(e => {
        console.log('ErrorloginPopup')
        console.log(e)
    })
  }

  logoutAuth365 = (logoutType=null) => {
    this.context.instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri:"/login"
    })
  }

  getGraphData365 = () => {
    this.context.instance.acquireTokenSilent({
        ...loginRequest,
        account: this.context.accounts[0]
    }).then((response) => {
        callMsGraph(response.accessToken).then(response =>  this.setState({graphData:response}));
    }).catch(e => {
        console.log('ErrorAcquireTokenSilent')
        console.log(e)
    })
  }
  render(){
    const {
      responseLogin,
      graphData
    } = this.state
    const {
        loginError,
        auth:{
            authorized
        },
        LoginApi,
        loading
    } = this.props

    if(responseLogin !== null && graphData === null){
      this.getGraphData365()
    }

    if(graphData !== null && loginError === '' && authorized === null && !loading){

        LoginApi({'user':graphData.mail,'typeAuth':'Microsoft','token':responseLogin.accessToken})
    }
    return (
        <div style={{
          marginRight: '2%',
        }}>
            <Button onClick={this.loginAuth365} transparent addShadow large fullWidth loading={loading}>
            <Icon src="assets/microsoft.svg"/>

              Login Microsoft
            </Button>
            {/* <Button onClick={this.logoutAuth365} Defualt fullWidth>Logout Microsoft</Button> */}
        </div>
    )
  }
}

export default ButtonMSAuth365
