import { createActions, createState, createReducer } from 'reduxsauce-crud'
import R from 'ramda'

const d = { deep: true }

export const AuthorizationStatusRedux = createActions(
  {
    getAuthorizationStatusRequest: [''],
    getAuthorizationStatusSuccess: ['data'],
    getAuthorizationStatusError: ['err'],
    getNextStatusRequest: ['data'],
    getNextStatusSuccess: ['data'],
    getNextStatusError: ['err'],
    resetNextStep:[''],
    // updateExpense: ['data']
  },
  {
    prefix: 'AUTHORIZATION_STATUS_',
    defaultActions: {
      get: true,
    },
  },
)

const { Types, Creators } = AuthorizationStatusRedux
export const AuthorizationStatusTypes = Types
export default Creators

export const INITIAL_STATE = createState({
  nextStatus:{
    fetching:false,
    error:null,
    result:{}
  }
}, {
  get: true,
})

const resetNextStep = state => state.merge({ nextStatus:{ fetching: false, error: null, result: null }}, d)

const getAuthorizationStatusRequest = state =>
  state.merge({ get: { fetching: true, error: null } }, d)

const getAuthorizationStatusError = (state, { err }) =>
  state.merge({ get: { fetching: false, error: err } }, d)

const getAuthorizationStatusSuccess = (state, data) => {
  return state.merge({ get: { fetching: false, results: data.data } }, d)
}
const getNextStatusRequest = state =>
  state.merge({ nextStatus: { fetching: true, error: null } }, d)

const getNextStatusError = (state, { err }) =>
  state.merge({ nextStatus: { fetching: false, error: err } }, d)

const getNextStatusSuccess = (state, data) => {
  return state.merge({ nextStatus: { fetching: false, result: data.data } }, d)
}

// const updateExpense = (state, { data }) => {
//   const newSolicitud = R.clone(state.get.results.data)
//   const activeExpenseIndex = R.findIndex(R.propEq('id', data.data.id))(newSolicitud)
//   newSolicitud[activeExpenseIndex] = data.data
//   return state.merge({ get: { fetching: false, results: { data: newSolicitud } } }, d)
// }


export const reducer = createReducer(
  INITIAL_STATE, {
    [Types.resetNextStep]: resetNextStep,
    [Types.getAuthorizationStatusRequest]: getAuthorizationStatusRequest,
    [Types.getAuthorizationStatusSuccess]: getAuthorizationStatusSuccess,
    [Types.getAuthorizationStatusError]: getAuthorizationStatusError,
    [Types.getNextStatusRequest]: getNextStatusRequest,
    [Types.getNextStatusSuccess]: getNextStatusSuccess,
    [Types.getNextStatusError]: getNextStatusError,
    // [Types.updateExpense]: updateExpense,
  },
  {
    defaultActions: {
      get: true,
    },
    Types,
  },
)
