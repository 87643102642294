import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'
import { isNil } from 'ramda'
import styled from 'styled-components'
import { useMsal } from "@azure/msal-react"
import { Form } from 'formsy-react'
import {
  Button,
  Colors,
  Display,
  GlobalStyles,
  Title1,
  Title2,
  TextInput,
} from 'advenio-components'
import  {  Actions as AuthActions } from '../../Data/Redux/AuthRedux'
import ButtonMSAuth365  from './ButtonMSAuth365'
import ButtonGoogleAuth  from './ButtonGoogleAuth'
import { COLORS } from '../../Constants'

const AdvenioLogo = styled.img`
  width: 350px;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    width: 100%;
  }
`
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
`
const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
`
const Card = styled.div`
  ${GlobalStyles.borderRadius};
  width: 100%;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0,0,0, .1);

  @media (min-width: 768px) {
    min-width: 400px;
  }
`
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px 0;
  ${({ newHeader }) => newHeader && `
    margin-bottom: 20px;
    padding: 0 0 0 0;
  `}
  @media (max-width: 767px) {
    padding: 16px 8px 0;
  }
`
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;

  @media (max-width: 767px) {
    padding: 16px;
  }
`
const ErrorContainer = styled.div`
  ${GlobalStyles.borderRadius};
  padding: 16px;
  background-color: ${Colors.red100};
  border: 1px solid ${Colors.red300};
`
const ErrorTitle = styled(Title1)`
  color: ${Colors.redText};
`
const SubHeading = styled(Title2)`
  color: ${COLORS.BLUE};
  padding-left:2px;
  font-size: 43px;
`
const ErrorText = styled.p`
  display: block;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 14px;
  color: ${Colors.redText};
`
const ButtonGroup = styled.div`
	width: 100%;
	display: inline-flex;
  justify-content: center;
`
class Login extends Component {
  onAuth = (data) => {    
    this.props.authUser(data)
  }

  forgotPassword = () => this.props.goTo('/recover-password')

  render() {

    const { loginError, authUser, auth, loading} = this.props

    return (
      <Container>
        <ContentWrapper>
          <AdvenioLogo src="assets/talisis_logo.png" />
          <CardHeader newHeader>
              <SubHeading>Vacantes</SubHeading>
          </CardHeader>
          {window.Cypress?
          <Card>
            <CardBody>
              <Form onValidSubmit={this.onAuth}>
                <TextInput
                  type="email"
                  id="email"
                  name="email"
                  label="Correo"
                />
                <TextInput
                  type="password"
                  id="password"
                  name="password"
                  label="Contraseña"
                />
                <Button className={'savelogin'} type="submit" name="sent-login" primary fullWidth>Ingresar</Button>
              </Form>
            </CardBody>
          </Card>
          :
          <ButtonGroup>
            <ButtonMSAuth365 LoginApi={authUser} auth={auth} loading={loading} loginError={loginError}/>
            <ButtonGoogleAuth loading={loading} LoginApi={authUser}/>
          </ButtonGroup>
          } 
        </ContentWrapper>
      </Container>
    )
  }
}

Login.propTypes = {
  /** Login error message */
// loginError: PropTypes.string.isRequired,
  /** Attempt to authenticate the user via the API */
  authUser: PropTypes.func.isRequired,
  /** Navigate to the given URI */
  goTo: PropTypes.func.isRequired,
}

Login.defaultProps = {
  loginError: '',
  authUser: () => {},
}

const mapStateToProps = ({ auth }) => ({
  loginError: auth.error,
  auth: auth,
  loading: auth.fetching
})

const mapDispatchToProps = dispatch => ({
  authUser: data => dispatch(AuthActions.loginRequest(data)),
  goTo: path => dispatch(push(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
